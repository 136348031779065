import api from '../api';
import { getFromSessionStorage } from '../../constants/functions';

export const getBilletCreationData = async (grupo: string | number, quota: string | number, digit: string | number) => {
  grupo = Number(grupo);
  grupo = grupo.toString().padStart(5, '0');
  quota = Number(quota);
  quota = quota.toString().padStart(4, '0');
  digit = Number(digit);
  digit = digit.toString().padStart(2, '0');

  const url = `/boleto`;
  try {
    const res = (await api.get(url, {
      headers: { token: getFromSessionStorage('token') },
      params: { grupo, quota, digito: digit },
    })) as any;
    return res as BilletCreationDataProps;
  } catch (e) {
    return null;
  }
};
export const emitNewBillet = async (data: any) => {
  const url = `/boleto`;
  try {
    const res = (await api.post(
      url,
      { ...data, codUsuarioEmissor: '0000201' },
      {
        headers: { token: getFromSessionStorage('token') },
      }
    )) as any;
    return res;
  } catch (e) {
    return null;
  }
};
export const emitBilletPDF = async (grupo: string, quota: string, digito: string, banco: string, nrodoc: string) => {
  const url = `/boleto/2via`;
  try {
    const res = (await api.post(
      url,
      {
        grupo,
        quota,
        digito,
        banco,
        nrodoc,
        regvend: process.env.REACT_APP_VENDEDOR ? process.env.REACT_APP_VENDEDOR : '_',
      },
      {
        headers: { token: getFromSessionStorage('token') },
      }
    )) as any;

    return res;
  } catch (e) {
    return null;
  }
};

export interface BilletCreationDataProps {
  digqta: string;
  cod_bem: string;
  cod_nome_bem: string;
  valcredi: number;
  ass_ref: string;
  aberto: {
    multa_juros: number;
    valor_total: number;
    dias: number;
    valor_parcela: number;
    vencimento: string;
    assembleia: string;
    parcela: string;
  }[];
  percdife: string;
  valdfp: number;
  totalpagar: number;
  custas_jur: number;
  custas_gar: number;
  custas_cob: number;
  saldodevedor: number;
  contemplado: string;
  vlrinfo: number;
  data_ass_ref: string;
  prazo_quota: string;
  taxas_diversas: number;
  historicos: string;
  receber_lance: string;
  altera_tela: string;
  mostra_msg_vcto: string;
  data_calculo: string;
  nro_pcls_atraso: string;
  data_cancel_qta: string;
  bco_aviso_mensal: string;
  tipo_convenio_bb: string;
  email_da_cota: string;
  data_vcto_limite: string;
  param_9206_boasVindas_anexar_boleto: string;
  param_9206_gera_boleto_net: string;
  param_9206_gera_boleto_netLight: string;
  param_9206_gera_boleto_mobile: string;
  boletosEmitidos: {
    tipoCobranca: string;
    valorCobrado: string;
    parcelasCobradas: string;
    assembleiaReferencia: string;
    codigoUsuario: string;
    dataVencimento: string;
    codigoBanco: string;
    nossoNumero: string;
    dataEmissao: string;
    horaEmissao: string;
  }[];
}
