import { Alert } from 'react-bootstrap';

import { useUserContext } from '../../context/UserContext';
import { useAxios } from '../../services/swr';
import { Container, Title } from './styles';

interface MembershipProposalData {
  urlProposta: string;
}

export function MembershipProposal() {
  const { group, quota, digit } = useUserContext();

  const { data, error } = useAxios<MembershipProposalData>([
    'consorciado/propostaAdesao',
    { grupo: group, quota, digito: digit },
  ]);

  return (
    <Container>
      <Title>Proposta de Adesão</Title>

      {error && (
        <div className="mt-3">
          <Alert key={'primary'} variant={'primary'}>
            {error.response?.data || 'Não foi possível gerar a proposta de adesão.'}
          </Alert>
        </div>
      )}

      {!!data && <embed src={data.urlProposta} className="mt-4" width="100%" height="93%" />}
    </Container>
  );
}
