import React, { CSSProperties } from 'react';
import { PrimaryButton, SecondaryButton } from './styles';

interface ButtonProps {
  children: any;
  onClick?: (e?: any) => void;
  hideOnPrint?: boolean;
  disabled?: boolean;
  style?: CSSProperties;
  type?: 'button' | 'submit' | 'reset' | undefined;
  className?: string;
  hierarchy?: string;
}

const Button = ({
  children,
  onClick,
  hideOnPrint,
  className,
  style,
  disabled = false,
  type = 'button',
  hierarchy = 'primary',
}: ButtonProps) => {
  return (
    <>
      {hierarchy === 'primary' ? (
        <PrimaryButton
          style={style}
          onClick={onClick}
          hideOnPrint={hideOnPrint}
          disabled={disabled}
          type={type}
          className={className}
        >
          {children}
        </PrimaryButton>
      ) : (
        <SecondaryButton
          onClick={onClick}
          hideOnPrint={hideOnPrint}
          disabled={disabled}
          type={type}
          className={className}
        >
          {children}
        </SecondaryButton>
      )}
    </>
  );
};

export default Button;
