import styled from 'styled-components';

export const PageLayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
`;

export const PageLayoutChildrenContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  max-height: calc(100vh - 68px);
  @media (max-width: 768px) {
    max-height: 100vh;
  }
`;
export const Page = styled.div`
  width: 100%;
  background: #f8f9fa;
  padding: 24px;
  overflow-y: scroll;
  max-height: 100vh;
  padding-top: 38px;
  @media (max-width: 768px) {
    padding-top: 148px;
  }
`;
