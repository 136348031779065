import 'bootstrap/dist/css/bootstrap.min.css';

import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import Button from '../../components/Button';

//styles
import { Alert, Col, Form, Row } from 'react-bootstrap';
import { CardInfo } from '../../components/CardInfo';
import { ExtractTable } from '../../components/ExtractTable';
import { useUserContext } from '../../context/UserContext';
import { useAxios } from '../../services/swr';
import { BoxCard } from '../BilletCreation/styles';
import { PageTitleContainer, Title } from './styles';

interface AssemblyResultsData {
  grupo: string;
  segmento: string;
  prazo: string;
  limite_conso: string;
  assembleia: string;
  local_unineg: string;
  horario: string;
  regraApuracao: string;
  tipoSorteio: string;
  bolasSorteadas: string[];
  tituloBolas: string;
  resultadosSorteio: {
    quota: string;
    digito: string;
    situacao: string;
    lance: string;
    tipoContemplacao: string;
    grupo: string;
  }[];
  resultadosLance: {
    quota?: string;
    digito?: string;
    situacao?: string;
    lance?: string;
    tipoContemplacao?: string;
    grupo: string;
  }[];
}

interface FilterFormData {
  month: string;
  year: string;
  group: string;
}

export default function AssemblyResults() {
  const { loginResponseData } = useUserContext();

  const [requestParams, setRequestParams] = useState<Record<string, string>>();

  const { register, handleSubmit } = useForm<FilterFormData>({
    defaultValues: {
      month: String(new Date().getMonth() + 1).padStart(2, '0'),
      year: new Date().getFullYear().toString(),
    },
  });

  const { data, error } = useAxios<AssemblyResultsData, string>(
    requestParams ? ['consorciado/assembleia/resultado', requestParams] : null
  );

  const uniqueGroups = useMemo(
    () => [...new Set(loginResponseData?.consorciados.map(({ grupo }) => grupo))],
    [loginResponseData]
  );

  function onSubmit({ group, month, year }: FilterFormData) {
    setRequestParams({
      grupo: group,
      mesAnoAssemb: `${year}${month}`,
    });
  }

  return (
    <main>
      <PageTitleContainer>
        <Title>Resultado das Assembleias</Title>
      </PageTitleContainer>

      <Form className="px-4" onSubmit={handleSubmit(onSubmit)}>
        <Row className="gy-3">
          <Form.Group as={Col} md="4" lg="4" xl="3">
            <Form.Label>Mês de referência</Form.Label>
            <Form.Select required {...register('month')}>
              <option value="">Selecione um mês</option>
              {monthTuples.map(([value, label]) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} md="4" lg="4" xl="3">
            <Form.Label>Ano de referência</Form.Label>
            <Form.Control required type="number" min="1900" max="2099" step="1" {...register('year')} />
          </Form.Group>
          <Form.Group as={Col} md="4" lg="4" xl="3">
            <Form.Label>Grupos Disponíveis</Form.Label>
            <Form.Select required {...register('group')}>
              <option value="">Selecione um grupo</option>
              {uniqueGroups.map((group) => (
                <option key={group} value={group}>
                  {group}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Col lg="12" xl="3" className="d-flex align-items-end justify-content-end">
            <Button type="submit">Buscar</Button>
          </Col>
        </Row>
      </Form>

      {error && (
        <div className="px-4 mt-3">
          <Alert key={'primary'} variant={'primary'}>
            {error.response?.data || 'Não foi possível carregar os dados.'}
          </Alert>
        </div>
      )}

      {data && (
        <>
          <Row className="gy-3 gx-4 mt-4">
            <Col lg="4">
              <BoxCard>
                <CardInfo title="Grupo" info={data.grupo} />
              </BoxCard>
            </Col>
            <Col lg="4">
              <BoxCard>
                <CardInfo title="Prazo" info={data.prazo} />
              </BoxCard>
            </Col>

            <Col lg="4">
              <BoxCard>
                <CardInfo title="Segmento" info={data.segmento} />
              </BoxCard>
            </Col>
            <Col lg="4">
              <BoxCard>
                <CardInfo title="Participantes" info={String(Number(data.limite_conso) || '-')} />
              </BoxCard>
            </Col>

            <Col lg="4">
              <BoxCard>
                <CardInfo title="Assembleia" info={data.assembleia} />
              </BoxCard>
            </Col>

            <Col lg="4">
              <BoxCard>
                <CardInfo title="Local" info={data.local_unineg} />
              </BoxCard>
            </Col>
          </Row>

          <div style={{ marginTop: 32 }}>
            <Title style={{ color: '#6C757D' }}>Etapa {data.bolasSorteadas[0]}</Title>
          </div>

          <BoxCard className="mt-4">
            <div style={{ gap: 32 }} className="d-flex flex-wrap flex-row justify-content-around align-items-center">
              {data.bolasSorteadas.slice(1).map((ball, key) => (
                <div key={ball}>
                  <p style={{ opacity: 0.5 }} className="text-muted">
                    {key + 1}ª
                  </p>
                  <p style={{ opacity: 0.75 }} className="fw-bold fs-4">
                    {ball}
                  </p>
                </div>
              ))}
            </div>
          </BoxCard>

          <Row className="gy-3 gx-4 mt-2">
            <Col md="12" lg="6">
              <BoxCard>
                <ExtractTable
                  title="Cotas contempladas por sorteio"
                  titleBackgroundColor="var(--cor-primaria)"
                  rowsGroup={['Cota', 'Situação']}
                  data={data.resultadosSorteio
                    .filter((i) => i.situacao)
                    .map(({ digito, quota, situacao }) => [`${quota}.${digito}`, situacao])}
                />
              </BoxCard>
            </Col>

            <Col md="12" lg="6">
              <BoxCard>
                <ExtractTable
                  title="Cotas contempladas por lance"
                  titleBackgroundColor="var(--cor-primaria)"
                  rowsGroup={['Cota', 'Situação', 'Lance']}
                  data={data.resultadosLance
                    .filter((i) => i.situacao)
                    .map(({ quota, digito, situacao, lance }) => [`${quota}.${digito}`, situacao, lance])}
                />
              </BoxCard>
            </Col>
          </Row>
        </>
      )}
    </main>
  );
}

const monthTuples = [
  ['01', 'Janeiro'],
  ['02', 'Fevereiro'],
  ['03', 'Março'],
  ['04', 'Abril'],
  ['05', 'Maio'],
  ['06', 'Junho'],
  ['07', 'Julho'],
  ['08', 'Agosto'],
  ['09', 'Setembro'],
  ['10', 'Outubro'],
  ['11', 'Novembro'],
  ['12', 'Dezembro'],
];
