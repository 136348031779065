import api from '../api';
import { getFromSessionStorage } from '../../constants/functions';

export const saveAddressInformation = async (reqData: SaveAddressInformationReqProps) => {
  const url = `/vendedor/consorciado/registerOrUpdatePerson`;
  try {
    const res = (await api.post(url, reqData, {
      headers: { token: getFromSessionStorage('token') },
    })) as any;
    return res as { status: boolean; response: any };
  } catch (e) {
    return null;
  }
};

export const getAddressByCep = async (cep: string) => {
  const url = `/cep`;
  try {
    const res = (await api.get(url, {
      headers: { token: getFromSessionStorage('token') },
      params: { cep },
    })) as any;
    return res as {
      cep: string;
      rua: string;
      bairro: string;
      cidade: string;
      estado: string;
    };
  } catch (e) {
    return null;
  }
};

export interface SaveAddressInformationReqProps {
  cpfCnpj: string;
  person: { emails: string[]; phones: string[]; nome: string; type: string; faturamento: string; renda: string };
  addresses: {
    zipCode: string;
    number: string;
    city: string;
    street: string;
    checked: boolean;
    neighborhood: string;
    state: string;
    complement: string;
  }[];
}
