import React, { Fragment } from 'react';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// arr routes
import routes from './';
import RequireAuth from '../components/RequireAuth/RequireAuth';
import PageContainer from '../components/PageContainer';

export default function RootRoutes() {
  return (
    <Router>
      <Fragment>
        <Routes>
          {/* <Route path="/" element={<Home />} /> */}
          {routes.map((item: any, index) => {
            if (item && !item.public) {
              return (
                <Route
                  path={item.route}
                  element={
                    <>
                      <RequireAuth>
                        <PageContainer>{item.component}</PageContainer>
                      </RequireAuth>
                    </>
                  }
                  key={index}
                />
              );
            }
            return <Route path={item.route} element={item.component} key={index} />;
          })}
        </Routes>
      </Fragment>
    </Router>
  );
}
