import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { Eye } from 'react-bootstrap-icons';
//styles
import sanitizeHtml from 'sanitize-html';
import * as S from './styles';

interface InputProps {
  label?: string;
  placeholder?: string;
  type: string;
  id: string;
  onChange?: (e: any) => void;
  value?: any;
  border?: any;
  disabled?: boolean;
  required?: boolean;
  autoFocus?: boolean;
}

export function Input(props: InputProps) {
  const [showPassword, setShowPassword] = useState(false);

  const inputComponent = () => (
    <>
      <S.Input
        type={showPassword ? 'text' : props.type}
        placeholder={props.placeholder}
        autoComplete="none"
        onChange={(e: any) => {
          // @ts-ignore
          return props.onChange({
            ...e,
            target: { value: sanitizeHtml(e.target.value).replace('&lt;', '').replace('&gt;', '') },
          });
        }}
        value={props.value}
        required={props.required}
        disabled={props.disabled}
        autoFocus={props.autoFocus}
      />
      {props.type === 'password' && (
        <S.ViewPassword onClick={() => setShowPassword(!showPassword)} type="button">
          <Eye color={`#abb5be`} size={24} />
        </S.ViewPassword>
      )}
    </>
  );

  return (
    <S.FormGroup controlId={props.id}>
      {props.label && <Form.Label>{props.label}</Form.Label>}
      {/*{isLocalEnv() ? inputComponent() : <form autoComplete="off">{inputComponent()}</form>}*/}
      {inputComponent()}
    </S.FormGroup>
  );
}
