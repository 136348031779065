import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Input } from '../../components/Form/Input/Input';

//styles
import { useUserContext } from '../../context/UserContext';
import { useStylization } from '../../hooks/useStylization';
import * as S from '../Login/styles';
import ModalInfo from '../../components/ModalInfo';
import { maskCpfCnpj } from '../../utils/masks';

const ForgotPassword = () => {
  useEffect(() => {
    sessionStorage.clear();
  }, []);

  const { stylization } = useStylization();
  const { forgotPassword } = useUserContext();

  const [document, setDocument] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [show, setShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [validated, setValidated] = useState<boolean>(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      try {
        const res = await forgotPassword({ document: document.replace(/\D/g, ''), email });
        if (res.error) {
          setErrorMessage(
            res.message || 'E-mail de Recuperação de Senha enviado para o e-mail informado em seu cadastro.'
          );
          return handleShow();
        }
        setErrorMessage('E-mail de Recuperação de Senha enviado para o e-mail informado em seu cadastro.');
        return handleShow();
      } catch (e) {
        console.log(e);
      }
    }

    setValidated(true);
  };

  return (
    <S.Content loginbackground={stylization?.imagemFundo + ''}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 ms-auto d-flex align-items-center justify-content-center">
            <S.Box>
              <div>
                <figure className="text-center mx-5">
                  <img src={stylization?.logoPrincipal} alt="" className="w-100" />
                </figure>

                <ModalInfo message={errorMessage} show={show} handleClose={handleClose} header={'Atenção'} />
                <S.Head>
                  <h1>Esqueceu sua senha?</h1>
                  <p>Informe seus dados abaixo para receber uma nova senha por e-mail.</p>
                  <p>Preencha o CPF/CNPJ apenas com números, sem ponto, barra ou hífen.</p>
                </S.Head>

                {document === '' || email === '' ? <S.AlertDanger>Preencha todos os campos!</S.AlertDanger> : null}

                <Form noValidate validated={validated} onSubmit={handleSubmit} autoComplete="off">
                  <Input
                    type="text"
                    id="user"
                    required={true}
                    placeholder="CPF/CNPJ"
                    onChange={(e: any) => setDocument(maskCpfCnpj(e.target.value))}
                    value={document}
                  />
                  <Input
                    type="email"
                    id="email"
                    required={true}
                    placeholder="E-mail"
                    onChange={(e: any) => setEmail(e.target.value)}
                    value={email}
                  />
                  <S.Submit type="submit">Recuperar senha</S.Submit>
                </Form>

                <div className="text-center">
                  <S.NavigatePassword to="/login" title="Login">
                    Fazer login
                  </S.NavigatePassword>
                </div>
              </div>

              <div className="mt-4 infoFooter">
                <p className="text-center mb-0">{stylization?.textoRodape}</p>

                <div className="text-center d-flex justify-content-center align-items-center gap-2 flex-wrap">
                  <strong>{stylization?.textoRodapeNegrito}</strong>
                </div>

                <figure className="text-center mt-5 mb-0 mx-auto logoSecondary">
                  <img src={stylization?.logoSecundario} alt="" className="w-100" />
                </figure>
              </div>
            </S.Box>
          </div>
        </div>
      </div>
    </S.Content>
  );
};

export default ForgotPassword;
