import api from '../api';
import { getFromSessionStorage } from '../../constants/functions';

export const getGroupInformation = async (group: string): Promise<GroupInfo | null> => {
  const url = `/consorciado/disponibilidade?cpfCnpj=${getFromSessionStorage('cpfCnpj')}&grupo=${group}`;
  try {
    const res = (await api.get(url, { headers: { token: getFromSessionStorage('token') } })) as any;
    return res as GroupInfo;
  } catch (e) {
    return null;
  }
};

export interface GroupInfo {
  grupo: string;
  dt_dispant: string;
  vlr_dispant: string;
  vlr_caixant: string;
  vlr_depbancant: string;
  vlr_chequeant: string;
  vlr_aplicfinancgrp: string;
  vlr_aplicvinccont: string;
  vlr_recoletado: string;
  vlr_totrecoletado: string;
  vlr_aquisicaocol: string;
  vlr_totaquisicaocol: string;
  vlr_txadmcol: string;
  vlr_totxadmcol: string;
  vlr_txfrcol: string;
  vlr_totxfrcol: string;
  vlr_rendaplicfinancol: string;
  vlr_totrendaplicfinancol: string;
  vlr_muljurcol: string;
  vlr_totmuljurcol: string;
  vlr_segurocol: string;
  vlr_totsegurocol: string;
  vlr_desjudicol: string;
  vlr_totdesjudicol: string;
  vlr_despregcol: string;
  vlr_totdespregcol: string;
  vlr_outroscol: string;
  vlr_totoutroscol: string;
  vlr_recutil: string;
  vlr_totrecutil: string;
  vlr_aquisicaoutil: string;
  vlr_totaquisicaoutil: string;
  vlr_txadmutil: string;
  vlr_totxadmutil: string;
  vlr_muljurutil: string;
  vlr_totmuljurutil: string;
  vlr_seguroutil: string;
  vlr_totseguroutil: string;
  vlr_despjudicutil: string;
  vlr_totdespjudicutil: string;
  vlr_devolutil: string;
  vlr_totdevolutil: string;
  vlr_despregutil: string;
  vlr_totdespregutil: string;
  vlr_outrosutil: string;
  vlr_totoutrosutil: string;
  dt_dispatu: string;
  vlr_dispatu: string;
  vlr_caixatu: string;
  vlr_depbancatu: string;
  vlr_chequeatu: string;
  vlr_aplicfinancgrpatu: string;
  vlr_aplicvinccontatu: string;
  '1': string;
  '2': string;
  '3': string;
  '4': string;
}
