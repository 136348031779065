import styled from 'styled-components';

export const SubgroupContainer = styled.div`
  display: flex;
  flex-direction: column;

  .accordion-item:first-of-type .accordion-button {
    border-radius: 8px;
  }

  .accordion-item {
    border: transparent;
  }

  .accordion-button {
    background: var(--cor-secundaria);

    &::after {
      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(197deg) brightness(107%) contrast(104%) !important;
    }
  }

  .accordion-body {
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 14px;
  }
`;
export const PseudoRow = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const PseudoRowTitle = styled.div`
  font-weight: 600;
  font-size: 0.75rem;
  color: #343a40;
`;
export const PseudoRowContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const AccordionCustomHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 4px;
`;
export const MainHeader = styled.h3`
  font-weight: 600;
  font-size: 1rem;
  display: flex;
  align-items: center;
  color: #fff;
  margin: 0;
`;
export const PseudoCollumnLabel = styled.div`
  font-weight: 500;
  font-size: 0.75rem;
  color: #6c757d;
`;
export const PseudoCollumnValue = styled.div`
  font-weight: 400;
  font-size: 0.75rem;
  color: #343a40;
`;
export const Row = styled.div`
  width: 100%;

  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  h4 {
    color: #495057;
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
  }

  p {
    color: #495057;
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
  }
`;
export const ColoredSubTitleRow = styled.div<{ titleBackgroundColor?: string; titleCenter: boolean }>`
  background: ${({ titleBackgroundColor }) => titleBackgroundColor ?? 'var(--cor-secundaria)'};
  padding: 10px 16px;
  border-radius: 8px;
  margin-bottom: 24px;

  h3 {
    font-weight: 600;
    font-size: 1rem;
    text-align: ${({ titleCenter }) => (titleCenter ? 'center' : 'inherit')};
    color: #fff;
    margin: 0;
  }
`;

export const InfosRowsGroups = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const RowGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const BoxTable = styled.div`
  overflow: auto;

  thead {
    tr {
      border: none !important;

      th {
        white-space: nowrap;
        border: none !important;
        padding: 12px 8px;
      }
    }
  }

  tbody {
    tr {
      border-width: 0 !important;
      border-radius: 8px;

      :hover {
        background: #e9ecef;
      }

      td {
        vertical-align: middle;
        white-space: nowrap;
        background: none;
        border: none !important;
        box-shadow: none;
        font-size: 1rem;
        font-weight: 400;
        color: #495057;
        padding: 5px;
        text-align: center;

        .disabled {
          color: #adb5bd;
        }
      }
    }
  }
`;

export const ItemHead = styled.div`
  display: flex;
  background: #e9ecef;
  width: 100%;
  color: #495057;
  padding: 10px;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  justify-content: center;
`;

export const IssueBillet = styled.a`
  text-decoration: none;
  background: none;
  border: none;
  padding: 0;
  color: #495057;
  transition: 0.15s;

  cursor: pointer;

  &:hover {
    color: var(--cor-primaria);
    font-weight: 700;
    transition: 0.15s;
  }
`;

export const TotalValue = styled.strong`
  color: var(--cor-primaria);
`;

export const TotalValueWithBackground = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 8px;
  background: #d5ebfa;
  color: #495057;
  font-size: 1rem;
  font-weight: 600;
`;
