import styled from 'styled-components';

export const Title = styled.h1`
  margin: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  line-height: 39px;
  color: #343a40;
  @media (max-width: 768px) {
    font-size: 1.25rem;
  }
`;

export const BaseDate = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  padding: 4px 16px;
  border-radius: 4px;
  border: 1px solid #6c757d;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  @media (max-width: 768px) {
    width: 100%;
  }

  @media (max-width: 475px) {
    flex-direction: column;
  }
`;

export const AssemblyResultsPageContainer = styled.div`
  margin-top: 24px;
  display: flex;
  padding: 30px 24px;
  background: white;
  flex-direction: column;
  border-radius: 8px;
  gap: 24px;
`;

export const PageTitleContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 24px;
  justify-content: space-between;
`;
