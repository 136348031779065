import api from '../api';
import { getFromSessionStorage } from '../../constants/functions';

export const getCurrentAccountStatementInfo = async (
  grupo: string,
  quota: string,
  todos = 'N'
): Promise<CurrentAccountStatementInfo | null> => {
  const url = `/consorciado/extrato`;
  try {
    const res = (await api.get(url, {
      headers: { token: getFromSessionStorage('token') },
      params: {
        cpfCnpj: getFromSessionStorage('cpfCnpj'),
        grupo,
        quota,
        todos,
        digito: getFromSessionStorage('digit'),
      },
    })) as any;
    return res as CurrentAccountStatementInfo;
  } catch (e) {
    return null;
  }
};

export const sendIREmail = async (grupo: string, quota: string, anoBase: string, emailPath: string) => {
  const segmento = process.env.REACT_APP_SEGMENTO;
  const url = `/vendedor/consorciado/extratoImpostoRenda`;
  try {
    const res = (await api.get(url, {
      headers: { token: getFromSessionStorage('token') },
      params: {
        grupo,
        quota,
        anoBase,
        emailPath,
        segmento,
        digito: getFromSessionStorage('digit'),
      },
    })) as any;
    return res as { success: boolean };
  } catch (e) {
    return null;
  }
};

export interface CurrentAccountStatementInfo {
  digqta: string;
  bem: string;
  credito: string;
  ass_ref: string;
  contemplacao: string;
  ass_cont: string;
  pagamentos: {
    devido: string;
    amortizado: string;
    diferenca: string;
    historico: string;
    vencimento: string;
    pagamento: string;
    parcela: string;
    pago: string;
  }[];
  percpago: string;
  total_pagto: string;
  abertos: {
    multa_juros: string;
    total: string;
    dias: string;
    valor: string;
    vencimento: string;
    assembleia: string;
    parcela: string;
  }[];
  percdife: string;
  valdfp: string;
  totalpagar: string;
  percamor: string;
  percdeve: string;
  saldodevedor: string;
  assembleias: (
    | { data: string; horario: string; assembleia: string; vencimento: string; local: string }
    | { data: string; horario: string; assembleia: string; vencimento: string; local: string }
    | { data: string; horario: string; assembleia: string; vencimento: string; local: string }
  )[];
  nro_ult_ass: string;
  data_ult_ass: string;
  hora_ult_ass: string;
  bolas_sorteio: string;
  cont_sorteio: string;
  lance: any[];
  prazo_quota: string;
  histograma: string;
  codrev: string;
  nomrev: string;
  pcl_pagas: string;
  pcl_aberto: string;
  prazo_grupo: string;
  custas_jur: string;
  custas_gar: string;
  custas_cob: string;
  taxas_diversas: string;
  mostra_dados_conso: string;
  proposta: string;
  cpf_cnpj_edi: string;
  rg_edi: string;
  data_edi: string;
  end_cob: string;
  nomrua: string;
  numero: string;
  comple: string;
  bairro: string;
  cidade: string;
  uf: string;
  codcep: string;
  cplcep: string;
  codddd: string;
  nrofone: string;
  ramal: string;
  lib_cred_atu: string;
  codigoVendedor: string;
  nomeVendedor: string;
  dtVencPrcParticipacao: string;
  taxaAdministracao: string;
  taxaSeguroVida: string;
  fundoReserva: string;
  dtVenda: string;
  nroUltimaPclQuota: string;
  dtVencUltimaPclQuota: string;
  dtAolUltimaPclQuota: string;
  nroPrimeiraAolGrupo: string;
  dtPrimeiraAolGrupo: string;
  nroUltimaAolGrupo: string;
  dtUltimaAolGrupo: string;
  nroAolAtual: string;
  dtVencAolAtual: string;
  nroProximaAol: string;
  dtVencProximaAol: string;
  vlrCreditoDisponivel: string;
  nroPclAtual: string;
  vlrTotalPclAtual: string;
  vlrFndComumPclAtual: string;
  vlrFndReservaPclAtual: string;
  vlrTxAdmPclAtual: string;
  vlrSegurosPclAtual: string;
  vlrMultaPclAtual: string;
  vlrJurosPclAtual: string;
  bensFaturados: {
    especie: string;
    sequencia: string;
    situacao: string;
    renavam: string;
    cor: string;
    modeloDescricaoBem: string;
    anoFabricacao: string;
    chassi: string;
    anoModelo: string;
    marcaFabricante: string;
    placa: string;
  }[];
  categoria: string;
  statusCota: string;
  dt_atualizacao: string;
  vlr_cred_cont: string;
  vlr_aplicado: string;
  vlr_pago: string;
  vlr_atualizado: string;
  dt_pgto: string;
}
