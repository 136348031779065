import api from '../api';
import { getFromSessionStorage } from '../../constants/functions';

export const sendBilletViaPhisical = async (grupo: string, quota: string, digito: string) => {
  const url = `/boleto`;
  try {
    const res = (await api.get(url, {
      headers: { token: getFromSessionStorage('token') },
      params: { grupo, quota, digito },
    })) as any;
    return res as { success: boolean };
  } catch (e) {
    return null;
  }
};
export const saveBilletInfo = async (email: string, grupo: string, quota: string, quotas: any[]) => {
  const url = `/boleto/digital`;
  try {
    const res = (await api.post(
      url,
      {
        email,
        grupo,
        quota,
        quotas,
      },
      {
        headers: { token: getFromSessionStorage('token') },
      }
    )) as any;
    return res as any;
  } catch (e) {
    return null;
  }
};
export const getBilletInfo = async (grupo: string, quota: string) => {
  const url = `/boleto/digital`;
  try {
    const res = (await api.get(url, {
      headers: { token: getFromSessionStorage('token') },
      params: { grupo, quota },
    })) as any;
    return res as any;
  } catch (e) {
    return null;
  }
};
