import styled from 'styled-components';

export const SubgroupContainer = styled.div`
  display: flex;
  flex-direction: column;

  .accordion-item:first-of-type .accordion-button {
    border-radius: 8px;
  }

  .accordion-item {
    border: transparent;
  }

  .accordion-button {
    background: var(--cor-secundaria);

    &::after {
      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(197deg) brightness(107%) contrast(104%) !important;
    }
  }

  .accordion-body {
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 14px;
  }
`;
export const PseudoRow = styled.div`
  display: flex;
`;
export const PseudoRowTitle = styled.div`
  font-weight: 600;
  font-size: 0.75rem;
  color: #343a40;
`;
export const PseudoRowContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const AccordionCustomHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 4px;
`;
export const MainHeader = styled.h3`
  font-weight: 600;
  font-size: 1rem;
  display: flex;
  align-items: center;
  color: #fff;
  margin: 0;
`;
export const PseudoCollumnLabel = styled.div`
  font-weight: 500;
  font-size: 0.75rem;
  color: #6c757d;
`;
export const PseudoCollumnValue = styled.div`
  font-weight: 400;
  font-size: 0.75rem;
  color: #343a40;
`;
export const Row = styled.div`
  width: 100%;

  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 8px;

  h4 {
    color: #495057;
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
  }

  p {
    color: #495057;
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
  }
`;
export const ColoredSubTitleRow = styled.div`
  background: var(--cor-secundaria);
  padding: 10px 16px;
  border-radius: 8px;
  margin-bottom: 24px;

  h2 {
    color: #fff;
    font-weight: 600;
    margin: 0;
    font-size: 1.25rem;
  }
`;

export const InfosRowsGroups = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const RowGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;
