import api from '../api';
import { getFromSessionStorage } from '../../constants/functions';

export const getAutomaticDebitData = async (grupo: string, quota: string) => {
  const url = `/consorciado/dadosBancariosDebito`;
  try {
    const res = (await api.get(url, {
      headers: { token: getFromSessionStorage('token') },
      params: { grupo, quota, digito: getFromSessionStorage('digit') },
    })) as any;
    return res as AutomaticDebitDataProps;
  } catch (e) {
    return null;
  }
};
export const getBanks = async () => {
  const url = `/bancos`;
  try {
    const res = (await api.get(url, {
      headers: { token: getFromSessionStorage('token') },
    })) as any;
    return res as any;
  } catch (e) {
    return null;
  }
};

export const saveAccountInfo = async (
  banco: string,
  agencia: string,
  digito: string,
  conta: string,
  debit: boolean
) => {
  const url = `/consorciado/atualizaDebitoConta`;
  const bodyFormData = new FormData();

  bodyFormData.append('grupo', getFromSessionStorage('group'));
  bodyFormData.append('quota', getFromSessionStorage('quota'));

  bodyFormData.append(
    'body',
    JSON.stringify({
      bankData: {
        debitoEmConta: debit,
        debitoDigitoConta: digito,
        debitoConta: conta,
        debitoDigitoEsc: getFromSessionStorage('digit'),
        debitoAgencia: agencia,
        situacaoUltimoVencimento: '1',
        debitoBanco: banco,
        naoDesejaInformarConta: '',
      },
    })
  );
  try {
    const res = (await api.post(url, bodyFormData, {
      headers: { token: getFromSessionStorage('token'), 'Content-Type': 'multipart/form-data' },
    })) as any;
    return res as AutomaticDebitDataProps;
  } catch (e) {
    return null;
  }
};

export interface AutomaticDebitDataProps {
  debitoDigitoEsc: string;
  debitoEmConta: boolean;
  debitoAgencia: string;
  debitoDigito: string;
  debitoConta: string;
  debitoDigitoConta: string;
  debitoBanco: string;
}
