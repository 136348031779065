import React from 'react';
import { LogoHeader, PritingHeader, PritingVersion } from './styles';
import { useUserContext } from '../../context/UserContext';
import { useStylization } from '../../hooks/useStylization';

interface PrintingVersionProps {
  children: any;
}

export const PrintingVersion = ({ children }: PrintingVersionProps) => {
  const { loginResponseData } = useUserContext();
  const { stylization } = useStylization();

  const printDate = () => {
    return new Date().toLocaleString().replace(',', '');
  };

  return (
    <PritingVersion>
      <LogoHeader>
        <img src={stylization?.logoPrincipal} alt="" className="w-100" />
      </LogoHeader>
      <PritingHeader bgColor={stylization?.corSecundaria}>
        <div>{loginResponseData?.usuarioApelido}</div>
        <div>{printDate()}</div>
      </PritingHeader>
      {children}
    </PritingVersion>
  );
};
