import { Alert, Form } from 'react-bootstrap';
import styled from 'styled-components';

export const Title = styled.h1`
  margin: 0;
  font-style: normal;
  font-weight: 600;
  line-height: 39px;
  color: #343a40;
  margin-bottom: 16px;
  font-size: 2rem;
  @media (max-width: 768px) {
    font-size: 1.25rem;
  }
`;

export const AlertContent = styled(Alert)`
  background: #cee8d6;
  color: #065919;
  border: 1px solid #065919;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 18px 32px;
`;

export const PageTitleContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
  align-items: end;
  margin-bottom: 32px;
`;

export const ResetButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: var(--cor-secundaria);
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 12px;
  font-size: 0.875rem;
  font-weight: 400;
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: 32px;

  button {
    border: 1px solid var(--cor-secundaria);
    border-radius: 4px;
  }

  .reset {
    background: none;
    color: #343a40;
    border-color: var(--cor-primaria);
    &:hover {
      color: #fff;
      background: var(--cor-secundaria);
      border-color: var(--cor-secundaria);
    }
  }

  .submit {
    border-color: var(--cor-primaria);
    background: var(--cor-primaria);
    color: #fff;
    &:hover {
      border-color: var(--cor-secundaria);
      background: var(--cor-secundaria);
    }
  }
`;

export const GroupOrCota = styled.span`
  color: #495057;
  font-size: 1rem;
  font-weight: 400;
`;

export const BoxContent = styled.div`
  background: #fff;
  border-radius: 8px;
  padding: 24px 16px;
`;

export const BoxHead = styled.div`
  background: var(--cor-secundaria);
  padding: 10px 24px;
  border-radius: 8px;
  margin-bottom: 17px;

  h4 {
    margin: 0 !important;
    color: #fff;
    margin: 0 !important;
    font-size: 1.25rem;
    font-weight: 600;
  }
  @media (max-width: 768px) {
    padding: 10px 20px;
    h4 {
      margin: 0 !important;
      color: #fff;
      margin: 0 !important;
      font-size: 0.75rem;
      font-weight: 600;
    }
  }
`;
export const BoxBody = styled.div`
  padding: 10px 24px;

  .form-check-inline {
    margin: 0 !important;
  }
  @media (max-width: 768px) {
    padding: 10px 20px;
  }
`;

export const SelectAll = styled.button`
  color: #495057;
  font-size: 1rem;
  font-weight: 600;
  background: none !important;
  border: none !important;
  padding: 0 !important;
`;

export const Checkbox = styled(Form.Check)`
  input {
    border-radius: 2px !important;

    &:checked {
      background-color: var(--cor-secundaria);
      border-color: var(--cor-secundaria);
    }
  }
`;

export const EmailInput = styled.div`
  position: relative;

  input {
    padding-right: 40px;
  }

  span {
    position: absolute;
    top: 0;
    right: 16px;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
