import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { CheckCircle, Plus, PlusSquare, Trash } from 'react-bootstrap-icons';
import Button from '../../components/Button';

//styles
import { getFromSessionStorage } from '../../constants/functions';
import { useUserContext } from '../../context/UserContext';
import { getAddressByCep, saveAddressInformation } from '../../services/changeAddress';
import {
  AlertContent,
  ButtonGroup,
  CounterField,
  FormGroup,
  GroupsContainer,
  InputsGroup,
  PageTitleContainer,
  RemoveMobile,
  Title,
} from './styles';

const ChangeAddress = () => {
  const [phones, setPhones] = useState([{ type: '', value: '' }]);
  const [emails, setEmails] = useState([{ type: '', value: '' }]);
  const [showAlert, setShowAlert] = useState<boolean>();
  const [cep, setCep] = useState('');
  const [endereco, setEndereco] = useState('');
  const [numero, setNumero] = useState('');
  const [complemento, setComplemento] = useState('');
  const [bairro, setBairro] = useState('');
  const [cidade, setCidade] = useState('');
  const [estado, setEstado] = useState('');

  const [rendaFaturamento, setRendaFaturamento] = useState('');

  const [selectedEndereco, setSelectedEndereco] = useState('001');
  const { userData, getUserData } = useUserContext();

  // function resetFields() {
  //   setPhones([{ type: '', value: '' }]);
  //   setEmails([{ type: '', value: '' }]);
  //   setSelectedEndereco('');
  //   setCep('');
  //   setEndereco('');
  //   setNumero('');
  //   setComplemento('');
  //   setBairro('');
  //   setRendaFaturamento('');
  // }

  function addNewAddress() {
    if (!userData) return;
    setCep('');
    setEndereco('');
    setNumero('');
    setComplemento('');
    setBairro('');
    setRendaFaturamento('');
    userData?.addresses.push({
      '0': '',
      '1': '',
      '15': '',
      '17': '',
      '18': '',
      '19': '',
      '2': '',
      '3': '',
      Observação: '',
      active: '',
      checked: false,
      city: '',
      complement: '',
      firstZipCode: '',
      id: (userData.addresses.length + 1).toString().padStart(3, '0'),
      main: '',
      neighborhood: '',
      number: '',
      secondZipCode: '',
      state: '',
      stateDescription: '',
      street: '',
      zipCode: '',
    });
    console.log(userData.addresses);
    setSelectedEndereco(userData.addresses.length.toString().padStart(3, '0'));
  }
  useEffect(() => {
    console.log(selectedEndereco);
  }, [selectedEndereco]);
  useEffect(() => {
    if (!userData) return;
    const selectedAdd = userData?.addresses.find((address) => Number(selectedEndereco) === Number(address.id));
    if (selectedAdd) {
      setBairro(selectedAdd.neighborhood);
      setEndereco(selectedAdd.street);
      setNumero(selectedAdd.number);
      setCep(selectedAdd.zipCode);
      setCidade(selectedAdd.city);
      setEstado(selectedAdd.stateDescription);
      setComplemento(selectedAdd.complement);
      setRendaFaturamento(userData?.txt_vlrenda + '');
      setEmails(
        userData?.emails.map((email) => {
          return {
            type: '',
            value: email.endereco,
          };
        }) as []
      );
      setPhones(
        userData?.phones.map((phone) => {
          return {
            type: '',
            value: phone.telefoneCompleto,
          };
        }) as []
      );
    }
  }, [userData, selectedEndereco]);
  const handleSalvar = async () => {
    if (userData) {
      const addresses = [
        ...userData.addresses.map((add) => {
          const { zipCode, street, number, complement, neighborhood, city, state } = add;
          return {
            checked: false,
            zipCode,
            street,
            number,
            complement,
            neighborhood,
            city,
            state,
          };
        }),
      ];
      const changedAddressIndex = userData.addresses.findIndex((add) => Number(add.id) === Number(selectedEndereco));
      addresses[changedAddressIndex] = {
        ...addresses[changedAddressIndex],
        zipCode: cep,
        complement: complemento,
        neighborhood: bairro,
        number: numero,
        street: endereco,
        city: cidade,
        state: estado,
      };
      addresses[0].checked = true;
      try {
        const res = await saveAddressInformation({
          cpfCnpj: getFromSessionStorage('cpfCnpj'),
          person: {
            type: userData.txt_pessoa,
            nome: userData.nome,
            renda: userData.txt_pessoa === '1' ? rendaFaturamento : '',
            faturamento: userData.txt_pessoa === '1' ? '' : rendaFaturamento,
            emails: emails.map((email) => email.value),
            phones: phones.map((phone) => phone.value),
          },
          addresses,
        });
        await getUserData();
        if (res && res.status) {
          setShowAlert(true);
          setTimeout(() => setShowAlert(false), 5000);
        }
      } catch (e) {
        console.log(e);
      }
    }
  };
  const handleChangeCEP = async (value: string) => {
    const numberRegex = new RegExp('^[0-9]+$');
    if (value.length < cep.length) {
      return setCep(value);
    }
    if (numberRegex.test(value) || value === '') {
      if (value.length >= 8) {
        try {
          const res = await getAddressByCep(value);
          if (res) {
            setBairro(res.bairro);
            setEndereco(res.rua);
            setCep(res.cep);
            setEstado(res.estado);
            setCidade(res.cidade);
          }
          return;
        } catch (e) {
          console.log(e);
        }
      }
      return setCep(value);
    }
  };

  const handleRendaMask = (e: string) => {
    if (['', ' '].includes(e)) {
      e = '0,00';
    }
    e = e.replace('.', '').replace(',', '').replace(/\D/g, '');

    const options = { minimumFractionDigits: 2 };
    const result = new Intl.NumberFormat('pt-BR', options).format(parseFloat(e) / 100);

    setRendaFaturamento(result);
  };
  const pageContent = () => {
    return (
      <>
        <form>
          <PageTitleContainer>
            <div>
              <Title>Alteração de Endereço</Title>
              <Form.Select
                onChange={(e) => {
                  setSelectedEndereco(e.target.value);
                }}
                value={selectedEndereco}
              >
                {userData?.addresses.map((address) => (
                  <option value={address.id}>
                    {address.street}, {address.number} - {address.complement}, {address.neighborhood}, {address.zipCode}
                  </option>
                ))}
              </Form.Select>
            </div>

            <Button type="reset" onClick={addNewAddress}>
              <PlusSquare /> Adicionar novo endereço
            </Button>
          </PageTitleContainer>

          {showAlert && (
            <AlertContent>
              <CheckCircle size={22} />
              Alterações feita com sucesso!
            </AlertContent>
          )}

          <div className="row">
            <div className="col-md-6">
              <FormGroup controlId="formBasicTYPE">
                <Form.Label>Tipo</Form.Label>
                <Form.Select>
                  <option value="">Padrão</option>
                  {/*<option value="">option 2</option>*/}
                  {/*<option value="">option 3</option>*/}
                </Form.Select>
              </FormGroup>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <FormGroup controlId="formBasicCEP">
                <Form.Label>CEP</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="CEP"
                  value={cep}
                  onChange={(e: any) => {
                    handleChangeCEP(e.target.value);
                  }}
                  required
                />
              </FormGroup>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <FormGroup controlId="streetAddress">
                <Form.Label>Endereço</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Endereço"
                  value={endereco}
                  onChange={(e: any) => setEndereco(e.target.value)}
                  required
                />
              </FormGroup>
            </div>

            <div className="col-sm-6">
              <FormGroup controlId="numberAddress">
                <Form.Label>Número</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Número"
                  value={numero}
                  onChange={(e: any) => setNumero(e.target.value)}
                  required
                />
              </FormGroup>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <FormGroup controlId="complementAddress">
                <Form.Label>Complemento</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Complemento"
                  value={complemento}
                  onChange={(e: any) => setComplemento(e.target.value)}
                />
              </FormGroup>
            </div>

            <div className="col-sm-6">
              <FormGroup controlId="districtAddress">
                <Form.Label>Bairro</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Bairro"
                  value={bairro}
                  onChange={(e: any) => setBairro(e.target.value)}
                  required
                />
              </FormGroup>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <FormGroup controlId="cityAddress">
                <Form.Label>Cidade</Form.Label>
                <Form.Select disabled>
                  <option value={cidade}>{cidade}</option>
                </Form.Select>
              </FormGroup>
            </div>

            <div className="col-sm-6">
              <FormGroup controlId="stateAddress">
                <Form.Label>Estado</Form.Label>
                <Form.Select disabled>
                  <option value={estado}>{estado}</option>
                </Form.Select>
              </FormGroup>
            </div>
          </div>

          <GroupsContainer>
            <div className="row">
              <div className="col-lg-9 col-xl-6">
                <span>Telefone</span>

                {phones.map((item: any, index: any) => (
                  <InputsGroup key={index}>
                    {phones.length > 1 && (
                      <div className="d-flex d-md-none align-items-center justify-content-between w-100 gap-4">
                        <CounterField>#{index + 1}</CounterField>
                        <RemoveMobile
                          type="button"
                          onClick={() => {
                            const _phones = [...phones];
                            _phones.splice(index, 1);
                            setPhones(_phones);
                          }}
                        >
                          <Trash size={22} />
                        </RemoveMobile>
                      </div>
                    )}

                    <Form.Select
                      onChange={(e: any) => {
                        const _phones = [...phones];
                        const phoneBeingChanged = _phones[index];
                        phoneBeingChanged.type = e.target.value;
                        setPhones(_phones);
                      }}
                      value={phones[index].type}
                    >
                      <option value="default">Padrão</option>
                      {/*<option value="optionTwo">option 2</option>*/}
                      {/*<option value="optionThree">option 3</option>*/}
                    </Form.Select>

                    <Form.Control
                      type="text"
                      placeholder="DDD+Telefone"
                      onChange={(e: any) => {
                        const _phones = [...phones];
                        const phoneBeingChanged = _phones[index];
                        phoneBeingChanged.value = e.target.value;
                        setPhones(_phones);
                      }}
                      value={phones[index].value}
                      required
                    />

                    <div className="d-none d-md-block">
                      {index === 0 ? (
                        <Button
                          className={'roundButton icon'}
                          type="button"
                          onClick={() => setPhones([...phones, { type: '', value: '' }])}
                        >
                          <Plus size={22} />
                        </Button>
                      ) : (
                        <RemoveMobile
                          type="button"
                          onClick={() => {
                            const _phones = [...phones];
                            _phones.splice(index, 1);
                            setPhones(_phones);
                          }}
                        >
                          <Trash size={22} color="#DC3545" />
                        </RemoveMobile>
                      )}
                    </div>
                  </InputsGroup>
                ))}

                <div className="d-flex d-md-none align-items-center justify-content-end w-100 gap-4">
                  <Button
                    className={'roundButton'}
                    type="button"
                    onClick={() => setPhones([...phones, { type: '', value: '' }])}
                  >
                    <Plus size={22} />
                    Adicionar novo Número
                  </Button>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-9 col-xl-6">
                <span>E-mail</span>

                {emails.map((item: any, index: any) => (
                  <InputsGroup key={index}>
                    {emails.length > 1 && (
                      <>
                        <div className="d-flex d-md-none align-items-center justify-content-between w-100 gap-4">
                          <CounterField>#{index + 1}</CounterField>
                          <RemoveMobile
                            type="button"
                            onClick={() => {
                              const _emails = [...emails];
                              _emails.splice(index, 1);
                              setEmails(_emails);
                            }}
                          >
                            <Trash size={22} />
                          </RemoveMobile>
                        </div>
                      </>
                    )}

                    <Form.Select
                      onChange={(e: any) => {
                        const _emails = [...emails];
                        const emailBeingChanged = _emails[index];
                        emailBeingChanged.type = e.target.value;
                        setEmails(_emails);
                      }}
                      value={emails[index].type}
                    >
                      <option value="default">Padrão</option>
                      {/*<option value="optionTwo">option 2</option>*/}
                      {/*<option value="optionThree">option 3</option>*/}
                    </Form.Select>

                    <Form.Control
                      type="email"
                      placeholder="Email"
                      onChange={(e: any) => {
                        const _emails = [...emails];
                        const emailBeingChanged = _emails[index];
                        emailBeingChanged.value = e.target.value;
                        setEmails(_emails);
                      }}
                      value={emails[index].value}
                    />

                    <div className="d-none d-md-block">
                      {index === 0 ? (
                        <Button
                          className={'roundButton icon'}
                          onClick={() => setEmails([...emails, { type: '', value: '' }])}
                        >
                          <Plus size={22} />
                        </Button>
                      ) : (
                        <RemoveMobile
                          type="button"
                          onClick={() => {
                            const _emails = [...emails];
                            _emails.splice(index, 1);
                            setEmails(_emails);
                          }}
                        >
                          <Trash size={22} color="#DC3545" />
                        </RemoveMobile>
                      )}
                    </div>
                  </InputsGroup>
                ))}

                <div className="d-flex d-md-none align-items-center justify-content-end w-100 gap-4">
                  <Button
                    className="roundButton"
                    type="button"
                    onClick={() => setEmails([...emails, { type: '', value: '' }])}
                  >
                    <Plus size={22} />
                    Adicionar novo Email
                  </Button>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-5">
                <FormGroup className="m-0" controlId="formBasicBILLING">
                  <Form.Label>Renda/Faturamento</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Renda/Faturamento"
                    required
                    value={rendaFaturamento}
                    onChange={(e: any) => {
                      handleRendaMask(e.target.value);
                    }}
                  />
                </FormGroup>
              </div>
            </div>
          </GroupsContainer>

          <ButtonGroup>
            <Button className="reset" type="reset" onClick={() => window.location.reload()}>
              Restaurar
            </Button>
            <Button className="submit" onClick={() => handleSalvar()}>
              Salvar
            </Button>
          </ButtonGroup>
        </form>
      </>
    );
  };

  return <>{userData && <div>{pageContent()}</div>}</>;
};

export default ChangeAddress;
