import RootRoutes from './routes/rootRoutes';
import { useStylization } from './hooks/useStylization';
import { useEffect } from 'react';
import { UserProvider } from './context/UserContext';
import { NavBarMenuProvider } from './context/NavbarContext';
import { SWRConfig } from 'swr';
import { defaultConfig } from 'swr/_internal';
import { swrAxiosFetcher } from './services/swr';

function App() {
  const { stylization } = useStylization();
  useEffect(() => {
    if (!stylization) return;
    document.documentElement.style.setProperty('--cor-primaria', stylization?.corPrimaria);
    document.documentElement.style.setProperty('--cor-secundaria', stylization?.corSecundaria);
    document.documentElement.style.setProperty('--cor-terciaria', stylization?.corTerciaria);

    const styleId = 'font-style-sheet';
    let fontStyleSheet = document.getElementById(styleId);
    let newFontStyleSheet = document.createElement('style');
    newFontStyleSheet.id = styleId;
    newFontStyleSheet.textContent = `
    @font-face {
      font-family: 'CustomFont';
      src: url(${stylization?.fontePrincipal?.estilo[1].arquivo}) format('woff');
    }
  `;

    if (!fontStyleSheet) {
      document.head.appendChild(newFontStyleSheet);
    }
  }, [stylization]);

  return (
    <SWRConfig
      value={{
        fetcher: swrAxiosFetcher,
        onErrorRetry(err, ...args) {
          const ignoredErrors = [403, 404, 500];

          if (ignoredErrors.includes(err.response?.status)) return;

          defaultConfig.onErrorRetry(err, ...args);
        },
      }}
    >
      <UserProvider>
        <NavBarMenuProvider>
          <RootRoutes />
        </NavBarMenuProvider>
      </UserProvider>
    </SWRConfig>
  );
}

export default App;
