import styled from 'styled-components';

export const Content = styled.h1`
  font-size: 1.5em;
  text-align: center;
  color: palevioletred;
`;
export const Title = styled.h1`
  margin-bottom: 8px !important;
  font-style: normal;
  font-weight: 600;
  line-height: 39px;
  color: #343a40;
  margin-bottom: 16px;
  font-size: 2rem;
  @media (max-width: 768px) {
    font-size: 1.25rem;
  }
`;

export const PageTitleContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 24px;
  justify-content: space-between;
  align-items: center;
`;
export const InfoTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  span {
    font-size: 1rem;
    font-weight: 400;
    color: #6c757d;
  }
  @media print {
    display: none;
  }
`;

export const TotalLine = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 8px;
  font-weight: 600;
  gap: 24px;
`;
export const TotalLineLabel = styled.div`
  padding: 10px;
  line-height: 19px;
  background: var(--cor-terciaria);
  border-radius: 8px;
`;
export const InputRow = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 30px;
`;
export const BoxCard = styled.div`
  background: white;
  padding: 24px;
  border-radius: 8px;
  height: 100%;
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: 32px;

  button {
    border: 1px solid var(--cor-secundaria);
    border-radius: 4px;
  }

  .seeBillets {
    background: none;
    color: var(--cor-secundaria);
    &:hover {
      color: white;
      background: var(--cor-secundaria);
    }
  }
`;
