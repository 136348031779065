import { useMemo, useState } from 'react';
import { DocumentInfo as IDocumentInfo } from '../../pages/Documents';
import Button from '../Button';
import {
  DocumentAlert,
  DocumentBox,
  DocumentContentContainer,
  DocumentCreatedInfo,
  DocumentCreatedInfoDate,
  DocumentCreatedInfoUser,
  DocumentInfo,
  DocumentItem,
  DocumentName,
  DocumentOrder,
  DocumentPreview,
  DocumentText,
  DocumentLink,
} from './styles';
import api from '../../services/api';
import { getFromSessionStorage } from '../../constants/functions';
import { useUserContext } from '../../context/UserContext';
import { FileEarmarkRuled } from 'react-bootstrap-icons';

interface DocumentFileProps {
  file: {
    filename: string;
    path: string;
    url: string;
    documentInfo?: IDocumentInfo;
  };
  order: number;
  canApprove?: boolean;
  onApproveReprove: () => void;
}

const imageExtensions = ['jpg', 'jpeg', 'png'];

export function DocumentFile({ file, order, onApproveReprove, canApprove = true }: DocumentFileProps) {
  const [isLoading, setLoading] = useState(false);

  const { loginResponseData } = useUserContext();

  const { documentInfo } = file;
  const isImage = useMemo(() => {
    const fileExtension = file.filename.split('.').pop() || '';

    return imageExtensions.includes(fileExtension.toLowerCase());
  }, [file]);

  async function handleApproveReprove(isApproving = false) {
    setLoading(true);

    if (!documentInfo) return;

    const requestBody = {
      processo: documentInfo.processo296,
      documento: documentInfo.documento296,
      sequencia: documentInfo.sequencia296,
      cpfCnpj: getFromSessionStorage('cpfCnpj'),
      aprova: isApproving ? 'S' : 'N',
    };

    try {
      await api.post('consorciado/aprovarDocumento', requestBody, {
        headers: { token: getFromSessionStorage('token') },
      });
    } finally {
      onApproveReprove();
      setLoading(false);
    }
  }

  return (
    <DocumentBox key={file.path} xs="12" sm="6" md="4" xl="3">
      <DocumentItem>
        {isImage ? (
          <DocumentPreview src={file.url} />
        ) : (
          <DocumentLink href={file.url} target="_blank" rel="noreferrer">
            <FileEarmarkRuled size="18" />
          </DocumentLink>
        )}
        <DocumentContentContainer>
          <DocumentOrder>{order}</DocumentOrder>
          <DocumentInfo>
            <DocumentName>{file.filename}</DocumentName>
            {!!documentInfo && (
              <>
                <DocumentAlert status={documentInfo.descricaoStatusDocumento}>
                  - {documentInfo.descricaoStatusDocumento} -
                </DocumentAlert>
                <DocumentText>Adicionado por:</DocumentText>
                <DocumentCreatedInfo>
                  <DocumentCreatedInfoUser>
                    {documentInfo.codigoUsuarioAdicionou} - {documentInfo.nomeUsuarioAdicionou}
                  </DocumentCreatedInfoUser>
                  <DocumentCreatedInfoDate>
                    {documentInfo.dataDocumentoAdicionado} | {documentInfo.horaDocumentoAdicionado}
                  </DocumentCreatedInfoDate>
                </DocumentCreatedInfo>
                {documentInfo.dataDocumentoAnalisado !== '00/00/0000' && (
                  <>
                    <DocumentText>Analisado por:</DocumentText>
                    <DocumentCreatedInfo>
                      <DocumentCreatedInfoUser>
                        {documentInfo.codigoUsuarioAnalisou} - {documentInfo.nomeUsuarioAnalisou}
                      </DocumentCreatedInfoUser>
                      <DocumentCreatedInfoDate>
                        {documentInfo.dataDocumentoAnalisado} | {documentInfo.horaDocumentoAnalisado}
                      </DocumentCreatedInfoDate>
                    </DocumentCreatedInfo>
                  </>
                )}
                {documentInfo.descricaoStatusDocumento === 'Pendente' &&
                canApprove &&
                loginResponseData?.usuarioCodigo === documentInfo.codigoUsuarioAdicionou ? (
                  <div
                    style={{
                      display: 'flex',
                      gap: 8,
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: 8,
                    }}
                  >
                    <Button
                      disabled={isLoading}
                      style={{ minWidth: 'auto', flex: 1, height: 'auto', padding: '8px  0', fontWeight: 'bold' }}
                      onClick={() => handleApproveReprove(true)}
                    >
                      Aprovar
                    </Button>
                    <Button
                      disabled={isLoading}
                      style={{ minWidth: 'auto', flex: 1, height: 'auto', padding: '8px  0', fontWeight: 'bold' }}
                      onClick={() => handleApproveReprove(false)}
                    >
                      Reprovar
                    </Button>
                  </div>
                ) : null}
              </>
            )}
          </DocumentInfo>
        </DocumentContentContainer>
      </DocumentItem>
    </DocumentBox>
  );
}
