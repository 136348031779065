import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const Title = styled.h1`
  margin-bottom: 8px !important;
  font-style: normal;
  font-weight: 600;
  line-height: 39px;
  color: #343a40;
  margin-bottom: 16px;
  font-size: 2rem;
  @media (max-width: 768px) {
    font-size: 1.25rem;
  }
`;

export const PageTitleContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 24px;
  justify-content: space-between;
  align-items: center;
`;

export const BoxCard = styled.div`
  background: white;
  padding: 24px;
  border-radius: 8px;
  height: 100%;
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: 32px;
`;

export const LinkButton = styled(NavLink)`
  background: var(--cor-primaria);
  color: #fff !important;
  text-decoration: none;
  padding: 10px 25px;
  border-radius: 4px;

  transition: 0.35s;

  &:hover {
    transition: 0.35s;
    background: var(--cor-secundaria);
  }
`;
