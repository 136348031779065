import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const Title = styled.h1`
  margin-bottom: 8px !important;
  font-style: normal;
  font-weight: 600;
  line-height: 39px;
  color: #343a40;
  margin-bottom: 16px;
  font-size: 2rem;
  @media (max-width: 768px) {
    font-size: 1.25rem;
  }
`;

export const PageTitleContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 24px;
  justify-content: space-between;
  align-items: center;
`;

export const BoxCard = styled.div`
  background: white;
  padding: 24px;
  border-radius: 8px;
  height: 100%;
`;

export const PaymentContainer = styled.div`
  background: #e9ecef;
  padding: 36px 24px 32px;
  border-radius: 8px;
`;
export const HeadPayments = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 34px;

  h2 {
    font-size: 1.75rem;
    font-weight: 600;
    color: #495057;
    margin-bottom: 0 !important;
  }
`;

export const PaymentData = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px;

  span {
    border: 1px solid #adb5bd;
    border-radius: 4px;
    color: #6c757d;
    padding: 5px 16px;
    font-size: 1rem;
    font-weight: 400;
  }
`;

export const LogoBank = styled.div`
  background: #fff;
  padding: 4px 16px;
  border-radius: 8px;

  img {
    max-width: 100px;
    object-fit: cover;
  }
`;

export const PaymentCards = styled.div`
  display: flex;
`;

export const PaymentCardPrimary = styled.button`
  height: 100%;
  width: 100%;
  border: none !important;
  text-align: left;
  background: var(--cor-primaria);
  padding: 24px;
  border-radius: 8px;

  * {
    color: #fff;
  }

  label {
    font-size: 1rem;
    font-weight: 400;
  }

  h3 {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0;
    margin-top: 16px;
  }
`;
export const PaymentCardSecondary = styled.button`
  height: 100%;
  width: 100%;
  border: none !important;
  text-align: left;
  background: var(--cor-secundaria);
  padding: 24px;
  border-radius: 8px;

  h1 ~ p,
  label {
    color: #fff;
  }

  label {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 16px;
  }

  h3 {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0;
  }
`;

export const SelectGroup = styled.div`
  display: flex;
  gap: 10px;
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: 32px;

  .transparent {
    background: none;
    color: var(--cor-secundaria);
    &:hover {
      color: white;
      background: var(--cor-secundaria);
    }
  }
`;

export const LinkButton = styled(NavLink)`
  background: var(--cor-primaria);
  color: #fff !important;
  text-decoration: none;
  padding: 10px 25px;
  border-radius: 4px;

  transition: 0.35s;

  &:hover {
    transition: 0.35s;
    background: var(--cor-secundaria);
  }

  &.transparent {
    background: transparent;
    border: 1px solid var(--cor-secundaria);
    color: #343a40 !important;

    &:hover {
      background: var(--cor-secundaria);
      color: #fff !important;
    }
  }
`;
