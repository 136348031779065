import useSWR, { Fetcher, Key, SWRConfiguration } from 'swr';
import { getFromSessionStorage } from '../constants/functions';
import api from './api';
import { AxiosError } from 'axios';

export const swrAxiosFetcher = (url: string | [string, Record<string, any>]) => {
  const headers = { token: getFromSessionStorage('token') };

  if (Array.isArray(url)) {
    return api.get(url[0], { params: url[1], headers });
  }

  return api.get(url, { headers });
};

export function useAxios<T, E = string>(url: Key, options?: SWRConfiguration<T, AxiosError<E>, Fetcher<T, Key>>) {
  return useSWR<T, AxiosError<E>>(url, options);
}
