import { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { BoxArrowLeft, ChevronDown, ChevronRight, List, Person } from 'react-bootstrap-icons';
import Form from 'react-bootstrap/Form';
import { NavLink } from 'react-router-dom';
import { getFromSessionStorage, setInSessionStorage } from '../../constants/functions';
import { useNavbarContext } from '../../context/NavbarContext';
import { useUserContext } from '../../context/UserContext';
import { useGetWindowSize } from '../../hooks/useGetWindowSize';
import { useStylization } from '../../hooks/useStylization';
import PageList from '../PageList';
import { SmallInfo } from '../Sidenav/styles';
import {
  ButtonCollapse,
  CanvasBody,
  CanvasContainer,
  FalseButton,
  GroupQuotaContainer,
  HeaderContainer,
  HeaderPageContainer,
  LogoContainer,
  LogoSidenav,
  UserContainer,
} from './styles';

const Header = () => {
  const [isShow, setIsShow] = useState(false);
  const { setOpenMenu } = useNavbarContext();
  const handleShow = () => {
    setIsShow(!isShow);
  };

  useEffect(() => {
    if (getFromSessionStorage('minimize')) {
      setTimeout(() => setIsShow(true), 1);
    }
  }, []);
  const { loginResponseData, setGroup, group, makeLogout, setQuota, quota, setDigit } = useUserContext();

  const { stylization } = useStylization();
  const { isMobile } = useGetWindowSize();

  useEffect(() => {
    setOpenMenu(!isShow);
  }, [isShow, setOpenMenu]);

  useEffect(() => {
    if (!isMobile) setIsShow(false);
  }, [isMobile]);

  const handleLoggout = () => {
    makeLogout();
  };
  const style = {
    transform: isShow ? 'rotate(180deg)' : '',
    transition: 'transform 150ms ease', // smooth transition
  };
  useEffect(() => {
    if (!group || !quota) return;

    console.log(group, quota);
    loginResponseData?.consorciados.forEach((consorciado) => {
      console.log(consorciado.grupo.padStart(5, '0') + '_' + consorciado.cota.padStart(4, '0'));
      console.log(group.padStart(5, '0') + '_' + quota.padStart(4, '0'));
    });
  }, [group, quota, loginResponseData]);
  return (
    <HeaderContainer>
      <div className="d-flex align-items-center justify-content-between">
        <ButtonCollapse onClick={handleShow} type="button">
          {isMobile ? <List size={22} /> : <ChevronRight size={14} fontWeight={700} color="black" style={style} />}
        </ButtonCollapse>

        <CanvasContainer show={isShow} onHide={handleShow} scroll={false} backdrop={false} className="d-md-none">
          <CanvasBody>
            <PageList minimize={false} hideLabel={false} onClick={() => handleShow()} />
            <div>
              <SmallInfo>{stylization?.textoRodape}</SmallInfo>
            </div>
          </CanvasBody>
        </CanvasContainer>

        <LogoContainer>
          <NavLink to="/">
            <LogoSidenav src={stylization?.logoSidebar} />
          </NavLink>
        </LogoContainer>
        <FalseButton />
      </div>

      <HeaderPageContainer>
        <GroupQuotaContainer>
          <p>Grupo / Quota</p>
          <div className="position-relative d-flex align-items-center">
            <Form.Select
              aria-label="Default select example"
              value={group.padStart(5, '0') + '_' + quota?.padStart(4, 0)}
              id={'select'}
              onChange={(e) => {
                const [group, quota] = e.target.value.split('_');
                const selected = loginResponseData?.consorciados.find(
                  (cons) => Number(cons.grupo) === Number(group) && Number(cons.cota) === Number(quota)
                );

                if (selected) {
                  setInSessionStorage('cpfCnpj', selected.cpfCnpj);
                  setDigit(selected.digito);
                  setInSessionStorage('digit', selected.digito);
                }

                setGroup(group);
                setQuota(quota);
                setInSessionStorage('group', group);
                setInSessionStorage('quota', quota);
              }}
              htmlSize={1}
            >
              {loginResponseData?.consorciados.map((consorciado) => (
                <option
                  key={consorciado.cota}
                  value={consorciado.grupo.padStart(5, '0') + '_' + consorciado.cota.padStart(4, '0')}
                >
                  {consorciado.grupo} / {consorciado.cota}.{consorciado.digito}
                </option>
              ))}
            </Form.Select>

            <div className="icon" style={{ marginLeft: '-30px' }}>
              <ChevronDown size={14} fontWeight={700} color="black" />
            </div>
          </div>
        </GroupQuotaContainer>

        <UserContainer>
          <Dropdown align={{ lg: 'end' }}>
            <Dropdown.Toggle className="p-0">
              <Person size={22} />
              <span className="d-none d-md-flex">{loginResponseData?.usuarioApelido}</span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {/*<Dropdown.Item href="#/action-1">*/}
              {/*  <Lock size={20} />*/}
              {/*  Alterar senha*/}
              {/*</Dropdown.Item>*/}
              <Dropdown.Item href="#/action-2" style={{ color: 'red' }} onClick={() => handleLoggout()}>
                <BoxArrowLeft size={20} /> Sair
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </UserContainer>
      </HeaderPageContainer>
    </HeaderContainer>
  );
};

export default Header;
