import api from '../api';
import { getFromSessionStorage } from '../../constants/functions';

export const getConsolidatedPosition = async (gruposCotas?: string) => {
  const url = `/consorciado/consolidada`;

  const params = {} as Record<string, any>;

  params.cpfCnpj = getFromSessionStorage('cpfCnpj');

  try {
    const res = (await api.get(url, {
      headers: { token: getFromSessionStorage('token') },
      params,
    })) as any;
    return res as ConsolidatedPositionProp;
  } catch (e) {
    return null;
  }
};

export interface ConsolidatedPositionProp {
  contempladas: any[];
  naoContempladas: naoContempladasProp[];
  total: { saldoDevedor: string; totalCotas: string; valorParcela: string; saldoPago: string; credito: string };
}

export interface naoContempladasProp {
  saldoDevedor: string;
  percentualDevedor: string;
  valorParcela: string;
  percentualPago: string;
  data: string;
  saldoPago: string;
  cota: string;
  grupo: string;
  credito: string;
}
