import React from 'react';

import HomePage from '../pages/home/';

const homeRoutePage = {
  component: <HomePage />,
  route: '/',
};

export { homeRoutePage };
