import 'bootstrap/dist/css/bootstrap.min.css';

import { useEffect, useState } from 'react';

//styles
import { Alert, Col, Form, Row } from 'react-bootstrap';
import { ResultType, Title, TotalInfo } from './styles';
import { BoxCard } from '../BilletCreation/styles';
import { CardInfo } from '../../components/CardInfo';
import { TitleBox } from '../../components/CardInfo/styles';
import { ExtractTable } from '../../components/ExtractTable';
import { useUserContext } from '../../context/UserContext';
import { useAxios } from '../../services/swr';

export type ContemplationResult = 'Pendente' | 'Lance' | 'Sorteio';

export interface AssemblyResultsResumeData {
  grupo: string;
  prazo: string;
  totalSorteio: string;
  totalSorteioPend: string;
  totalSorteioGer: string;
  totalLance: string;
  totalLancePend: string;
  totalLanceGer: string;
  nroAssembleiaAtual: string;
  dataAssembleiaAtual: string;
  totalSorteioLance: string;
  assembleias: {
    [key: string]: string;
  };
  resultados: {
    grupo: string;
    quota: string;
    digito: string;
    codContemplacao: string;
    tipoContemplacao: ContemplationResult;
    assContemplacao: string;
    dataContemplacao: string;
    lance: string;
  }[];
}

export default function AssemblyResultsResume() {
  const { group } = useUserContext();
  const [assemblies, setAssemblies] = useState<[string, string][]>([]);
  const [assembly, setAssembly] = useState<string>();

  const { data, error } = useAxios<AssemblyResultsResumeData, string>(
    ['consorciado/assembleia/resultadoResumido', { grupo: group, nroAssembleia: assembly }],
    {
      onSuccess: (data) => {
        if (!data.assembleias) return;

        setAssemblies(Object.entries(data.assembleias));
      },
    }
  );

  useEffect(() => {
    setAssemblies([]);
    setAssembly(undefined);
  }, [group]);

  return (
    <main>
      <Row className="pt-4 pb-2 gy-2">
        <Col lg="8">
          <Title>Resultado Assembleias Resumido</Title>
        </Col>

        <Form.Group as={Col} lg="4">
          <Form.Select value={assembly} onChange={(e) => setAssembly(e.target.value)}>
            <option value="">Todas Assembleias</option>
            {assemblies.map(([key, value]) => (
              <option key={key} value={key}>
                {value}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Row>

      {error && (
        <Alert key={'primary'} variant={'primary'}>
          {error.response?.data || 'Não foi possível carregar os dados.'}
        </Alert>
      )}

      {data && (
        <Row className="g-3 mt-3">
          <Col lg="4">
            <BoxCard>
              <CardInfo fontSize="lg" title="Grupo" info={data.grupo} />
            </BoxCard>
          </Col>
          <Col lg="4">
            <BoxCard>
              <CardInfo fontSize="lg" title="Prazo" info={`${data.prazo} meses`} />
            </BoxCard>
          </Col>
          <Col lg="4">
            <BoxCard>
              <CardInfo fontSize="lg" title="Assembleia" info={assembly ? data.assembleias[assembly] : 'Todas'} />
            </BoxCard>
          </Col>

          <Col xs="12">
            <BoxCard>
              <TitleBox>
                <h2>Resumo de contemplações </h2>
              </TitleBox>
              <div className="d-flex flex-wrap flex-sm-row flex-column justify-content-around align-items-center">
                <div className="d-flex flex-column mx-2">
                  <div className="d-flex">
                    <p className="fw-semibold">por Sorteio</p>
                    <p className="ms-2">{data.totalSorteio || 0}</p>
                  </div>
                  <div className="d-flex mt-3">
                    <p className="fw-semibold">por Lance</p>
                    <p className="ms-2">{data.totalLance || 0}</p>
                  </div>
                </div>
                <div className="d-flex flex-column mx-2 mt-2 mt-sm-0">
                  <div className="d-flex">
                    <p className="fw-semibold">Crédito Pendente</p>
                    <p className="ms-2">{data.totalSorteioPend || 0}</p>
                  </div>
                  <div className="d-flex mt-3">
                    <p className="fw-semibold">Crédito Pendente</p>
                    <p className="ms-2">{data.totalLancePend || 0}</p>
                  </div>
                </div>
                <div className="d-flex flex-column mx-2 mt-2 mt-sm-0">
                  <div className="d-flex">
                    <p className="fw-semibold">Total sorteio + pendente</p>
                    <p className="ms-2">{data.totalSorteioGer || 0}</p>
                  </div>
                  <div className="d-flex mt-3">
                    <p className="fw-semibold">Total lance + pendente</p>
                    <p className="ms-2">{data.totalLanceGer || 0}</p>
                  </div>
                </div>
                <div className="d-flex flex-column mx-2 mt-2 mt-sm-0 align-items-center p-2">
                  <TotalInfo>Total sorteio + lance</TotalInfo>
                  <p className="fw-semibold mt-2">{data.totalSorteioLance || 0}</p>
                </div>
              </div>
            </BoxCard>
          </Col>

          <Col xs="12" className="d-flex align-items-center justify-content-center">
            <BoxCard style={{ maxWidth: '80rem', width: '100%' }}>
              <ExtractTable
                rowsGroup={['Quota', 'Tipo', 'Assembleia de contemplação']}
                data={data.resultados.map(({ quota, tipoContemplacao, assContemplacao, dataContemplacao }) => [
                  quota,
                  <ResultType type={tipoContemplacao}>{tipoContemplacao[0].toUpperCase()}</ResultType>,
                  `${assContemplacao} - ${dataContemplacao}`,
                ])}
              />
            </BoxCard>
          </Col>
        </Row>
      )}
    </main>
  );
}
