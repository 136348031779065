import styled from 'styled-components';
interface ListItemProps {
  active: boolean;
  minimize?: boolean;
}
export const SidenavMenuListItemMobile = styled.li<ListItemProps>`
  color: white;
  font-size: 0.75rem;
  background: ${({ active }) => (active ? 'var(--cor-secundaria)' : 'transparent')};
  border-radius: 8px;
  cursor: pointer;
  transition: 0.5s;
  width: 100%;
  padding: 12px;
  display: flex;
  gap: 10px;
`;
export const SidenavMenuListItemDesktop = styled.li<ListItemProps>`
  color: white;
  font-size: 0.75rem;
  background: ${({ active }) => (active ? 'var(--cor-secundaria)' : 'transparent')};
  border-radius: 8px;
  cursor: pointer;
  //transition: 0.5s;
  width: 100%;
  padding: 12px;
  display: flex;
  gap: 10px;

  justify-content: ${({ minimize }) => (minimize ? 'center' : 'start')};
  align-items: center;
  height: ${({ minimize }) => (minimize ? '60px' : 'auto')};
  transition: all 0.2s linear;

  &:last-child {
    margin-bottom: 0;
  }
  :hover {
    background: var(--cor-secundaria);
  }
`;
export const IconContainer = styled.div`
  svg {
    width: 18px;
    height: 18px;
  }
`;
