import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../../components/Button';

export const PageTitleContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 24px;
  justify-content: space-between;
  align-items: center;
`;
export const Title = styled.h1`
  margin-bottom: 8px !important;
  font-style: normal;
  font-weight: 600;
  line-height: 39px;
  color: #343a40;
  margin-bottom: 16px;
  font-size: 2rem;
  @media (max-width: 768px) {
    font-size: 1.25rem;
  }
`;

export const BoxCard = styled.div`
  background: white;
  height: 100%;
  padding: 24px 32px;
  border-radius: 8px;

  h3 {
    color: #343a40;
    font-size: 1.75rem;
    font-weight: 600;
    margin: 0;

    @media (max-width: 768px) {
      font-size: 1.25rem;
    }
  }

  h4 {
    color: #495057;
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0;
  }

  h5 {
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
  }

  p {
    color: #343a40;
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
  }
`;

export const GroupCota = styled.h2`
  color: #6c757d;
  font-size: 1.75rem;
  font-weight: 600;
  margin-bottom: 16px;
`;

export const InfoWithColor = styled.div`
  .status {
    display: flex;
    gap: 6px;
    color: ${({ color }) => color};

    .tabBigWithColor {
      background: ${({ color }) => color};
      width: 3px;
      max-height: 40px;
      border-radius: 8px;
    }
  }

  h3 {
    margin-top: 8px;
    margin-bottom: 4px;
  }
`;

export const InfoWithButton = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin-top: 4px;

  .info {
    background: #ffc10733;
    border-radius: 4px;
    padding: 8px 10px;
    min-width: 150px;
    max-width: 180px;
    display: flex;
    align-items: center;
    gap: 8px;

    .icon {
      flex-shrink: 0;
    }

    small {
      color: #664d03;
      font-size: 0.75rem;
      font-weight: 500;
      line-height: 14.52px;
    }
  }
`;

export const BilletEmissionRedirect = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 12px;
  gap: 10px;

  width: 46px;
  height: 46px;

  /* Siens/Primary (dark) */

  background: var(--cor-primaria);
  color: white;
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
`;

export const ButtonInfo = styled(Button)`
  min-width: inherit;
  width: inherit;
  height: 100%;
  padding: 14px 17px;
  flex-shrink: 0;
`;

export const DeadlineDate = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 15px;

  h5 {
    color: #adb5bd;
  }

  .dates {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 4px;
    @media (max-width: 576px) {
      flex-direction: row;
      flex-wrap: wrap;
    }

    .date {
      background: #adb5bd;
      padding: 3.5px 12px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;

      small {
        color: #fff;
        font-size: 0.75rem;
        font-weight: 400;
      }
    }
  }
`;

export const DeadLineContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 52px;
  @media (max-width: 768px) {
    gap: 48px;
  }
`;
export const InfoDeadLine = styled.div`
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  @media (max-width: 768px) {
    align-items: center;
    margin-top: 16px;
  }

  span {
    padding: 6px 12px;
    border: 1px solid #6c757d;
    color: #6c757d;
    font-size: 0.875rem;
    font-weight: 400;
    border-radius: 4px;
  }
`;

export const QuickAccessContainer = styled.div`
  margin-top: 18px;

  h2 {
    color: #343a40;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 16px;
  }
`;

export const QuickAccessButton = styled(NavLink)`
  background: var(--cor-secundaria);
  color: #fff !important;
  width: 100%;
  height: 100%;
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 18px;
  text-decoration: none;
  border-radius: 8px;

  font-size: 1.25rem;
  line-height: 24px;
  transition: background 0.35s;

  &:hover {
    background: var(--cor-primaria);
    transition: background 0.35s;
  }
`;

export const SlideContainer = styled.div`
  margin-top: 22px;
`;
export const SlideBox = styled.div`
  background: white;
  padding: 24px;
  border-radius: 8px;

  h4 {
    margin: 0;
    color: #495057;
  }
`;
export const ValuesSlide = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 24px;

  h5 {
    margin-top: 8px;
    margin-bottom: 4px;
    color: #343a40;
  }
`;
export const StatusSmall = styled.div`
  display: flex;
  gap: 4px;
  color: #adb5bd;

  .tabBigWithColor {
    background: #adb5bd;
    width: 2px;
    max-height: 20px;
    border-radius: 8px;
  }
`;
export const LinkToQuota = styled.button`
  text-decoration: none;
  color: #343a40;
  border: 1px solid var(--cor-primaria);
  padding: 10px 12px;
  border-radius: 4px;
  font-size: 0.875rem;
  transition: 0.35s;
  background: white;

  &:hover {
    background: var(--cor-primaria);
    color: #fff;
    transition: 0.35s;
  }
`;
export const SlidesButton = styled.div`
  margin: 18px 0;
  display: flex;
  justify-content: start;

  button {
    border: 0.842093px solid #dee2e6;
    color: var(--cor-primaria);
    background: #fff;
    padding: 10px 13px;

    opacity: 1;
    transition: opacity 0.15s;

    &.left {
      border-radius: 4px 0 0 4px;
    }

    &.right {
      border-radius: 0 4px 4px 0;
    }

    svg {
      margin-top: -2px;
    }

    &:disabled {
      opacity: 0.25;
      transition: opacity 0.15s;
    }
  }
`;
