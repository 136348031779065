import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useRef, useState } from 'react';

//styles
import { Alert } from 'react-bootstrap';
import { Printer } from 'react-bootstrap-icons';
import Button from '../../components/Button';
import Table from '../../components/Table';
import { useUserContext } from '../../context/UserContext';
import { useGetWindowSize } from '../../hooks/useGetWindowSize';
import { getGroupInformation, GroupInfo } from '../../services/group';
import {
  BaseDate,
  ButtonsContainer,
  GroupsAvailabilityPageContainer,
  PageTitleContainer,
  Title,
  TitleRow,
} from './styles';
// @ts-ignore
import { useReactToPrint } from 'react-to-print';
import { PrintingVersion } from '../../components/PrintingVersion';

const collumns = ['Descrição da Conta', 'Mensal (R$)', 'Acumulado (R$)'];
const GroupsAvailability = () => {
  const { isMobile } = useGetWindowSize();
  const { group } = useUserContext();
  const ref = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => ref?.current,
  });

  const [groupInfo, setGroupInfo] = useState<GroupInfo>({} as GroupInfo);
  useEffect(() => {
    const func = async () => {
      if (!group) return;

      try {
        const info = await getGroupInformation(group as string);
        setGroupInfo(info as GroupInfo);
      } catch (e) {
        console.log(e);
      }
    };
    func();
  }, [group]);

  const formatDate = () => {
    let txt = '';
    if (!groupInfo.dt_dispant) return txt;
    let [, month, year] = groupInfo.dt_dispant.split('/');
    const months = [
      '',
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ];
    txt += months[Number(month)];
    if (isMobile) {
      txt = txt.slice(0, 3);
      txt += `/${year}`;
    } else {
      txt += ` de ${year}`;
    }

    return txt;
  };

  const pageContent = () => {
    return (
      <>
        <PageTitleContainer>
          <Title>Disponibilidade dos Grupos - {group}</Title>
          {groupInfo.grupo && (
            <ButtonsContainer>
              <BaseDate>
                Data base: <strong>{formatDate()}</strong>
              </BaseDate>

              <Button
                onClick={() => {
                  handlePrint();
                }}
                hideOnPrint={true}
              >
                <Printer /> Imprimir
              </Button>
            </ButtonsContainer>
          )}
        </PageTitleContainer>

        <GroupsAvailabilityPageContainer>
          {!groupInfo || !groupInfo.grupo ? (
            <Alert variant={'danger'}>{groupInfo && groupInfo['1']}</Alert>
          ) : (
            <>
              {!isMobile && (
                <TitleRow>
                  {collumns.map((data, index) => (
                    <p className={'p' + index} key={'p' + index}>
                      {data}
                    </p>
                  ))}
                </TitleRow>
              )}

              <Table
                titleRow={{
                  data: [`Disponibilidades (em ${groupInfo.dt_dispant}`, groupInfo.vlr_dispant, ''],
                  color: '#fff9e7',
                }}
                rows={[
                  ['Caixa', groupInfo.vlr_caixant, ''],
                  ['Depósitos Bancários', groupInfo.vlr_depbancant, ''],
                  ['Cheque em Cobrança', groupInfo.vlr_chequeant, ''],
                  ['Aplicação financeira do Grupo', groupInfo.vlr_aplicfinancgrp, ''],
                  ['Aplicação financeira Veiculadas a Contemplações', groupInfo.vlr_aplicvinccont, ''],
                ]}
                mobilePseudoCollumnLabels={collumns}
              />

              <Table
                titleRow={{
                  data: ['(+) Recursos coletados', groupInfo.vlr_recoletado, groupInfo.vlr_totrecoletado],
                  color: '#eaf7ed',
                }}
                rows={[
                  ['Contribuições para Aquisição de Bens', groupInfo.vlr_aquisicaocol, groupInfo.vlr_totaquisicaocol],
                  ['Taxa de Administração', groupInfo.vlr_txadmcol, groupInfo.vlr_totxadmcol],
                  ['Contribuições ao Fundo de Reserva', groupInfo.vlr_txfrcol, groupInfo.vlr_totxfrcol],
                  [
                    'Rendimentos de Aplicações Financeiras',
                    groupInfo.vlr_rendaplicfinancol,
                    groupInfo.vlr_totrendaplicfinancol,
                  ],
                  ['Multa e Juros Moratórios', groupInfo.vlr_muljurcol, groupInfo.vlr_totmuljurcol],
                  ['Prêmios de Seguro', groupInfo.vlr_segurocol, groupInfo.vlr_totsegurocol],
                  ['Custas Judiciais', groupInfo.vlr_desjudicol, groupInfo.vlr_totdesjudicol],
                  ['Reembolso de Despesas de Registro', groupInfo.vlr_despregcol, groupInfo.vlr_totdespregcol],
                  ['Ourtos', groupInfo.vlr_outroscol, groupInfo.vlr_totoutroscol],
                ]}
                mobilePseudoCollumnLabels={collumns}
              />

              <Table
                titleRow={{
                  data: ['(-) Recursos Utilizados', groupInfo.vlr_recutil, groupInfo.vlr_totrecutil],
                  color: '#fcebed',
                }}
                rows={[
                  ['Aquisição de Bens', groupInfo.vlr_aquisicaoutil, groupInfo.vlr_totaquisicaoutil],
                  ['Taxa de Administração', groupInfo.vlr_txadmutil, groupInfo.vlr_totxadmutil],
                  ['Multa e Juros Moratórios', groupInfo.vlr_muljurutil, groupInfo.vlr_totmuljurutil],
                  ['Prêmios de Seguros', groupInfo.vlr_seguroutil, groupInfo.vlr_totseguroutil],
                  ['Custas Judiciais', groupInfo.vlr_despjudicutil, groupInfo.vlr_totdespjudicutil],
                  ['Devolução a Consorciados Desligados', groupInfo.vlr_devolutil, groupInfo.vlr_totdevolutil],
                  ['Despesas de Registro de Contrato', groupInfo.vlr_despregutil, groupInfo.vlr_totdespregutil],
                  ['Ourtos', groupInfo.vlr_outrosutil, groupInfo.vlr_totoutrosutil],
                ]}
                mobilePseudoCollumnLabels={collumns}
              />
              <Table
                titleRow={{
                  data: [`Disponibilidades (em ${groupInfo.dt_dispatu}`, groupInfo.vlr_dispatu, ''],
                  color: '#e8f6f8',
                }}
                rows={[
                  ['Caixa', groupInfo.vlr_caixatu, ''],
                  ['Depósitos Bancários', groupInfo.vlr_depbancatu, ''],
                  ['Cheque em Cobrança', groupInfo.vlr_chequeatu, ''],
                  ['Aplicação financeira do Grupo', groupInfo.vlr_aplicfinancgrpatu, ''],
                  ['Aplicação financeira Veiculadas a Contemplações', groupInfo.vlr_aplicvinccontatu, ''],
                ]}
                mobilePseudoCollumnLabels={collumns}
              />
            </>
          )}
        </GroupsAvailabilityPageContainer>
      </>
    );
  };

  return (
    <>
      <div ref={ref}>
        <PrintingVersion>{pageContent()}</PrintingVersion>
      </div>

      <div>{pageContent()}</div>
    </>
  );
};
export default GroupsAvailability;
