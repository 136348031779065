import 'bootstrap/dist/css/bootstrap.min.css';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';

import { CardInfo } from '../../components/CardInfo';
import { ExtractTable } from '../../components/ExtractTable';
import { PrintingVersion } from '../../components/PrintingVersion';

//styles
import Button from '../../components/Button';
import { calcTotalByCollum, formatMoney } from '../../constants/functions';
import { useUserContext } from '../../context/UserContext';
import { BilletCreationDataProps, emitNewBillet, getBilletCreationData } from '../../services/billetCreation';
import {
  BoxCard,
  ButtonGroup,
  HeadPayments,
  LogoBank,
  PageTitleContainer,
  PaymentCardPrimary,
  PaymentCardSecondary,
  PaymentContainer,
  PaymentData,
  SelectGroup,
  Title,
} from './styles';
import { useAxios } from '../../services/swr';

interface BanksResponse {
  bancos: Array<{
    id: string;
    name: string;
  }>;
}

const PaymentOptions = () => {
  const { group, quota, digit } = useUserContext();

  const { data: billetInfo } = useAxios<BilletCreationDataProps>(['boleto', { grupo: group, quota, digito: digit }]);

  const { data: banksInfo } = useAxios<BanksResponse>('bancos');

  const bankName = useMemo(() => {
    if (!banksInfo || !billetInfo) return;
    const bank = banksInfo.bancos.find((b) => b.id === billetInfo.bco_aviso_mensal);
    return bank?.name;
  }, [billetInfo, banksInfo]);

  const [interval, setInterval] = useState<{ start: string; finish: string }>({ start: '', finish: '' });
  const [singular, setSingular] = useState('');

  const [radioSelected, setRadioSelected] = useState('total');

  function cardSelectOption(event: any) {
    if (event.target.checked) {
      setRadioSelected(event.target.id);
    }

    if (event.target.id === radioSelected) {
      event.currentTarget.value = true;
    }
  }

  const renderParecelas = useCallback(() => {
    if (!billetInfo) return [];
    return billetInfo.aberto.map((boleto) => boleto.parcela);
  }, [billetInfo]);

  useEffect(() => {
    if (!billetInfo) return;
    const par = renderParecelas()[0];
    if (!par) return;
    setInterval({ start: par, finish: par });
    setSingular(par);
  }, [billetInfo, renderParecelas]);

  const handleBilletEmission = async () => {
    if (!billetInfo) return;

    try {
      let body: any = {
        grupo: group,
        quota: quota,
        digito: digit,
        banco: billetInfo.bco_aviso_mensal,
      };
      if (radioSelected === 'total') {
        body = {
          ...body,
          opcao: 'D',
          envio: 'N',
        };
      }
      if (radioSelected === 'installmentsNumber') {
        body = {
          ...body,
          opcao: 'P',
          historico: '003',
          parcela_unica: singular,
          envio: 'N',
        };
      }
      if (radioSelected === 'ploInterval') {
        body = {
          ...body,
          opcao: 'I',
          historico: '008',
          parcela_inicial: interval.start,
          parcela_final: interval.finish,
          envio: 'N',
        };
      }
      if (radioSelected === 'dischargeInterval') {
        body = {
          ...body,
          opcao: 'T',
          envio: 'N',
        };
      }
      const res = await emitNewBillet(body);
      if (res && res.publicLink) window.open(res.publicLink, '_blank');
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    console.log(interval);
    if (interval.start || !interval.finish) {
      // setInterval({ ...interval, finish: interval.start });
    }
  }, [interval]);
  const pageContent = () => {
    return (
      <>
        <PageTitleContainer>
          <Title>Emissão de boleto bancário</Title>
        </PageTitleContainer>
        <div className="row gy-3 gx-4">
          <div className="col-lg-6">
            <BoxCard>
              <CardInfo title="Bem objeto" info={billetInfo?.cod_nome_bem} />
            </BoxCard>
          </div>
          <div className="col-lg-6">
            <BoxCard>
              <CardInfo title="Crédito atual" info={formatMoney(billetInfo?.valcredi)} />
            </BoxCard>
          </div>
          <div className="col-lg-6">
            <BoxCard>
              <CardInfo title="Prazo Cota" info={billetInfo?.prazo_quota + ' meses'} />
            </BoxCard>
          </div>
          <div className="col-lg-6">
            <BoxCard>
              <CardInfo title="Assembleia" info={billetInfo?.ass_ref + ' - ' + billetInfo?.data_ass_ref} />
            </BoxCard>
          </div>
          <div className="col-lg-12">
            <BoxCard>
              <ExtractTable
                title="Total a pagar"
                withLink={(e) => {
                  setSingular(billetInfo?.aberto[Number(e)].parcela + '');
                  setRadioSelected('installmentsNumber');
                }}
                rowsGroup={[
                  'Parcela',
                  'Vencimento',
                  'Assembleia',
                  'Dias para vencer',
                  'Valor parcela',
                  'Multa/Juros',
                  'Valor a pagar',
                ]}
                data={[
                  ...(billetInfo?.aberto?.map((boleto) => {
                    const { assembleia, dias, multa_juros, parcela, valor_parcela, valor_total, vencimento } = boleto;
                    return [
                      parcela,
                      vencimento,
                      assembleia,
                      dias,
                      formatMoney(valor_parcela),
                      formatMoney(multa_juros),
                      formatMoney(valor_total),
                    ];
                  }) as []),
                ]}
                totalValues={
                  billetInfo?.percdife && billetInfo.valdfp
                    ? [
                        '',
                        '',
                        '',
                        '',
                        'Diferença de Parcelas:',
                        billetInfo && billetInfo?.percdife + '%',
                        billetInfo && formatMoney(billetInfo.valdfp),
                      ]
                    : []
                }
                totalValuesTwo={[
                  '',
                  '',
                  '',
                  '',
                  'Total a pagar:',
                  billetInfo && formatMoney(calcTotalByCollum(billetInfo.aberto, 'multa_juros')),
                  billetInfo && formatMoney(billetInfo.totalpagar),
                ]}
              />
            </BoxCard>
          </div>
          <div className="col-lg-12">
            <PaymentContainer>
              <HeadPayments>
                <h2>Opções de pagamento</h2>

                <PaymentData>
                  <LogoBank>{bankName || '-'}</LogoBank>

                  <span>
                    Vencimento do boleto: <strong>{billetInfo?.data_vcto_limite}</strong>
                  </span>
                </PaymentData>
              </HeadPayments>

              <Form>
                <div className="row g-2">
                  <div className="col-sm-6 col-lg-3">
                    <PaymentCardPrimary type="button" onClick={() => setRadioSelected('total')}>
                      <Form.Check
                        type="radio"
                        label="Pagar total de débitos"
                        name="paymentChoose"
                        id="total"
                        onChange={(event) => cardSelectOption(event)}
                        checked={radioSelected === 'total'}
                      />
                      <h3>{formatMoney(billetInfo?.totalpagar)}</h3>
                    </PaymentCardPrimary>
                  </div>

                  <div className="col-sm-6 col-lg-3">
                    <PaymentCardSecondary type="button" onClick={() => setRadioSelected('installmentsNumber')}>
                      <Form.Check
                        type="radio"
                        label="Pagar um número de parcela"
                        name="paymentChoose"
                        id="installmentsNumber"
                        onChange={(event) => cardSelectOption(event)}
                        checked={radioSelected === 'installmentsNumber'}
                      />

                      <Form.Select onChange={(e: any) => setSingular(e.target.value)} value={singular}>
                        {renderParecelas()?.map((parcelaNum) => (
                          <option value={parcelaNum} key={parcelaNum}>
                            {parcelaNum}
                          </option>
                        ))}
                      </Form.Select>
                    </PaymentCardSecondary>
                  </div>
                  <div className="col-sm-6 col-lg-3">
                    <PaymentCardSecondary type="button" onClick={() => setRadioSelected('ploInterval')}>
                      <Form.Check
                        type="radio"
                        label="Pagar intervalo de parcelas"
                        name="paymentChoose"
                        id="ploInterval"
                        onChange={(event) => cardSelectOption(event)}
                        checked={radioSelected === 'ploInterval'}
                      />

                      <SelectGroup>
                        <Form.Select
                          onChange={(e: any) => {
                            const _interval = { ...interval };
                            if (e.target.value >= interval.finish) {
                              _interval.finish = e.target.value;
                            }
                            setInterval({ ..._interval, start: e.target.value });
                          }}
                        >
                          {renderParecelas()?.map((parcelaNum) => (
                            <option value={parcelaNum} key={parcelaNum}>
                              {parcelaNum}
                            </option>
                          ))}
                        </Form.Select>
                        <Form.Select
                          onChange={(e: any) => {
                            setInterval({ ...interval, finish: e.target.value });
                          }}
                        >
                          {renderParecelas()
                            ?.filter((parcela) => parcela >= interval.start)
                            .map((parcelaNum) => (
                              <option value={parcelaNum} key={parcelaNum}>
                                {parcelaNum}
                              </option>
                            ))}
                        </Form.Select>
                      </SelectGroup>
                    </PaymentCardSecondary>
                  </div>

                  <div className="col-sm-6 col-lg-3">
                    <PaymentCardPrimary type="button" onClick={() => setRadioSelected('dischargeInterval')}>
                      <Form.Check
                        type="radio"
                        label="Pagar o saldo para quitação"
                        name="paymentChoose"
                        id="dischargeInterval"
                        onChange={(event) => cardSelectOption(event)}
                        checked={radioSelected === 'dischargeInterval'}
                      />
                      <h3>{formatMoney(billetInfo?.saldodevedor)}</h3>
                    </PaymentCardPrimary>
                  </div>
                </div>
              </Form>
            </PaymentContainer>
          </div>
        </div>
        <ButtonGroup>
          <Button onClick={() => handleBilletEmission()}>Gerar novo boleto</Button>
        </ButtonGroup>
      </>
    );
  };

  return (
    <>
      {billetInfo && (
        <>
          <PrintingVersion>{pageContent()}</PrintingVersion>

          <div>{pageContent()}</div>
        </>
      )}
    </>
  );
};

export default PaymentOptions;
