import React from 'react';
import { Navigate } from 'react-router-dom';
import { getFromSessionStorage } from '../../constants/functions';

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  if (!getFromSessionStorage('token')) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default RequireAuth;
