import { Accordion } from 'react-bootstrap';
import { useGetWindowSize } from '../../hooks/useGetWindowSize';
import {
  AccordionCustomHeader,
  ColoredSubTitleRow,
  InfosRowsGroups,
  MainHeader,
  PseudoCollumnValue,
  PseudoRow,
  PseudoRowContainer,
  PseudoRowTitle,
  Row,
  RowGroup,
  SubgroupContainer,
} from './styles';

interface ExtractProps {
  title: string;
  rows: any[];
  secondRow?: any[];
}

export const Extract = ({ title, rows, secondRow }: ExtractProps) => {
  const { isMobile } = useGetWindowSize();

  return (
    <SubgroupContainer color={title}>
      {isMobile ? (
        <>
          <Accordion defaultActiveKey={['0']} alwaysOpen>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <AccordionCustomHeader>
                  <MainHeader>{title}</MainHeader>
                </AccordionCustomHeader>
              </Accordion.Header>

              <Accordion.Body>
                {rows.map((row, index) => (
                  <PseudoRowContainer key={'r' + index}>
                    <PseudoRowTitle>{row[0]}</PseudoRowTitle>
                    {row.map((data: any, index: number) => {
                      if (index === 0 || !data || data === '') return <></>;
                      return (
                        <PseudoRow>
                          <PseudoCollumnValue>{row[index]}</PseudoCollumnValue>
                        </PseudoRow>
                      );
                    })}
                  </PseudoRowContainer>
                ))}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </>
      ) : (
        <>
          <ColoredSubTitleRow>
            <h2>{title}</h2>
          </ColoredSubTitleRow>

          <InfosRowsGroups>
            <RowGroup>
              {rows.map((row, index) => (
                <Row key={'r' + index}>
                  {row.map((data: any, index: number) =>
                    index === 0 ? <h4 key={data}>{data}</h4> : <p key={data}>{data}</p>
                  )}
                </Row>
              ))}
            </RowGroup>

            {secondRow && (
              <RowGroup>
                {secondRow.map((row, index) => (
                  <Row key={'r' + index}>
                    {row.map((data: any, index: number) =>
                      index === 0 ? <h4 key={data}>{data}</h4> : <p key={data}>{data}</p>
                    )}
                  </Row>
                ))}
              </RowGroup>
            )}
          </InfosRowsGroups>
        </>
      )}
    </SubgroupContainer>
  );
};
