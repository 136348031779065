import { Alert, Form } from 'react-bootstrap';
import styled from 'styled-components';

export const Title = styled.h1`
  margin-bottom: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 39px;
  color: #343a40;
  font-size: 2rem;
  @media (max-width: 768px) {
    font-size: 1.25rem;
  }
`;

export const BoxCard = styled.div`
  background: white;
  padding: 24px;
  border-radius: 8px;
  height: 100%;
`;

export const AlertContent = styled(Alert)`
  background: #cee8d6;
  color: #065919;
  border: 1px solid #065919;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 18px 32px;
`;

export const PageTitleContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
  align-items: end;
  margin-bottom: 32px;
  .form-switch .form-check-input {
    width: 50px;
    height: 30px;
  }
`;

export const ResetButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: var(--cor-secundaria);
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 12px;
  font-size: 0.875rem;
  font-weight: 400;
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: 32px;

  button {
    border: 1px solid var(--cor-secundaria);
    border-radius: 4px;
  }

  .reset {
    background: none;
    color: #343a40;
    border-color: var(--cor-primaria);
    &:hover {
      color: #fff;
      background: var(--cor-secundaria);
      border-color: var(--cor-secundaria);
    }
  }

  .submit {
    border-color: var(--cor-primaria);
    background: var(--cor-primaria);
    color: #fff;
    &:hover {
      border-color: var(--cor-secundaria);
      background: var(--cor-secundaria);
    }
  }
`;

export const FormGroup = styled(Form.Group)`
  margin: 8px 0;
`;

export const GroupsContainer = styled.div`
  margin-top: 22px;

  span {
    margin-bottom: 8px;
  }
  .roundButton {
    border-radius: 50rem !important;
    max-width: fit-content;
    padding: 10px 12px;
  }
  .icon {
    min-width: 39px;
    max-width: 39px;
  }
  .row {
    margin-bottom: 32px;
  }
`;

export const InputsGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  //margin-bottom: 32px;
  margin-top: 8px;

  @media (max-width: 768px) {
    border-radius: 4px;
    margin-bottom: 16px;
    background: #e9ecef;
    flex-direction: column;
    padding: 16px;
  }
`;

export const CounterField = styled.span`
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 0 !important;
`;
export const RemoveMobile = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  color: #343a40;
`;
export const AddNewMobile = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: var(--cor-secundaria);
  color: #fff;
  margin-left: 8px;
  padding: 10px 12px;
  margin-bottom: 32px;
`;
export const AddNewDesktop = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: var(--cor-secundaria);
  color: #fff;
  border-radius: 100%;
  width: 39px;
  height: 39px;
  margin-left: 8px;
`;
