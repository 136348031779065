import React from 'react';
import CurrencyInput, { CurrencyInputProps } from 'react-currency-input-field';

export type OfferInputType = 'percent' | 'money' | 'integer';

interface OfferInputProps extends CurrencyInputProps {
  type: OfferInputType;
}

export function OfferInput({ type, ...rest }: OfferInputProps) {
  if (type === 'money') {
    return (
      <CurrencyInput
        className="form-control"
        allowNegativeValue={false}
        intlConfig={{ currency: 'BRL', locale: 'pt-BR' }}
        decimalsLimit={2}
        decimalScale={2}
        {...rest}
      />
    );
  }

  if (type === 'percent') {
    return (
      <CurrencyInput
        className="form-control"
        allowNegativeValue={false}
        suffix="%"
        decimalsLimit={4}
        decimalScale={4}
        {...rest}
      />
    );
  }

  return (
    <CurrencyInput
      className="form-control"
      allowNegativeValue={false}
      disableGroupSeparators
      allowDecimals={false}
      step={1}
      {...rest}
    />
  );
}
