import { Info, TitleBox } from './styles';

interface CardInfoProps {
  title: string;
  info?: string;
  fontSize?: 'sm' | 'md' | 'lg';
}

const fontSizeLookup = {
  sm: '1rem',
  md: '1.25rem',
  lg: '1.75rem',
};

export function CardInfo({ title, fontSize = 'md', info }: CardInfoProps) {
  return (
    <>
      <TitleBox>
        <h2>{title}</h2>
      </TitleBox>

      {info && <Info fontSize={fontSizeLookup[fontSize]}>{info}</Info>}
    </>
  );
}
