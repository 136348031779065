const secret = '@N3T4T3ND';
const cipher = (salt: any) => {
  const textToChars = (text: string) => text.split('').map((c) => c.charCodeAt(0));
  const byteHex = (n: any) => ('0' + Number(n).toString(16)).substr(-2);
  const applySaltToChar = (code: any) => textToChars(salt).reduce((a, b) => a ^ b, code);

  return (text: string) => text.split('').map(textToChars).map(applySaltToChar).map(byteHex).join('');
};

const decipher = (salt: string) => {
  const textToChars = (text: string) => text.split('').map((c) => c.charCodeAt(0));
  const applySaltToChar = (code: any) => textToChars(salt).reduce((a, b) => a ^ b, code);
  return (encoded: any) =>
    encoded
      .match(/.{1,2}/g)
      .map((hex: string) => parseInt(hex, 16))
      .map(applySaltToChar)
      .map((charCode: number) => String.fromCharCode(charCode))
      .join('');
};

const myChipher = cipher(secret);
const myDecipher = decipher(secret);
export const getFromSessionStorage = (key: string) => {
  const encriptedKey = myChipher(key);
  return sessionStorage.getItem(encriptedKey) ? myDecipher(sessionStorage.getItem(encriptedKey)) : null;
};

export const setInSessionStorage = (key: string, value: string) => {
  const encriptedKey = myChipher(key);
  const encriptedValue = myChipher(value);
  sessionStorage.setItem(encriptedKey.toString(), encriptedValue.toString());
};

export const formatMoney = (val: string | number | undefined) => {
  if (!val) {
    return '';
  }
  if (typeof val === 'string') {
    val = val.replace(/\./g, '').replace(',', '.');
  }
  return 'R$ ' + new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(Number(val));
};

export const formatNumberMoney = (val: string | number | undefined) => {
  if (!val) {
    return '';
  }

  return 'R$ ' + new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(Number(val));
};
export const calcTotalByCollum = (arr: any[], key: string | number) => {
  // new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
  return new Intl.NumberFormat('pt-BR').format(
    arr?.reduce((accumulator, curr) => {
      let val = curr[key];
      if (typeof val === 'string') {
        val = val.replace(/\./g, '').replace(',', '.');
      }
      const cleanValue = Number(val);
      return accumulator + cleanValue;
    }, 0)
  );
};

export function splitLastOccurrence(str: string, substring: string) {
  const lastIndex = str.lastIndexOf(substring);

  const before = str.slice(0, lastIndex);

  const after = str.slice(lastIndex + 1);

  return [before, after];
}
