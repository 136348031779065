import styled from 'styled-components';

export const TitleBox = styled.div`
  background: var(--cor-secundaria);
  padding: 10px 16px;
  border-radius: 8px;
  margin-bottom: 16px;

  h2 {
    color: #fff;
    font-weight: 600;
    margin: 0;
    font-size: 1.25rem;
  }
`;

export const Info = styled.h2<{ fontSize: string }>`
  font-weight: 600;
  font-size: ${({ fontSize }) => fontSize};
  color: #495057;
  margin: 0 8px;
`;
