import { Table } from 'react-bootstrap';
import { BoxTable, ColoredSubTitleRow, IssueBillet, ItemHead, TotalValue, TotalValueWithBackground } from './styles';

interface ExtractProps {
  title?: string;
  data?: any;
  rowsGroup?: any;
  totalValues?: any;
  totalValuesTwo?: any;
  titleCenter?: boolean;
  withLink?: (index: number) => void;
  linkIndex?: number;
  indexesDisabled?: number[];
  titleBackgroundColor?: string;
}

export const ExtractTable = ({
  title,
  data,
  rowsGroup,
  totalValues,
  totalValuesTwo,
  withLink,
  titleCenter = false,
  linkIndex = 0,
  indexesDisabled = [],
  titleBackgroundColor,
}: ExtractProps) => {
  return (
    <>
      {title && (
        <ColoredSubTitleRow titleCenter={titleCenter} titleBackgroundColor={titleBackgroundColor}>
          <h3>{title}</h3>
        </ColoredSubTitleRow>
      )}

      <BoxTable>
        <Table striped bordered hover>
          <thead>
            <tr>
              {rowsGroup.map((tableHead: any) => (
                <th>
                  <ItemHead>{tableHead}</ItemHead>
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {data?.map((dataRow: any, indexRow: number) => (
              <tr key={Math.ceil(Math.random() * 1000000)}>
                {dataRow?.map((dataRowInfo: any, index: any) => (
                  <td key={Math.ceil(Math.random() * 1000000)}>
                    {withLink && index === linkIndex && !indexesDisabled.includes(indexRow) ? (
                      <IssueBillet onClick={() => withLink(indexRow)}>
                        <span> {dataRowInfo} </span>
                      </IssueBillet>
                    ) : (
                      <span className={indexesDisabled.includes(indexRow) && index === linkIndex ? 'disabled' : ''}>
                        {dataRowInfo}
                      </span>
                    )}
                  </td>
                ))}
              </tr>
            ))}

            <tr>
              {totalValues?.map((totalValue: any) => (
                <td key={totalValue + Math.random()}>
                  {totalValue === 'Diferença de Parcelas: ' ? (
                    <TotalValueWithBackground>
                      <strong>{totalValue}</strong>
                    </TotalValueWithBackground>
                  ) : (
                    <TotalValue>{totalValue}</TotalValue>
                  )}
                </td>
              ))}
            </tr>

            <tr>
              {totalValuesTwo?.map((totalValueTwo: any) => (
                <td key={totalValueTwo + Math.random()}>
                  {totalValueTwo === 'Total à Pagar: ' ? (
                    <TotalValueWithBackground>
                      <strong>{totalValueTwo}</strong>
                    </TotalValueWithBackground>
                  ) : (
                    <TotalValue>{totalValueTwo}</TotalValue>
                  )}
                </td>
              ))}
            </tr>
          </tbody>
        </Table>
      </BoxTable>
    </>
  );
};
