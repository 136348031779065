import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useMemo, useCallback, useRef, useState } from 'react';

//styles
import {
  Accordion,
  Card,
  Dropdown,
  DropdownButton,
  Form,
  InputGroup,
  Overlay,
  Popover,
  useAccordionButton,
} from 'react-bootstrap';
import { CheckCircle, Eye, InfoCircleFill, Percent, Printer, Trash } from 'react-bootstrap-icons';
import { useReactToPrint } from 'react-to-print';
import Button from '../../components/Button';
import { formatMoney, getFromSessionStorage } from '../../constants/functions';
import { useUserContext } from '../../context/UserContext';
import { Bid, calculateOffers, getOffers, saveOffers, OffersRes } from '../../services/bidOffer';
import { AlertContent } from '../BilletSend/styles';
import { PageTitleContainer, Title } from '../ChangeAddress/styles';
import { ButtonsContainer } from '../GroupsAvailability/styles';
import {
  AllQuotasOffer,
  BidEditButton,
  CardHead,
  Collumn,
  Forms,
  InputLine,
  Offer,
  OfferForm,
  Row,
  Tag2,
  Title2,
} from './styles';
import ModalInfo from '../../components/ModalInfo';
import { OfferInput, OfferInputType } from '../../components/Form/OfferInput';
import api from '../../services/api';

const lanceTypeLookupTable: { [key: string]: OfferInputType } = {
  '1': 'integer',
  '2': 'percent',
  '3': 'money',
};

// @ts-ignore
function CustomToggle({ children, eventKey, onClick, hierarchy = 'primary', disabled = false }) {
  const decoratedOnClick = useAccordionButton(eventKey, onClick);

  return (
    <Button
      type="button"
      onClick={(e: any) => {
        decoratedOnClick(e);
      }}
      disabled={disabled}
      hierarchy={hierarchy}
    >
      {children}
    </Button>
  );
}

interface BidOption {
  lanceFormaContemplacaoQta: string;
  lanceCodRegraApuracao: string;
  lanceGrupo: string;
  lanceCota: string;
  lanceTipoLance: string;
  lanceOferta: string;
  embutido: boolean;
  percEmbutido: string;
  selectedRule: any;
}

export interface Regra {
  codigo: string;
  prioridade: string;
  forma: string;
  descricao: string;
  minimo: string;
  maximo: string;
  quantidade: string;
  limite: string;
  maxLanceEmbutido: string;
  tipoCalcLanceEmbutido: string;
  notMapped: string;
}

export type ConsolidadaResponse = {
  contempladas: Array<{
    grupo: string;
    cota: string;
    data: string;
    valorParcela: string;
    saldoDevedor: string;
    percentualDevedor: string;
    saldoPago: string;
    percentualPago: string;
    credito: string;
  }>;
  naoContempladas: Array<{
    grupo: string;
    cota: string;
    data: string;
    valorParcela: string;
    saldoDevedor: string;
    percentualDevedor: string;
    saldoPago: string;
    percentualPago: string;
    credito: string;
  }>;
  total: {
    credito: string;
    totalCotas: string;
    saldoPago: string;
    saldoDevedor: string;
    valorParcela: string;
  };
};

const BidOffer = () => {
  const { userData, group, quota } = useUserContext();

  const [showAlert] = useState<boolean>();

  const [view, setView] = useState<'all' | 'form'>('all');

  const [formStep, setFormStep] = useState(0);

  const [offersInitial, setOffersInitial] = useState<Bid[]>();

  const [gruposCotas, setGruposCotas] = useState<any[]>();
  const [contemplation, setContemplation] = useState<Regra[]>();
  const [offersForm, setOffersForm] = useState<BidOption[]>([]);
  const [errorsForm, setErrorsForm] = useState<string[]>([]);
  const [allFilters, setAllFilters] = useState('');
  const [percEmbutido, setPercEmbutido] = useState<string[]>([]);

  const [revisionOffers, setRevisionOffers] = useState<any[]>([]);

  const [finalOffers, setFinalOffers] = useState<any[]>([]);
  useEffect(() => {
    if (!userData) return;

    const cpfCnpj = getFromSessionStorage('cpfCnpj');

    api
      .get<any, ConsolidadaResponse>('/consorciado/consolidada', {
        params: { cpfCnpj },
      })
      .then((data) => {
        const naoContempladas = data.naoContempladas;

        setGruposCotas(
          naoContempladas.map((n) => ({
            cota: n.cota,
            grupo: n.grupo,
          }))
        );
      });
  }, [userData, group, quota]);

  const searchOffers = useCallback(async () => {
    const { res, success } = await getOffers(gruposCotas as any[]);

    if (!success) {
      // setMessage(res.err['1'] ?? 'Ocorreu um erro inesperado!');
      // handleShow();
      return;
    }

    setOffersInitial(res.lances);

    return res;
  }, [gruposCotas]);

  useEffect(() => {
    const func = async () => {
      try {
        if (!gruposCotas) return;
        const res = await searchOffers();
        if (!res) return;
        setOffersForm(
          res.lances.map(
            (lance: any) =>
              ({
                lanceGrupo: lance.lanceGrupo,
                lanceCota: lance.lanceCota,
                lanceTipoLance: lance.lanceTipoLance,
                lanceOferta: lance.lanceOferta,
                lanceCodRegraApuracao: lance.lanceCodRegraApuracao,
                selectedRule: {
                  codigo: '1',
                  prioridade: '1',
                  forma: '1',
                },
              } as BidOption)
          )
        );
        setContemplation(res.regras);
      } catch (e) {
        console.log(e);
      }
    };
    func();
  }, [gruposCotas, searchOffers]);

  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const [isNew, setIsNew] = useState(false);
  const ref = useRef(null);
  const printRef = useRef(null);

  const handleTooltip = (event: any) => {
    setShow(!show);
    setTarget(event.target);
  };

  const updateErrors = (field: string, error: boolean) => {
    if (error) {
      setErrorsForm([...errorsForm.filter((err) => err !== field), field]);
      return;
    }

    setErrorsForm(errorsForm.filter((err) => err !== field));
  };

  const hasError = (field: string) => {
    return errorsForm.some((error) => error === field);
  };

  const handleCalculate = async () => {
    try {
      let _offers = [...offersForm];
      _offers = _offers.filter((offer, index) => {
        if (!offersInitial) return false;
        // if (['0'].includes(offer.lanceOferta) && offersInitial[index].lanceOferta === ' ') {
        //
        // }
        return !(['0'].includes(offer.lanceOferta) && offersInitial[index].lanceOferta === ' ');
        // return offer.lanceOferta && offer.lanceOferta != '0' && offer.lanceTipoLance;
      });
      const res = await calculateOffers(_offers);
      if (res.err) {
        // handleShow();
        // setMessage(res.err['1']);
        setView('all');
        setTimeout(() => {
          setView('form');
        }, 10);
        return;
      }
      setRevisionOffers(res);
    } catch (e) {
      console.log(e);
    }
    setFormStep(1);
  };
  const handleSave = async () => {
    try {
      let _offers = [...offersForm];
      _offers = _offers.filter((offer) => {
        return revisionOffers
          .map((rev) => rev.lanceCota + '' + rev.lanceGrupo)
          .includes(offer.lanceCota + '' + offer.lanceGrupo);
      });

      const percentuaisEmbutidos = _offers.map((offer) => offer.percEmbutido);
      setPercEmbutido(percentuaisEmbutidos);
      const res = await saveOffers(_offers);
      setFinalOffers(res);
    } catch (e) {
      console.log(e);
    }
    setFormStep(2);
  };
  const handleNew = () => {
    if (!offersInitial || !contemplation) return;
    console.log({ contemplation });
    let _offers = [
      ...offersInitial.map(
        (lance) =>
          ({
            lanceGrupo: lance.lanceGrupo,
            lanceCota: lance.lanceCota,
            lanceTipoLance: lance.lanceTipoLance,
            lanceOferta: lance.lanceOferta,
            lanceCodRegraApuracao: lance.lanceCodRegraApuracao,
            selectedRule: contemplation.find((cont) => cont.forma === '99'),
          } as BidOption)
      ),
    ];
    _offers = _offers?.filter((offer) => offer.lanceOferta === ' ') as any;

    for (let i = 0; i < _offers.length; i++) {
      _offers[i].lanceOferta = '0';
      _offers[i].selectedRule = contemplation?.find((cont) => cont.forma === '99');
    }

    setIsNew(true);
    setFormStep(0);
    setOffersForm(_offers);
    setView('form');
  };
  const handleEdit = () => {
    setIsNew(false);
    if (!offersInitial || !contemplation) return;
    const _offers = [
      ...offersInitial.map(
        (lance) =>
          ({
            lanceGrupo: lance.lanceGrupo,
            lanceCota: lance.lanceCota,
            lanceTipoLance: lance.lanceTipoLance,
            lanceOferta: lance.lanceOferta,
            lanceCodRegraApuracao: lance.lanceCodRegraApuracao,
            selectedRule: contemplation.find((cont) => cont.forma === '11'),
          } as BidOption)
      ),
    ];

    for (let i = 0; i < offersInitial.length; i++) {
      if (offersInitial[i].lanceFormaContemplacaoQta < '11') {
        offersInitial[i].lanceFormaContemplacaoQta = '99';
      }
      _offers[i].lanceOferta = Number(offersInitial[i].lanceOferta.replace(',', '.')).toString();
      // _offers[i].lanceTipoLance = '2';
      _offers[i].selectedRule = contemplation?.find(
        (cont) => cont.forma === offersInitial[i].lanceFormaContemplacaoQta
      );
    }

    setOffersForm(_offers);
    setView('form');
  };
  const handleChangeTipoLance = (index: number, lanceTipoLance: string) => {
    if (!offersInitial) return;

    const _offers = [...offersForm];

    const selectedRule = _offers[index].selectedRule;

    const isFixed = Number(selectedRule?.maximo) === 0 && lanceTipoLance === offersInitial[index].lanceTipoLance;

    _offers[index] = {
      ..._offers[index],
      lanceOferta: isFixed ? Number(selectedRule?.minimo ?? 0).toString() : '',
      lanceTipoLance,
    };
    setOffersForm(_offers);
  };

  const rulesForAllCases = useMemo(() => {
    if (!contemplation) return [];

    const grupos = new Map<string, Regra[]>();

    // Agrupar os itens pelo campo 'codigo'
    for (const item of contemplation.filter((c) => c.forma !== '00')) {
      if (!grupos.has(item.codigo)) {
        grupos.set(item.codigo, []);
      }
      grupos.get(item.codigo)?.push(item);
    }

    let resultado: string[] = [];

    for (const grupo of grupos.values()) {
      const decricoes = grupo.map((item) => item.descricao);
      if (resultado.length === 0) {
        resultado = decricoes;
      } else {
        resultado = resultado.filter((descricao) => decricoes.includes(descricao));
      }
    }
    return resultado;
  }, [contemplation]);

  function handleChangeAllOffersRule(ruleDescription: string) {
    if (!ruleDescription || !contemplation) return;

    setOffersForm((state) =>
      state.map((offer) => {
        const selectedRule = contemplation.find(
          (c) => c.descricao === ruleDescription && c.codigo === offer.lanceCodRegraApuracao
        );

        if (!selectedRule) return offer;

        return {
          ...offer,
          percEmbutido: Number(selectedRule.maxLanceEmbutido).toString(),
          selectedRule,
        };
      })
    );

    // TODO
  }

  const allFilterOptions = useMemo(() => {
    if (!offersInitial) return ['3'];

    const options = [...new Set(offersInitial.map((item) => item.lanceTipoLance))];

    options.push('3');

    if (options.length > 2) return ['3'];

    return options;
  }, [offersInitial]);

  useEffect(() => {
    if (!offersForm || !allFilters) return;
    let _offers = [...offersForm];
    _offers = _offers.map((offer) => {
      return {
        ...offer,
        lanceTipoLance: allFilters,
      };
    });
    setOffersForm(_offers);
  }, [allFilters, offersForm]);
  const handlePrint = useReactToPrint({
    content: () => printRef?.current,
  });
  const renderFilter = (data: string) => {
    if (data === '1') {
      return 'Parcelas';
    }
    if (data === '2') {
      return '%';
    }
    if (data === '3') {
      return 'R$';
    }
  };
  // const [message, setMessage] = useState('');
  // const [showModal, setShowModal] = useState(false);
  // const handleClose = () => setShowModal(false);
  // const handleShow = () => setShowModal(true);

  const pageContent = () => {
    return (
      <>
        <PageTitleContainer>
          {/* <ModalInfo message={message} show={showModal} handleClose={handleClose} header={'Alerta'}></ModalInfo> */}
          <Title>Oferta de Lance</Title>
          <ButtonsContainer>
            {view === 'all' ? (
              <>
                {offersInitial && !offersInitial.every((offer) => offer.lanceOferta !== ' ') && (
                  <CustomToggle eventKey={'0'} onClick={() => handleNew()}>
                    Novo Lance
                  </CustomToggle>
                )}

                <BidEditButton hierarchy={'secondary'} onClick={() => handleEdit()}>
                  Editar Lance
                </BidEditButton>
              </>
            ) : (
              <>
                <BidEditButton
                  hierarchy={'secondary'}
                  onClick={() => {
                    searchOffers();
                    setView('all');
                    setIsNew(false);
                  }}
                >
                  Ver todos os Lances
                </BidEditButton>
              </>
            )}
          </ButtonsContainer>
        </PageTitleContainer>

        {showAlert && (
          <AlertContent>
            <CheckCircle size={22} />
            Alterações feita com sucesso!
          </AlertContent>
        )}

        {view === 'form' ? (
          <Forms>
            <Accordion defaultActiveKey="0">
              <Card>
                <CardHead className={formStep >= 1 ? 'finished' : formStep === 0 ? 'active' : ''}>
                  <Percent size={24} />
                  Criar novo Lance
                </CardHead>

                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <div className={'row'}>
                      <div className="col-lg-5 col-xl-3">
                        <AllQuotasOffer>
                          <h3>Lance para todas as cotas</h3>
                          <InputGroup>
                            <OfferInput
                              type={lanceTypeLookupTable[allFilters]}
                              onValueChange={(val) => {
                                let _offers = [...offersForm];
                                _offers = _offers.map((offer) => {
                                  return {
                                    ...offer,
                                    lanceOferta: val || '',
                                  };
                                });
                                setOffersForm(_offers);
                              }}
                            />
                            {/* <Form.Control
                              aria-label="Text input with dropdown button"
                              onChange={(e) => {

                            /> */}

                            <DropdownButton
                              variant="outline-secondary"
                              title={renderFilter(allFilters)}
                              id="input-group-dropdo6"
                              align="end"
                            >
                              {allFilterOptions.map((item) => (
                                <Dropdown.Item key={item} onClick={() => setAllFilters(item)}>
                                  {item === '2' ? '%' : item === '1' ? 'Parcelas' : 'R$'}
                                </Dropdown.Item>
                              ))}
                            </DropdownButton>
                          </InputGroup>

                          <Form.Select
                            aria-label="Default select example"
                            onChange={(e) => handleChangeAllOffersRule(e.target.value)}
                          >
                            {rulesForAllCases.map((ruleDescription) => (
                              <option key={ruleDescription} value={ruleDescription}>
                                {ruleDescription.trim()}{' '}
                              </option>
                            ))}
                          </Form.Select>

                          <Form.Check
                            type={'checkbox'}
                            label={`Lance embutido`}
                            id={`newEmbeddedBid`}
                            // checked={offer.embutido}
                            onChange={(e) => {
                              setOffersForm((state) =>
                                state.map((val, index) => {
                                  const isDisabled =
                                    Number(val.selectedRule?.lanceMaxEmbutido) === 0 &&
                                    val.lanceTipoLance === (offersInitial ?? [])[index]?.lanceTipoLance;

                                  if (isDisabled) {
                                    return val;
                                  }

                                  return { ...val, embutido: e.target.checked };
                                })
                              );
                            }}
                          />
                        </AllQuotasOffer>
                        <div className={'mt-4'}>
                          <CustomToggle
                            onClick={() => handleCalculate()}
                            eventKey={'1'}
                            disabled={offersForm.some((offer) => {
                              if (errorsForm.length > 0) {
                                return true;
                              }
                              const val = offer.selectedRule?.forma === '99' && offer.lanceOferta !== '0';
                              if (isNew) {
                                return val || (offer.selectedRule?.forma !== '99' && offer.lanceOferta === '0');
                              }
                              return val;
                            })}
                          >
                            Confirmar
                          </CustomToggle>
                        </div>
                      </div>

                      <div className="mt-4 mt-lg-0 col-lg-7 col-xl-9">
                        <div className={'d-flex flex-column gap-3 w-100'}>
                          {offersForm?.map((offer, index) => (
                            <OfferForm key={offer.lanceCota}>
                              <h3 className={'fw-bold'}>
                                {offer.lanceGrupo} / {offer.lanceCota}
                              </h3>
                              <InputLine>
                                <Collumn>
                                  <Form.Select
                                    aria-label="Default select example"
                                    value={offer?.selectedRule?.descricao}
                                    onChange={(e) => {
                                      const _offers = [...offersForm];
                                      let selectedRule = contemplation?.find(
                                        (rule) =>
                                          rule.descricao === e.target.value &&
                                          rule.codigo === offer.lanceCodRegraApuracao
                                      );
                                      const isFixed = Number(selectedRule?.maximo) === 0;

                                      _offers[index] = {
                                        ..._offers[index],
                                        percEmbutido: Number(selectedRule?.maxLanceEmbutido).toString(),
                                        lanceOferta: isFixed ? Number(selectedRule?.minimo ?? 0).toString() : '',
                                        selectedRule,
                                      };
                                      setOffersForm(_offers);
                                    }}
                                  >
                                    {contemplation &&
                                      contemplation
                                        .filter(
                                          (cont) => cont.forma !== '00' && cont.codigo === offer.lanceCodRegraApuracao
                                        )
                                        .map((contemplationWay, index) => (
                                          <option value={contemplationWay.descricao}>
                                            {contemplationWay.descricao}
                                          </option>
                                        ))}
                                  </Form.Select>
                                  <Form.Check
                                    type={'checkbox'}
                                    label={`Lance embutido`}
                                    id={`embeddedBid-${index}`}
                                    checked={offer.embutido}
                                    disabled={
                                      Number(offer.selectedRule?.lanceMaxEmbutido) === 0 &&
                                      offer.lanceTipoLance === (offersInitial ?? [])[index]?.lanceTipoLance
                                    }
                                    onChange={(e) => {
                                      const _offers = [...offersForm];
                                      _offers[index] = {
                                        ..._offers[index],
                                        embutido: e.target.checked,
                                      };
                                      setOffersForm(_offers);
                                    }}
                                  />
                                </Collumn>
                                <Collumn>
                                  <InputGroup>
                                    <OfferInput
                                      type={lanceTypeLookupTable[offer.lanceTipoLance]}
                                      value={offer.lanceOferta}
                                      disabled={
                                        Number(offer.selectedRule?.maximo) === 0 &&
                                        offer.lanceTipoLance === (offersInitial ?? [])[index]?.lanceTipoLance
                                      }
                                      onValueChange={(val) => {
                                        const _offers = [...offersForm];
                                        _offers[index] = {
                                          ..._offers[index],
                                          lanceOferta: val || '0',
                                        };
                                        setOffersForm(_offers);
                                      }}
                                    />
                                    <DropdownButton
                                      variant="outline-secondary"
                                      title={renderFilter(offer.lanceTipoLance)}
                                      id="input-group-dropdown-2"
                                      align="end"
                                    >
                                      {(offersInitial ?? [])[index].lanceTipoLance === '2' && (
                                        <Dropdown.Item
                                          onClick={() => {
                                            handleChangeTipoLance(index, '2');
                                          }}
                                        >
                                          %
                                        </Dropdown.Item>
                                      )}

                                      {(offersInitial ?? [])[index].lanceTipoLance === '1' && (
                                        <Dropdown.Item
                                          onClick={() => {
                                            handleChangeTipoLance(index, '1');
                                          }}
                                        >
                                          Parcelas
                                        </Dropdown.Item>
                                      )}
                                      <Dropdown.Item
                                        onClick={() => {
                                          handleChangeTipoLance(index, '3');
                                        }}
                                      >
                                        R$
                                      </Dropdown.Item>
                                    </DropdownButton>
                                  </InputGroup>
                                  <InputGroup>
                                    <OfferInput
                                      type={'percent'}
                                      value={offer.percEmbutido}
                                      disabled={!offersForm[index].embutido}
                                      onValueChange={(val) => {
                                        const _offers = [...offersForm];
                                        _offers[index] = {
                                          ..._offers[index],
                                          percEmbutido: val || '0',
                                        };
                                        setOffersForm(_offers);

                                        const isAboveLimit =
                                          Number(val) >
                                          (offersInitial
                                            ? Number(offersInitial[index].lanceMaxEmbutido.trim().replace(',', '.'))
                                            : 0);

                                        updateErrors(`percEmbutido${index}`, isAboveLimit);
                                      }}
                                    />
                                  </InputGroup>
                                  <div className="redText" hidden={!hasError(`percEmbutido${index}`)}>
                                    Valor acima do permitido
                                  </div>
                                  <div className={'grayText'}>
                                    Lance Máximo:{' '}
                                    {Number(
                                      offersInitial
                                        ? offersInitial[index].lanceQtdeMaximoGrupo.trim().replace(',', '.')
                                        : 0
                                    )}
                                    % <br />
                                    Lance Mínimo:{' '}
                                    {Number(
                                      offersInitial
                                        ? offersInitial[index].lanceQtdeMinimoGrupo.trim().replace(',', '.')
                                        : 0
                                    )}
                                    %<br />
                                    Lance Embutido Máximo:{' '}
                                    {offersInitial ? offersInitial[index].lanceMaxEmbutido.trim().replace(',', '.') : 0}
                                    %
                                  </div>
                                </Collumn>
                                <Collumn className="align-items-end align-items-md-center">
                                  <Trash
                                    size={24}
                                    className={'trashIcon'}
                                    onClick={() => {
                                      const _offers = [...offersForm];
                                      _offers[index] = {
                                        ..._offers[index],
                                        lanceOferta: '0',
                                        embutido: false,
                                      };
                                      _offers[index].selectedRule = contemplation?.find((cont) => cont.forma === '99');
                                      // delete _offers[index].selectedRule;

                                      setOffersForm(_offers);
                                    }}
                                  />
                                </Collumn>
                              </InputLine>
                            </OfferForm>
                          ))}
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <CardHead className={formStep >= 2 ? 'finished' : formStep === 1 ? 'active' : ''}>
                  <Eye />
                  Revisão do Lance
                  {/*<CustomToggle eventKey='1'>Click me!</CustomToggle>*/}
                </CardHead>
                <Accordion.Collapse eventKey="1">
                  <div className={'w-100 d-flex flex-column gap-3'}>
                    {revisionOffers &&
                      revisionOffers.map((offer) => (
                        <Offer>
                          <Row>
                            <Collumn>
                              <div className={'mainData'}>
                                {offer.lanceGrupo} / {offer.lanceCota}{' '}
                              </div>
                              <Tag2>
                                {offersForm.find(
                                  (of) => of.lanceGrupo === offer.lanceGrupo && offer.lanceCota.includes(of.lanceCota)
                                )?.selectedRule?.forma === '11'
                                  ? 'Lance Livre'
                                  : 'Lance Limitado'}
                              </Tag2>
                              {offersForm.findIndex(
                                (of) =>
                                  of.embutido &&
                                  of.lanceGrupo === offer.lanceGrupo &&
                                  offer.lanceCota.includes(of.lanceCota)
                              ) > -1 && <Tag2>Lance Embutido</Tag2>}
                            </Collumn>

                            <Collumn>
                              <div className={'title'}>Oferta</div>
                              <div className={'data'}>
                                {Number(offer.lanceOfertado.split('Porcento')[0].trim().replace(',', '.')) || ''}%
                              </div>
                            </Collumn>

                            <Collumn>
                              <div className={'title'}>
                                Valor Total do Lance <InfoCircleFill onClick={handleTooltip} />
                                <div ref={ref} className={'Valor do Lance a Pagar + Valor do Lance Embutido'}>
                                  <Overlay
                                    show={show}
                                    target={target}
                                    placement="top"
                                    container={ref}
                                    containerPadding={20}
                                  >
                                    <Popover id="popover-contained">
                                      <Popover.Body>Valor do Lance a Pagar + Valor do Lance Embutido</Popover.Body>
                                    </Popover>
                                  </Overlay>
                                </div>
                              </div>
                              <div className={'data'}>{formatMoney(offer.lanceValorTotal)}</div>
                            </Collumn>

                            <Collumn>
                              <div className={'title'}>Crédito</div>
                              <div className={'data'}>{formatMoney(offer['16']) ? formatMoney(offer['16']) : '-'}</div>
                            </Collumn>
                            <Collumn>
                              <div className={'title'}>Crédito Disponível</div>
                              <div className={'data'}>
                                {formatMoney(offer.lanceVlrCredDisponivel)
                                  ? formatMoney(offer.lanceVlrCredDisponivel)
                                  : '-'}
                              </div>
                            </Collumn>
                          </Row>
                          <Row>
                            <Collumn>
                              <div className={'title'}>Percentual Embutido </div>
                              <div className={'data'}>
                                {Number(offer.lanceMsgTextoEmbutido.trim().replace(',', '.')) + '%' || ''}
                              </div>
                            </Collumn>

                            <Collumn>
                              <div className={'title'}>Valor Embutido </div>
                              <div className={'data'}>{formatMoney(offer.lanceEmbutidoVlr)}</div>
                            </Collumn>

                            <Collumn>
                              <div className={'title'}>Percentual em Especie </div>
                              <div className={'data'}>
                                {Number(offer.lanceOfertado.split('Porcento')[0].trim().replace(',', '.')) -
                                  Number(offer.lanceMsgTextoEmbutido.trim().replace(',', '.')) +
                                  '%'}
                              </div>
                            </Collumn>

                            <Collumn>
                              <div className={'title'}>Valor em Especie </div>
                              <div className={'data'}>{formatMoney(offer.lanceAPagarVlr)}</div>
                            </Collumn>
                          </Row>
                          {/*<Collumn>*/}
                          {/*  <div className={'title'}>*/}
                          {/*    Valor do Lance Embutido <InfoCircleFill onClick={handleTooltip} />*/}
                          {/*    <div ref={ref} className={'Valor do Lance a Pagar + Valor do Lance Embutido'}>*/}
                          {/*      <Overlay*/}
                          {/*        show={show}*/}
                          {/*        target={target}*/}
                          {/*        placement="top"*/}
                          {/*        container={ref}*/}
                          {/*        containerPadding={20}*/}
                          {/*      >*/}
                          {/*        <Popover id="popover-contained">*/}
                          {/*          <Popover.Body>*/}
                          {/*            Valor do Lance Embutido a ser descontado do Valor do Crédito quando ocorrer a*/}
                          {/*            confirmação da Contemplação por lance*/}
                          {/*          </Popover.Body>*/}
                          {/*        </Popover>*/}
                          {/*      </Overlay>*/}
                          {/*    </div>*/}
                          {/*  </div>*/}
                          {/*  <div className={'data'}>R$ 0,00</div>*/}
                          {/*</Collumn>*/}
                        </Offer>
                      ))}

                    <div>
                      <div>
                        <Title2>
                          Valor Total dos Lances Ofertados
                          <InfoCircleFill onClick={handleTooltip} />
                          <div ref={ref} className={'Valor do Lance a Pagar + Valor do Lance Embutido'}>
                            <Overlay show={show} target={target} placement="top" container={ref} containerPadding={20}>
                              <Popover id="popover-contained">
                                <Popover.Body>
                                  Se selecionada a opção de Lance Embutido, o valor do Embutido e o valor do Lance em
                                  Espécie devem ser confirmados com a Administradora no momento do pagamento do lance.
                                </Popover.Body>
                              </Popover>
                            </Overlay>
                          </div>
                          <h2>
                            {formatMoney(
                              revisionOffers.reduce(
                                (acc, cur) => Number(cur.lanceValorTotal.replace(/\./g, '').replace(',', '.')) + acc,
                                0
                              )
                            )}
                          </h2>
                        </Title2>
                        <div className={'d-flex gap-3 '}>
                          <CustomToggle eventKey={'0'} onClick={() => setFormStep(0)} hierarchy={'secondary'}>
                            Voltar
                          </CustomToggle>
                          <CustomToggle eventKey={'2'} onClick={() => handleSave()}>
                            Salvar
                          </CustomToggle>
                        </div>
                      </div>
                    </div>
                  </div>
                </Accordion.Collapse>
              </Card>

              <Card>
                <CardHead className={formStep >= 3 ? 'finished' : formStep === 2 ? 'active' : ''}>
                  <Percent size={24}></Percent>
                  Confirmação do Lance
                  {/*<CustomToggle eventKey='0'>Click me!</CustomToggle>*/}
                </CardHead>
                <Accordion.Collapse eventKey="2">
                  <Card.Body ref={printRef}>
                    <div className={'w-100 d-flex flex-column gap-3'}>
                      {finalOffers.map((offer, index) => (
                        <Offer>
                          <Row>
                            <Collumn>
                              <div className={'mainData'}>
                                {offer.lanceGrupo} / {offer.lanceCota}{' '}
                              </div>
                              <Tag2>
                                {offersForm.find(
                                  (of) => of.lanceGrupo === offer.lanceGrupo && offer.lanceCota.includes(of.lanceCota)
                                )?.selectedRule?.forma === '11'
                                  ? 'Lance Livre'
                                  : 'Lance Limitado'}
                              </Tag2>
                              {offersForm.findIndex(
                                (of) =>
                                  of.embutido &&
                                  of.lanceGrupo === offer.lanceGrupo &&
                                  offer.lanceCota.includes(of.lanceCota)
                              ) > -1 && <Tag2>Lance Embutido</Tag2>}
                            </Collumn>

                            <Collumn>
                              <div className={'title'}>Data Assembleia</div>
                              <div className={'data'}>{offer.lanceDataAssembleia}</div>
                            </Collumn>

                            <Collumn>
                              <div className={'title'}>Oferta</div>
                              <div className={'data'}>{offer.lanceOfertado.replace('Porcento', ' %')}</div>
                            </Collumn>
                            <Collumn>
                              <div className={'title'}>Valor Total do Lance</div>
                              <div className={'data'}>
                                {' '}
                                {formatMoney(Number(offer.lanceValorTotal.replace(/\./g, '').replace(',', '.')))}
                              </div>
                            </Collumn>
                            <Collumn>
                              {offer.lanceOfertado === 'Cancelada ' ? (
                                <></>
                              ) : (
                                <>
                                  <div className={'title'}>Senha para consulta</div>
                                  <div className={'data'}>{offer.senha}</div>
                                </>
                              )}
                            </Collumn>
                          </Row>
                          <Row>
                            <Collumn>
                              <div className={'title'}>Percentual Embutido </div>
                              <div className={'data'}>
                                {Number(percEmbutido[index]?.trim().replace(',', '.')) || '0'}%
                              </div>
                            </Collumn>

                            <Collumn>
                              <div className={'title'}>Valor Embutido </div>
                              <div className={'data'}>{formatMoney(offer.lanceEmbutidoVlr)}</div>
                            </Collumn>

                            <Collumn>
                              <div className={'title'}>Percentual em Especie </div>
                              <div className={'data'}>
                                {Number(offer.lanceOfertado.split('Porcento')[0].trim().replace(',', '.')) -
                                  Number(percEmbutido[index]?.trim().replace(',', '.') ?? 0) +
                                  '%'}
                              </div>
                            </Collumn>

                            <Collumn>
                              <div className={'title'}>Valor em Especie </div>
                              <div className={'data'}>{formatMoney(offer.lanceAPagarVlr)}</div>
                            </Collumn>
                          </Row>
                        </Offer>
                      ))}

                      {process.env.REACT_APP_SEGMENTO === 'CNK' && (
                        <div
                          style={{
                            textAlign: 'center',
                            color: '#ff0b07',
                            background: '#ffc',
                            border: '1px solid #ffecb4',
                            borderRadius: '4px',
                            padding: '8px 16px',
                            fontSize: '0.85rem',
                            fontWeight: 'bold',
                          }}
                        >
                          Em casos de lance carta avaliação, direcionar para{' '}
                          <a href="mailto:lance@kasinskiconsorcio.com.br">lance@kasinskiconsorcio.com.br</a>
                          , juntamente com o laudo de avaliação e documento do veículo em nome do titular.
                          <br />
                          <br />
                          Caso os documentos não sejam direcionados para o e-mail acima até um dia útil antes da
                          assembleia, a oferta de lance não será realizada.
                        </div>
                      )}

                      <div>
                        <Button onClick={() => handlePrint()} hideOnPrint={true}>
                          <Printer></Printer> Imprimir
                        </Button>
                      </div>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Forms>
        ) : (
          <div className={'d-flex flex-column gap-3'}>
            {offersInitial &&
              offersInitial
                .filter((offer) => Number(offer.lanceOferta) !== 0)
                .map((offer) => (
                  <Offer>
                    <div className={'d-flex flex-column'}>
                      <div className={'title'}>Grupo/ Quota</div>
                      <div className={'mainData'}>
                        {offer.lanceGrupo} / {offer.lanceCota}
                      </div>
                    </div>

                    <div className="d-flex gap-4 justify-content-between flex-wrap">
                      <div className={'d-flex flex-column'}>
                        <div className={'title'}>Data Assembleia</div>
                        <div className={'data'}>{offer.lanceDataAssembleia}</div>
                      </div>

                      <div className={'d-flex flex-column'}>
                        <div className={'title'}>Oferta</div>
                        <div className={'data'}>{Number(offer.lanceOferta.trim().replace(',', '.'))}%</div>
                      </div>
                    </div>
                  </Offer>
                ))}
          </div>
        )}
      </>
    );
  };

  return <>{userData && <div>{pageContent()}</div>}</>;
};

export default BidOffer;
