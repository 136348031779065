/* eslint-disable @typescript-eslint/no-unused-vars */
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useRef, useState } from 'react';
import { Form, Overlay, Popover } from 'react-bootstrap';
import { Printer } from 'react-bootstrap-icons';
import { useReactToPrint } from 'react-to-print';
import Button from '../../components/Button';
import { Extract } from '../../components/Extract';
import { ExtractTable } from '../../components/ExtractTable';

//styles
import ModalInfo from '../../components/ModalInfo';
import { PrintingVersion } from '../../components/PrintingVersion';
import { PseudoCollumnValue, PseudoRowContainer, PseudoRowTitle } from '../../components/Table/styles';
import { useUserContext } from '../../context/UserContext';
import {
  CurrentAccountStatementInfo,
  getCurrentAccountStatementInfo,
  sendIREmail,
} from '../../services/currentAccoutStatement';
import {
  BoxCard,
  Cell,
  HistogramContainer,
  InputRow,
  PageTitleContainer,
  Title,
  TotalLine,
  TotalLineLabel,
} from './styles';
import { Collumn } from '../BidOffer/styles';
import { formatMoney } from '../../constants/functions';

const CurrentAccountStatement = () => {
  const currentYear = new Date().getFullYear();

  const ref = useRef<HTMLDivElement>(null);
  const [isPrinting, setIsPrinting] = useState<boolean>(false);
  const { group, quota, userData, getUserData } = useUserContext();
  const [accountStatement, setAccountStatement] = useState<CurrentAccountStatementInfo>();
  const [filter, setFilter] = useState('N');
  const [year, setYear] = useState('');
  const [email, setEmail] = useState('');

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    if (!group || !quota) return;
    const func = async () => {
      if (filter === 'I') return;
      try {
        const info = await getCurrentAccountStatementInfo(group + '', quota, filter);
        setAccountStatement(info as CurrentAccountStatementInfo);
        getUserData();
      } catch (e) {
        console.log(e);
      }
    };
    func();
  }, [group, filter, quota, getUserData]);

  useEffect(() => {
    if (!userData) return;

    console.log(userData);
    setEmail(userData ? userData.txt_e_mail_1 : '');
  }, [userData]);

  const handlePrint = useReactToPrint({
    content: () => ref?.current,
    onAfterPrint: () => setIsPrinting(false),
  });
  const handleSendEmail = async () => {
    const res = await sendIREmail(group + '', quota, year, email);
    console.log(res);
    if (res && res.success) {
      handleShow();
    }
  };
  const validEmail = () => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
  const renderHistogramByLetter = (letter: string | undefined) => {
    const res = { color: '', tooltip: '' };
    if (letter === 'A') {
      res.color = '#008000';
      res.tooltip = 'Parcela Paga em Atraso';
    }
    if (letter === 'C') {
      res.color = '#DF7518';
      res.tooltip = 'Parcela Paga com Cheque Pré-datado';
    }
    if (letter === 'D') {
      res.color = '#A1E63D';
      res.tooltip = 'Diferença de Lance';
    }
    if (letter === 'L') {
      res.color = '#0000ff';
      res.tooltip = 'Parcela Paga com o Lance';
    }
    if (letter === 'P') {
      res.color = '#6699cc';
      res.tooltip = 'Parcela Paga em Dia';
    }
    if (letter === 'Q') {
      res.color = '#B67D02';
      res.tooltip = 'Parcela Paga com Seguro de Quebra de Garantia';
    }
    if (letter === 'R') {
      res.color = '#ff0000';
      res.tooltip = 'Parcela Recuperada';
    }
    if (letter === 'V') {
      res.color = '#ff00ff';
      res.tooltip = 'Parcela de TCV Paga (Termo de Compromisso Variável)';
    }
    if (letter === '.') {
      res.color = '#000000';
      res.tooltip = 'Parcela de TCV não Paga (Termo de Compromisso Variável)';
    }
    return res;
  };
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipText, setTooltipText] = useState('');

  const [target, setTarget] = useState(null);
  const refTooltip = useRef(null);

  const handleTooltip = (event: any, text = '') => {
    if (text === '') return;
    setTooltipText(text);
    setShowTooltip(!showTooltip);
    setTarget(event.target);
  };
  const pageContent = () => {
    return (
      <>
        <ModalInfo
          message={'Um email foi encaminhado para o endereço ' + email}
          show={show}
          handleClose={handleClose}
          header={'Sucesso'}
        ></ModalInfo>
        <PageTitleContainer>
          <div>
            <Title>Extrato de Conta Corrente</Title>

            <InputRow>
              <Form.Select onChange={(e: any) => setFilter(e.target.value)}>
                <option value="N">Últimos lançamentos</option>
                <option value="S">Todos os lançamentos</option>
                <option value="I">Para Imposto de Renda</option>
              </Form.Select>
              {filter === 'I' && (
                <Form.Select onChange={(e: any) => setYear(e.target.value)}>
                  <option value="">Ano Base</option>
                  {[...new Array(5)].map((item, index) => (
                    <option value={currentYear - (index + 1)}>{currentYear - (index + 1)}</option>
                  ))}
                </Form.Select>
              )}
            </InputRow>
            {filter === 'I' && (
              <>
                <div style={{ marginBottom: 8 }}>Digite o seu e-mail para o envio do extrato</div>
                <InputRow>
                  <Form.Control onChange={(e: any) => setEmail(e.target.value)} value={email} />
                  <Button
                    onClick={() => {
                      handleSendEmail();
                    }}
                    disabled={!email || !validEmail() || !year || year === ''}
                    hideOnPrint={true}
                  >
                    Enviar
                  </Button>
                </InputRow>
              </>
            )}
          </div>

          {filter !== 'I' && (
            <Button
              onClick={() => {
                handlePrint();
              }}
              hideOnPrint={true}
            >
              <Printer /> Imprimir
            </Button>
          )}
        </PageTitleContainer>

        {filter === 'I' ? (
          <></>
        ) : (
          <div className="row g-4">
            <div className="col-lg-6">
              <BoxCard>
                <Extract
                  title={'Dados Cadastrais'}
                  rows={[
                    ['Data de adesão:', accountStatement?.data_edi],
                    ['Proposta:', accountStatement?.proposta],
                    ['CPF/CNPJ:', accountStatement?.cpf_cnpj_edi],
                    ['RG/IE:', accountStatement?.rg_edi],
                    ['Status da Quota:', accountStatement?.statusCota],
                  ]}
                  secondRow={[
                    ['Endereço de Cobrança:', accountStatement?.end_cob],
                    ['Rua:', accountStatement?.nomrua],
                    ['Número:', accountStatement?.numero + ' - ' + accountStatement?.comple],
                    ['Bairro:', accountStatement?.bairro],
                    ['CEP:', accountStatement?.codcep + '-' + accountStatement?.cplcep],
                    ['Cidade:', accountStatement?.cidade + ' - ' + accountStatement?.uf],
                    ['Telefone/Ramal:', `(${accountStatement?.codddd}) ${accountStatement?.nrofone}`],
                  ]}
                />
              </BoxCard>
            </div>

            <div className="col-lg-6">
              <BoxCard>
                <Extract
                  title={'Bem Objeto do Contrato'}
                  rows={[
                    ['Descrição:', accountStatement?.bem],
                    ['Crédito Atual:', formatMoney(accountStatement?.credito)],
                    ['Prazo:', accountStatement?.prazo_quota],
                    ['Assinatura Referência:', accountStatement?.ass_ref],
                  ]}
                />
              </BoxCard>
            </div>

            <div className="col-12">
              <BoxCard>
                <Extract
                  title={'Informações da Contemplação'}
                  rows={
                    accountStatement
                      ? [
                          ['Assembleia:', accountStatement.ass_cont ? accountStatement.ass_cont : ''],
                          ['Contemplação:', accountStatement.contemplacao ? accountStatement.contemplacao : ''],
                          [
                            'Crédito na Contemplação:',
                            formatMoney(accountStatement.vlr_cred_cont ? accountStatement.vlr_cred_cont : ''),
                          ],
                          [
                            'Valor Aplicado:',
                            formatMoney(accountStatement.vlr_aplicado ? accountStatement.vlr_aplicado : ''),
                          ],
                          ['Valor Pago:', formatMoney(accountStatement.vlr_pago ? accountStatement.vlr_pago : '')],
                          ['Data de Atualização:', accountStatement.dt_pgto ? accountStatement.dt_pgto : ''],
                          [
                            'Valor Pendente:',
                            formatMoney(accountStatement.vlr_atualizado ? accountStatement.vlr_atualizado : ''),
                          ],
                        ]
                      : []
                  }
                />
              </BoxCard>
            </div>
            <div className="col-12">
              <BoxCard>
                <ExtractTable
                  title="Últimos Pagamentos Registrados"
                  rowsGroup={[
                    'Parcela',
                    'Histórico',
                    'Vencimento',
                    'Pagamento',
                    'Valor Devido',
                    'Valor Pago',
                    'Diferença',
                  ]}
                  data={[
                    ...(accountStatement as CurrentAccountStatementInfo).pagamentos.map((_pagamento) => {
                      const { parcela, historico, vencimento, pagamento, devido, pago, diferenca } = _pagamento;
                      return [parcela, historico, vencimento, pagamento, `R$ ${devido}`, `R$ ${pago}`, diferenca];
                    }),
                  ]}
                />

                <TotalLine className="d-none d-md-flex mt-3">
                  <TotalLineLabel>Total Pago: </TotalLineLabel>
                  <div>{formatMoney(accountStatement?.total_pagto)}</div>
                </TotalLine>

                <PseudoRowContainer className="d-md-none gap-3 mt-4">
                  <div>
                    <PseudoRowTitle>Total Pago: </PseudoRowTitle>
                    <PseudoCollumnValue>{formatMoney(accountStatement?.total_pagto)}</PseudoCollumnValue>
                  </div>
                </PseudoRowContainer>
              </BoxCard>
            </div>

            <div className="col-12">
              <BoxCard>
                <ExtractTable
                  title="Total à Pagar"
                  rowsGroup={[
                    'Parcela',
                    'Data vencimento',
                    'Data assembleia',
                    'Dias para vencer',
                    'Valor parcela',
                    'Multa/Juros',
                    'Valor total',
                  ]}
                  data={[
                    ...(accountStatement as CurrentAccountStatementInfo).abertos.map((item) => {
                      const { parcela, vencimento, assembleia, dias, valor, multa_juros, total } = item;
                      return [
                        parcela,
                        vencimento,
                        assembleia,
                        dias + ' dias',
                        formatMoney(valor),
                        formatMoney(multa_juros),
                        formatMoney(total),
                      ];
                    }),
                  ]}
                />
                <TotalLine className="d-none d-md-flex mt-3">
                  <TotalLineLabel>Diferença de Parcelas: </TotalLineLabel>
                  <div>{accountStatement?.percdife}%</div>

                  <div> {formatMoney(accountStatement?.valdfp)}</div>

                  <TotalLineLabel>Total à Pagar: </TotalLineLabel>
                  <div> {formatMoney(accountStatement?.totalpagar)}</div>
                </TotalLine>

                <PseudoRowContainer className="d-md-none gap-3 mt-4">
                  <div>
                    <PseudoRowTitle>Diferença de Parcelas: </PseudoRowTitle>
                    <div className="d-flex justify-content-between flex-wrap gap-2">
                      <PseudoCollumnValue>{accountStatement?.percdife}%</PseudoCollumnValue>
                      <PseudoCollumnValue>{formatMoney(accountStatement?.valdfp)}</PseudoCollumnValue>
                    </div>
                  </div>

                  <div>
                    <PseudoRowTitle>Total à Pagar: </PseudoRowTitle>
                    <PseudoCollumnValue>{formatMoney(accountStatement?.totalpagar)}</PseudoCollumnValue>
                  </div>
                </PseudoRowContainer>
              </BoxCard>
            </div>

            <div className="col-12">
              <BoxCard>
                <Extract
                  title={'Saldo Devedor'}
                  rows={[
                    ['Percentual Amortizado:', accountStatement?.percamor + '%'],
                    ['Percentual Devedor:', accountStatement?.percdeve + '%'],
                    ['Saldo Devedor:', formatMoney(accountStatement?.saldodevedor)],
                  ]}
                />
              </BoxCard>
            </div>

            <div className="col-12">
              <BoxCard>
                <ExtractTable
                  title="Calendário das Próximas 3 Assembleias"
                  rowsGroup={['Assembleia', 'Data Vencimento', 'Data Assembleia', 'Horário', 'Local']}
                  data={[
                    ...(accountStatement as CurrentAccountStatementInfo).assembleias.map((item) => {
                      const { assembleia, vencimento, data, horario, local } = item;
                      return [assembleia, vencimento, data, horario, local];
                    }),
                  ]}
                />
              </BoxCard>
            </div>

            <div className="col-12">
              <BoxCard>
                <Extract
                  title={`Resultado da Última Assembleia nro. ${accountStatement?.nro_ult_ass} em ${accountStatement?.data_ult_ass} - ${accountStatement?.hora_ult_ass}`}
                  rows={[
                    ['Cotas Contemp. Sorteio:', accountStatement?.cont_sorteio],
                    ['Cotas Contemp. Lance:', accountStatement?.lance.join(',')],
                  ]}
                />
              </BoxCard>
            </div>

            <div className={'col-12'}>
              <BoxCard>
                <div className={'d-flex justify-content-between'}>
                  <h2>Opções de pagamento</h2>
                  <div className={'d-flex gap-3'}>
                    <Collumn>
                      <div className={'title'}>Parcelas pagas</div>
                      <div className={'data'}>{accountStatement?.pcl_pagas}</div>
                    </Collumn>
                    <Collumn>
                      <div className={'title'}>Parcelas em aberto</div>
                      <div className={'data'}>{accountStatement?.pcl_aberto}</div>
                    </Collumn>
                  </div>
                </div>
                <div ref={refTooltip}>
                  <Overlay
                    show={showTooltip}
                    target={target}
                    placement="top"
                    container={refTooltip}
                    containerPadding={20}
                  >
                    <Popover id="popover-contained">
                      <Popover.Body>{tooltipText}</Popover.Body>
                    </Popover>
                  </Overlay>
                </div>
                <div className={'d-flex align-itens-center justify-content-center'}>
                  <HistogramContainer>
                    {[
                      ...new Array(Number(accountStatement ? accountStatement.prazo_grupo : 0)).fill('DefaultValue'),
                    ].map((val, index) => (
                      <>
                        <div className={'d-flex flex-column justify-content-start align-items-center'}>
                          <Cell
                            color={renderHistogramByLetter(accountStatement?.histograma[index]).color}
                            onClick={(e) =>
                              handleTooltip(e, renderHistogramByLetter(accountStatement?.histograma[index]).tooltip)
                            }
                          >
                            {index + 1}
                          </Cell>
                          <span style={{ color: renderHistogramByLetter(accountStatement?.histograma[index]).color }}>
                            {!accountStatement?.histograma[index] || accountStatement?.histograma[index] === ' '
                              ? '‎ '
                              : accountStatement?.histograma[index]}
                          </span>
                        </div>
                      </>
                    ))}
                  </HistogramContainer>
                </div>
              </BoxCard>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      {accountStatement && (
        <>
          <div ref={ref}>
            <PrintingVersion>{pageContent()}</PrintingVersion>
          </div>

          <div>{pageContent()}</div>
        </>
      )}
    </>
  );
};

export default CurrentAccountStatement;
