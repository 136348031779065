import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { InfoCircle } from 'react-bootstrap-icons';

//styles
import { getFromSessionStorage } from '../../constants/functions';
import { useNavbarContext } from '../../context/NavbarContext';
import PageList from '../PageList';
import * as S from './styles';
import { MenuContainer, SidenavMenuList } from './styles';
import { useStylization } from '../../hooks/useStylization';

let firstLoad = true;

const Sidenav = () => {
  const [hideLabel, setHideLabel] = useState<boolean>(true);

  const { stylization } = useStylization();
  const [_minimize, setMinize] = useState(!!getFromSessionStorage('minimize'));
  const [style, setStyle] = useState({
    minWidth: '244px',
    transition: 'all .2s linear',
  });
  const { openMenu } = useNavbarContext();

  useEffect(() => {
    setMinize(openMenu);
    if (!openMenu) {
      if (firstLoad) {
        setTimeout(() => {
          firstLoad = false;
        }, 200);
      } else {
        setTimeout(() => setHideLabel(openMenu), 200);
      }
    } else {
      setHideLabel(openMenu);
    }
    setStyle({
      minWidth: !openMenu ? '244px' : '88px',
      transition: 'all .2s linear',
    });
  }, [openMenu]);

  return (
    <S.SidenavContainer style={style}>
      <MenuContainer>
        <SidenavMenuList>
          <PageList minimize={_minimize} hideLabel={hideLabel} />
        </SidenavMenuList>
      </MenuContainer>

      <OverlayTrigger
        placement="right"
        overlay={
          _minimize ? (
            <Tooltip id="tooltip" style={{ marginLeft: '15px' }}>
              {stylization?.textoRodape}
            </Tooltip>
          ) : (
            <></>
          )
        }
      >
        <S.SidenavMenuListItemDesktop active={false} minimize={_minimize}>
          {_minimize ? (
            <S.IconContainer>
              <InfoCircle color="#fff" />
            </S.IconContainer>
          ) : (
            <></>
          )}
          {!hideLabel && <S.SmallInfo>{stylization?.textoRodape} </S.SmallInfo>}
        </S.SidenavMenuListItemDesktop>
      </OverlayTrigger>
    </S.SidenavContainer>
  );
};

export default Sidenav;
