import { Modal } from 'react-bootstrap';
import Button from '../Button';
import React from 'react';

interface IModalInfo {
  message: string;
  show: boolean;
  handleClose: () => void;
  isHtml?: boolean;
  header: string;
}

export const ModalInfo = ({ message, show, handleClose, header, isHtml = true }: IModalInfo) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{header}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ wordWrap: 'break-word' }}>
        {isHtml ? <div dangerouslySetInnerHTML={{ __html: message }} /> : message}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose}>Fechar</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalInfo;
