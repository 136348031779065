import styled from 'styled-components';
import { ContemplationResult } from '.';

export const Title = styled.h1`
  margin: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  line-height: 39px;
  color: #343a40;
  @media (max-width: 768px) {
    font-size: 1.25rem;
  }
`;

export const TotalInfo = styled.div`
  padding: 10px;
  font-weight: 600;
  background: #e9ecef;
  color: #495057;
  border-radius: 8px;
`;

const colors: { [key in ContemplationResult]: { background: string; text: string } } = {
  Pendente: {
    background: '#DC354533',
    text: '#DC3545',
  },
  Sorteio: {
    background: '#17A2B833',
    text: '#17A2B8',
  },
  Lance: {
    background: '#28A74533',
    text: '#28A745',
  },
};

export const ResultType = styled.div<{ type: ContemplationResult }>`
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  border-radius: 0.5rem;
  color: ${({ type }) => colors[type].text};
  background: ${({ type }) => colors[type].background};
`;
