import api from '../api';
import { ILoginFormData, ILoginResponseData } from '../../context/UserContext';
import { getFromSessionStorage, setInSessionStorage } from '../../constants/functions';

export const handleLogin = async (userData: ILoginFormData) => {
  const url = `/consorciado/login`;
  let { group, quota, password, digit, document } = userData;
  try {
    const res = (await api.post(url, {
      grupo: group,
      quota,
      senha: password,
      digito: digit,
      cpfCnpj: document,
    })) as any;

    group = group ? group : res.consorciados[0].grupo;
    quota = quota ? quota : res.consorciados[0].cota;
    digit = digit ? digit : res.consorciados[0].digito;

    setInSessionStorage('loggedWithCpfCnpj', String(Boolean(document)));
    setInSessionStorage('access', res.consorciadoAcesso);
    setInSessionStorage('cpfCnpj', res.consorciadoCpfCnpj);
    setInSessionStorage('segmentoLogin', res.consorciadoSegmento);
    setInSessionStorage('group', group as string);
    setInSessionStorage('quota', quota as string);
    setInSessionStorage('digit', digit + '');
    return res as ILoginResponseData;
  } catch (e) {
    return e;
  }
};
export const requestPassword = async (userData: ILoginFormData) => {
  const url = `/consorciado/solicitaSenha`;
  const { document, email } = userData;

  try {
    const res = (await api.post(url, { cpf_cnpj: document, email: email })) as any;
    return res;
  } catch (e) {
    return e;
  }
};
export const getUserInfo = async () => {
  const url = `/vendedor/consorciado/dados?cpfCnpj=${getFromSessionStorage('cpfCnpj')}`;
  try {
    const res = await api.get(url, { headers: { token: getFromSessionStorage('token') } });
    return res;
  } catch (e) {
    return e;
  }
};
