import { Offcanvas } from 'react-bootstrap';
import styled from 'styled-components';

interface ListItemProps {
  active: boolean;
}

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 20px;
  background: #fff;
  z-index: 1;

  height: 68px;

  @media (max-width: 768px) {
    position: fixed;
    height: 148px;
    flex-direction: column;
    align-items: inherit;
    padding: 0 20px;
  }
`;
export const ButtonCollapse = styled.button`
  padding: 0;
  border: none;
  outline: none;
  border-radius: 100%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  background: #fff;
  @media (min-width: 768px) {
    background: #f8f9fa;
  }
`;
export const HeaderPageContainer = styled.div`
  display: flex;
  padding: 15px 0;
  width: 100%;
  justify-content: space-between;
`;

export const CanvasContainer = styled(Offcanvas)`
  height: calc(100vh - 148px);
  width: 100vw !important;
  margin-top: auto;
  background: var(--cor-primaria);
`;
export const CanvasBody = styled(Offcanvas.Body)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;

  @media (max-width: 768px) {
    padding: 24px 16px;
  }
`;
export const SidenavMenuListItem = styled.li<ListItemProps>`
  color: white;
  font-size: 0.75rem;
  background: ${({ active }) => (active ? 'var(--cor-secundaria)' : 'transparent')};
  border-radius: 8px;
  cursor: pointer;
  transition: 0.5s;
  width: 100%;
  padding: 12px;
  display: flex;
  gap: 10px;
`;
export const IconContainer = styled.div`
  svg {
    width: 18px;
    height: 18px;
  }
`;

export const FalseButton = styled.div`
  width: 22px;
`;

export const GroupQuotaContainer = styled.div`
  display: flex;
  color: #adb5bd;
  flex-direction: column;

  p {
    font-size: 0.75rem;
    margin: 0;
  }

  .form-select {
    border: none;
    box-shadow: none;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 1.25rem;
    color: #495057 !important;
    line-height: 34px;
    padding: 0;
    width: 195px;
    position: relative;
    background: none;
    cursor: pointer;

    option {
      padding-left: 12px !important;
      font-weight: 500;
      font-size: 1.25rem;
    }
  }

  .icon {
    background: #f8f9fa;
    border-radius: 100%;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-left: 8px;
    margin-bottom: 4px;
  }
`;
export const UserContainer = styled.div`
  display: flex;
  align-items: center;

  .btn {
    background: transparent !important;
    border: transparent;
    color: #141414 !important;
    display: flex;
    gap: 12px;
    align-items: center;
    font-weight: 700;
    font-size: 0.75rem;

    &::after {
      font-size: 1.375rem;
      color: #6c757d;
      margin-left: 0 !important;
    }
  }

  .dropdown-menu {
    min-width: 230px;
    right: 0;
    left: auto;
  }

  .dropdown-item {
    padding: 12px 16px;
    display: flex;
    gap: 12px;
    align-items: center;
  }
`;
export const LogoContainer = styled.div`
  height: 68px;
  background: #ffffff;
  display: flex;
  padding: 8px 12px;
  min-width: 210px;
  align-items: center;
  justify-content: center;
`;
export const LogoSidenav = styled.img`
  max-width: 150px;
  max-height: 35px;
`;
