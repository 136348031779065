import { useEffect, useState } from 'react';

interface WindowSize {
  height: number;
  width: number;
}

interface getWindowSizeProps {
  windowSize: WindowSize;
  isMobile: boolean;
}

export const useGetWindowSize = (): getWindowSizeProps => {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    height: 0,
    width: 0,
  });
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const updateSize = () => {
    setWindowSize({
      height: window.innerHeight,
      width: window.innerWidth,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  });
  useEffect(() => {
    setIsMobile(windowSize.width < 768 && windowSize.width > 1);
  }, [windowSize]);
  useEffect(() => {
    updateSize();
  }, []);

  return { windowSize, isMobile };
};
