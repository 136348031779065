import styled from 'styled-components';

export const SubgroupContainer = styled.div<{ color: string }>`
  display: flex;
  flex-direction: column;
  gap: 24px;

  .accordion-item:first-of-type .accordion-button {
    border-radius: 8px;
  }

  .accordion-item {
    border: transparent;
  }

  .accordion-button {
    background: ${({ color }) => color};
  }

  .accordion-body {
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 14px;
  }
`;
export const PseudoRow = styled.div`
  display: flex;
`;
export const PseudoRowTitle = styled.div`
  font-weight: 600;
  font-size: 0.75rem;
  color: #343a40;
`;
export const PseudoRowContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const AccordionCustomHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 4px;
`;
export const MainHeader = styled.div`
  font-weight: 600;
  font-size: 1rem;
  display: flex;
  align-items: center;
  text-align: center;
  color: #343a40;
`;
export const PseudoCollumnLabel = styled.div`
  font-weight: 500;
  font-size: 0.75rem;
  color: #6c757d;
`;
export const PseudoCollumnValue = styled.div`
  font-weight: 400;
  font-size: 0.75rem;
  color: #343a40;
`;
export const Row = styled.div<{ hoverable?: boolean }>`
  padding: 0 10px;

  border-radius: 8px;
  display: flex;
  width: 100%;
  justify-content: space-between;

  p {
    white-space: nowrap;
    overflow: auto;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 130%;
    color: #495057;
  }

  .p0 {
    width: 100%;
    max-width: 180px;
    overflow: visible;
  }

  .p1 {
    min-width: 180px;
    text-align: right;
  }

  .p2 {
    width: 100%;
    text-align: right;
    max-width: 180px;
  }

  :hover {
    ${({ hoverable }) => (hoverable ? `background: #e9ecef` : '')}//background: #e9ecef;;;;;;;;;;
  }
`;
export const ColoredSubTitleRow = styled(Row)<{ color: string }>`
  background: ${({ color }) => color};
  padding: 18px 10px;

  p {
    color: #343a40;
    font-weight: 600;
  }
`;

export const RowGroup = styled.div`
  display: flex;
  flex-direction: column;
`;
