import styled from 'styled-components';

export const Button = styled.button<{ hideOnPrint?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 12px;
  gap: 10px;
  min-width: 180px;
  height: 38px;
  border-radius: 4px;
  line-height: 130%;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
  border: transparent;
  @media (max-width: 768px) {
    padding: 0;
    min-width: auto;
    width: 100%;
    gap: 0px;
  }
  @media print {
    display: ${({ hideOnPrint }) => (hideOnPrint ? 'none' : 'block')};
  }
  :disabled,
  [disabled] {
    background-color: var(--cor-secundaria) !important;
    cursor: not-allowed;
  }
  transition: 0.35s;

  :hover {
    transition: 0.35s;
    background-color: var(--cor-secundaria);
  }
`;
export const PrimaryButton = styled(Button)`
  background: var(--cor-primaria);
  color: #ffffff;
  border: transparent;
  @media (max-width: 768px) {
    gap: 10px;
  }
`;
export const SecondaryButton = styled(Button)`
  border: 1px solid #6c757d;
  color: #495057;
  :hover {
    color: #ffffff;
  }
`;
