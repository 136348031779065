import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useState } from 'react';

//styles
import { Form } from 'react-bootstrap';
import { CheckCircle } from 'react-bootstrap-icons';
import Button from '../../components/Button';
import { useUserContext } from '../../context/UserContext';
import { getAutomaticDebitData, getBanks, saveAccountInfo } from '../../services/automaticDebit';
import { AlertContent } from '../BilletSend/styles';
import { ButtonGroup, FormGroup, PageTitleContainer, Title } from '../ChangeAddress/styles';

const AutomaticDebit = () => {
  const { userData, group, quota } = useUserContext();

  const [bank, setBank] = useState<string>('');
  const [account, setAccount] = useState<string>('');
  const [agency, setAgency] = useState<string>('');
  const [digit, setDigit] = useState<string>('');
  const [check, setCheck] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>();
  const [banks, setBanks] = useState<any>([]);

  useEffect(() => {
    if (!userData || !group || !quota) return;

    const func = async () => {
      try {
        const res = await getAutomaticDebitData(group + '', quota);
        const res2 = await getBanks();
        // console.log();
        setBanks(res2.bancos.filter((banco: { id: any }) => res2.bancosDebito.includes(banco.id)));
        if (!res) return;
        setAccount(res.debitoConta);
        setBank(res.debitoBanco);
        setDigit(res.debitoDigito);
        setAgency(res.debitoAgencia);
        setCheck(!!res && res.debitoEmConta);
      } catch (e) {
        console.log(e);
      }
    };
    func();
  }, [userData, group, quota]);

  const handleSave = async () => {
    try {
      const res = await saveAccountInfo(bank, agency, digit, account, check);
      if (res) setShowAlert(true);
      setTimeout(() => setShowAlert(false), 5000);
    } catch (e) {
      console.log(e);
    }
  };

  const handleVerifyCheck = (check: boolean) => {
    if (!check) {
      setBank('');
      setAgency('');
      setAccount('');
    }
    setCheck(check);
  };

  const pageContent = () => {
    return (
      <>
        <PageTitleContainer>
          <div className={'d-flex align-center gap-3 mt-4 mt-lg-0'}>
            <Title>Débito Automático</Title>

            <Form.Check
              type="switch"
              id="custom-switch"
              checked={check}
              onChange={(e: any) => handleVerifyCheck(e.target.checked)}
            />
          </div>
        </PageTitleContainer>

        {showAlert && (
          <AlertContent>
            <CheckCircle size={22} />
            Alterações feita com sucesso!
          </AlertContent>
        )}

        <div className="row">
          <div className="col-lg-9">
            <div className="row">
              <div className="col-md-6">
                {/*  <Form.Control*/}
                {/*    maxLength={3}*/}
                {/*    type='text'*/}
                {/*    placeholder='Banco'*/}
                {/*    required*/}
                {/*    value={bank}*/}
                {/*    onChange={(e: any) => setBank(e.target.value)}*/}
                {/*  />*/}
                <FormGroup controlId="formBasicCEP">
                  <Form.Label>Banco</Form.Label>

                  <Form.Select
                    aria-label="Default select example"
                    value={bank}
                    id={'select'}
                    onChange={(e: any) => setBank(e.target.value)}
                    htmlSize={1}
                  >
                    <option value={''}>Selecione </option>
                    {banks.map((bank: any) => (
                      <option key={bank.id} value={bank.id}>
                        {bank.id + ' - ' + bank.name}
                      </option>
                    ))}
                  </Form.Select>
                </FormGroup>
              </div>
              <div className="col-7 col-md-4">
                <FormGroup controlId="formBasicCEP">
                  <Form.Label>Agência</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Agência"
                    required
                    value={agency}
                    onChange={(e: any) => setAgency(e.target.value)}
                  />
                </FormGroup>
              </div>
              <div className="col-5 col-md-2 d-flex align-items-end">
                <FormGroup controlId="formBasicCEP">
                  <Form.Control
                    type="text"
                    placeholder="Dígito"
                    required
                    value={digit}
                    onChange={(e: any) => setDigit(e.target.value)}
                  />
                </FormGroup>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-6">
                <FormGroup controlId="formBasicCEP">
                  <Form.Label>Conta</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Conta"
                    required
                    value={account}
                    onChange={(e: any) => setAccount(e.target.value)}
                  />
                </FormGroup>
              </div>
              <div className="col-md-6 col-lg-4">
                <FormGroup controlId="formBasicCEP">
                  <Form.Label>Utilização de cheque especial</Form.Label>
                  <div className="mt-1">
                    <Form.Check inline label="Sim" name="group1" type={'radio'} id={`inline-radio-1`} disabled />
                    <Form.Check inline label="Não" name="group1" type={'radio'} id={`inline-radio-2`} disabled />
                  </div>
                </FormGroup>
              </div>
            </div>
          </div>
        </div>

        <ButtonGroup>
          <Button className="reset" type="reset" onClick={() => window.location.reload()}>
            Restaurar
          </Button>
          <Button className="submit" onClick={() => handleSave()}>
            Salvar
          </Button>
        </ButtonGroup>
      </>
    );
  };

  return <>{userData && <div>{pageContent()}</div>}</>;
};

export default AutomaticDebit;
