import styled from 'styled-components';

interface ListItemProps {
  active: boolean;
  minimize: boolean;
}

export const SidenavContainer = styled.div`
  background: var(--cor-primaria);
  min-width: 244px;
  height: calc(100vh - 68px);
  overflow-y: auto;
  display: none;

  @media (min-width: 768px) {
    padding: 20px 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;
export const MenuContainer = styled.div`
  display: flex;
  justify-content: center;
`;
export const IconContainer = styled.div`
  svg {
    width: 18px;
    height: 18px;
  }
`;

export const SidenavMenuList = styled.ul`
  list-style: none;
  box-sizing: border-box;
  margin: 0;
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
export const SidenavMenuListItem = styled.li<ListItemProps>`
  color: white;
  font-size: 0.75rem;
  background: ${({ active }) => (active ? 'var(--cor-secundaria)' : 'transparent')};
  border-radius: 8px;
  cursor: pointer;
  //transition: 0.5s;
  width: 100%;
  padding: 12px;
  display: flex;
  gap: 10px;

  justify-content: ${({ minimize }) => (minimize ? 'center' : 'start')};
  align-items: center;
  height: ${({ minimize }) => (minimize ? '60px' : 'auto')};
  transition: all 0.2s linear;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const SidenavMenuListItemDesktop = styled.li<ListItemProps>`
  margin-top: 20px;
  color: #ced4da;
  font-size: 0.75rem;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SmallInfo = styled.small`
  display: block;
  color: #ced4da;
  font-size: 0.6875rem;
  font-weight: 500;
  padding: 12px;
  @media (min-width: 768px) {
    max-width: 200px;
    padding: 0;
  }
`;
