import { useEffect, useState } from 'react';
import { getStylization } from '../services/styles';
import { getFromSessionStorage, setInSessionStorage } from '../constants/functions';
import { AxiosError } from 'axios';

export interface Stylization {
  corPrimaria: string;
  corSecundaria: string;
  corTerciaria: string;
  fontePrincipal: string;
  fonteSecundaria: string;
  imagemFundo: string;
  logoPrincipal: string;
  logoSecundario: string;
  logoSidebar: string;
  subTitulo: string;
  textoRodape: string;
  textoRodapeNegrito: string;
  textoSidebar: string;
  tituloPrincipal: string;
}

const ERROR_MESSAGE =
  'Ocorreu um erro ao tentar se conectar ao servidor. Verifique sua conexão com a internet e tente novamente.';

export const useStylization = () => {
  const [stylization, setStylization] = useState<Stylization>();

  const [error, setError] = useState<string>('');

  useEffect(() => {
    const getStyle = async () => {
      try {
        const res = (await getStylization()) as unknown as Stylization;

        if (typeof res === 'string') {
          setError(ERROR_MESSAGE);
        }

        setInSessionStorage('stylization', JSON.stringify(res));
        setStylization(res);
      } catch (e) {
        const error = e as AxiosError;

        if (error.code === 'ERR_NETWORK') {
          setError(ERROR_MESSAGE);
        }

        console.log(e);
      }
    };
    if (getFromSessionStorage('stylization')) {
      const style = JSON.parse(getFromSessionStorage('stylization') as string);
      setStylization(style);
    }
    getStyle();
  }, []);

  return { stylization, error };
};
