import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { CheckCircle, PencilSquare } from 'react-bootstrap-icons';
import Button from '../../components/Button';

//styles
import { useUserContext } from '../../context/UserContext';
import { getBilletInfo, saveBilletInfo } from '../../services/billetSend';
import {
  AlertContent,
  BoxBody,
  BoxContent,
  BoxHead,
  ButtonGroup,
  EmailInput,
  GroupOrCota,
  PageTitleContainer,
  SelectAll,
  Title,
} from './styles';
import { useGetWindowSize } from '../../hooks/useGetWindowSize';

const BilletSend = () => {
  const { loginResponseData, userData, group, quota } = useUserContext();
  const [options, setOptions] = useState<{ grupo: string; quota: string; digito: string; selected: string }[]>([]);
  const [email, setEmail] = useState<string>();
  const [showAlert, setShowAlert] = useState<boolean>();
  const { isMobile } = useGetWindowSize();

  useEffect(() => {
    if (!userData) {
      return;
    }
    setEmail(userData?.consorciadoEmail || userData?.txt_e_mail_1);
    setOptions(
      loginResponseData?.consorciados.map((consorciado) => {
        return {
          data: `${consorciado.grupo} / ${consorciado.cota}`,
          checkedEmail: false,
          checkedMaterial: false,
        };
      }) as []
    );
    const checkForData = async () => {
      if (!group || !quota) return;

      const res2 = await getBilletInfo(group + '', quota);
      setOptions(
        res2.quotas.map((_quota: { grupo: string; quota: string; digito: string; selected: string }) => {
          const { grupo, quota, digito, selected } = _quota;
          return {
            grupo,
            quota,
            digito,
            selected,
          };
        })
      );
    };
    checkForData();
  }, [group, quota, userData, loginResponseData]);

  const handleSendBillet = async () => {
    try {
      await saveBilletInfo(email + '', group + '', quota, options);
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), 5000);
    } catch (e) {
      console.log(e);
    }
  };

  const handleChange = (e: any, index: number) => {
    const _options = [...options];
    _options[index].selected = e;
    setOptions(_options);
    console.log(_options);
  };
  const pageContent = () => {
    return (
      <>
        <PageTitleContainer>
          <Title>Envio de Boleto</Title>
        </PageTitleContainer>

        {showAlert && (
          <AlertContent>
            <CheckCircle size={22} />
            Alterações feita com sucesso!
          </AlertContent>
        )}

        <Form>
          <div className="row">
            <div className="col-sm-6">
              <Form.Group className="m-0">
                <Form.Label>CPF/CNPJ</Form.Label>
                <Form.Control
                  type="text"
                  id="document"
                  placeholder="CPF/CNPJ"
                  value={loginResponseData?.consorciadoCpfCnpj}
                  disabled
                />
              </Form.Group>
            </div>

            <div className="mt-3 mt-sm-0 col-sm-6">
              <Form.Group className="m-0">
                <Form.Label>Email</Form.Label>
                <EmailInput>
                  <Form.Control
                    type="email"
                    id="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e: any) => setEmail(e.target.value)}
                  />
                  <span>
                    <PencilSquare color="#ADB5BD" />
                  </span>
                </EmailInput>
              </Form.Group>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-xl-9 col-xxl-7 mx-auto">
              <BoxContent>
                <BoxHead>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="col-sm-4 px-1">
                      <h4>Grupo/Cota</h4>
                    </div>
                    <div className="col-sm-4 text-center px-1">
                      <h4>Boleto via e-mail</h4>
                    </div>
                    <div className="col-sm-4 text-center px-1">
                      <h4>Boleto via correios</h4>
                    </div>
                  </div>
                </BoxHead>

                <BoxBody>
                  {!isMobile && (
                    <div className="row mb-2">
                      <div className="col-sm-4"></div>
                      <div className="col-sm-4 text-center">
                        <SelectAll
                          type="button"
                          onClick={() => {
                            setOptions(
                              options.map((item) => {
                                return {
                                  ...item,
                                  selected: 'S',
                                };
                              })
                            );
                          }}
                        >
                          Selecionar tudo
                        </SelectAll>
                      </div>

                      <div className="col-sm-4 text-center">
                        <SelectAll
                          type="button"
                          onClick={() =>
                            setOptions(
                              options.map((item) => {
                                return {
                                  ...item,
                                  selected: 'N',
                                };
                              })
                            )
                          }
                        >
                          Selecionar tudo
                        </SelectAll>
                      </div>
                    </div>
                  )}

                  <Form>
                    {options?.map((item: any, index) => (
                      <div className="row mb-3" key={item.quota}>
                        <div className="col-4">
                          <GroupOrCota>
                            {item.grupo}/{item.quota}
                          </GroupOrCota>
                        </div>

                        <div className="col-4 text-center d-flex flex-column">
                          <Form.Check
                            inline
                            name={'index' + index}
                            type={'radio'}
                            id={'index' + index}
                            value={'S'}
                            onChange={(e: any) => handleChange(e.target.value, index)}
                            defaultChecked={item.selected === 'S'}
                            checked={item.selected === 'S'}
                          />
                        </div>

                        <div className="col-4 text-center">
                          <Form.Check
                            inline
                            name={'index' + index}
                            type={'radio'}
                            id={'index' + index}
                            value={'N'}
                            onChange={(e: any) => handleChange(e.target.value, index)}
                            defaultChecked={item.selected === 'N'}
                            checked={item.selected === 'N'}
                          />
                        </div>
                      </div>
                    ))}
                  </Form>
                </BoxBody>
              </BoxContent>
            </div>
          </div>

          <ButtonGroup>
            <Button className="reset" onClick={() => window.location.reload()}>
              Restaurar
            </Button>
            <Button className="submit" onClick={() => handleSendBillet()}>
              Salvar
            </Button>
          </ButtonGroup>
        </Form>
      </>
    );
  };

  return <>{loginResponseData && <div> {pageContent()}</div>}</>;
};
export default BilletSend;
