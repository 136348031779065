import { Col } from 'react-bootstrap';
import styled from 'styled-components';

const DocumentAlertColors = {
  Aprovado: {
    background: '#e4ecef',
    textColor: 'blue',
  },
  Reprovado: {
    background: '#e4ecef',
    textColor: 'red',
  },
  Pendente: {
    background: '#fff6ab',
    textColor: 'red',
  },
};

export const DocumentBox = styled(Col)`
  min-width: 256px;
`;

export const DocumentItem = styled.div`
  display: flex;
  flex-direction: column;
  border: 2px solid #e1e6ea;
  border-radius: 0.25rem;
  justify-content: center;
  align-items: center;
  padding: 0.25rem;
  gap: 0.25rem;
`;

export const DocumentPreview = styled.img`
  width: 90%;
  aspect-ratio: 1.5;
  /* height: 150px; */
  object-fit: cover;
`;

export const DocumentContentContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  width: 100%;
  gap: 0.5rem;
  font-size: 0.75rem;
`;

export const DocumentOrder = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: center;
  padding: 0.25rem;
  font-size: 0.85rem;
  background-color: #e4ecef;
`;

export const DocumentInfo = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 0.125rem;
`;

export const DocumentName = styled.div`
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const DocumentAlert = styled.div<{ status: 'Pendente' | 'Aprovado' | 'Reprovado' }>`
  background-color: ${({ status }) => DocumentAlertColors[status].background};
  border: 1.5px dotted ${({ status }) => DocumentAlertColors[status].textColor};
  display: flex;
  /* padding: 0 0.25rem; */
  font-size: 0.8rem;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  color: ${({ status }) => DocumentAlertColors[status].textColor};
`;

export const DocumentText = styled.p``;

export const DocumentCreatedInfo = styled.div`
  background-color: #e4ecef;
  padding: 0.25rem;
  gap: 0.125rem;
  font-weight: bold;
`;

export const DocumentCreatedInfoUser = styled.div`
  color: blue;
`;

export const DocumentCreatedInfoDate = styled.div``;

export const DocumentLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: 2px 0;
  color: #212529b3;
  width: 100%;
`;
