import { Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

// style
import styled from 'styled-components';

export const Content = styled.div<{ loginbackground: string }>`
  @media (max-width: 768px) {
    background: #fff;
  }
  background: url(${({ loginbackground }) => loginbackground}) no-repeat #e9ecef;
  background-size: cover;
  background-position: center;

  min-height: 100vh;
  padding: 16px;
  max-width: 100vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    color: #495057;
  }

  strong {
    color: #141414;
  }
`;

export const Head = styled.div`
  margin-bottom: 24px;

  h1 {
    margin-bottom: 8px;
    color: #343a40;
    font-weight: 600;
    font-size: 1.5rem;
  }

  p {
    color: #6c757d;
    font-size: 0.875rem;
    font-weight: 500;
  }
`;

export const Box = styled.div`
  width: 486px;
  min-height: 629px;

  padding: 35px 50px;
  background: #fff;
  border-radius: 8px;

  @media (max-width: 500px) {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  figure {
    margin-bottom: 24px;
  }

  .infoFooter {
    margin-top: 24px;

    p,
    strong {
      font-size: 0.75rem;
    }
  }

  input {
    height: 39px;
    margin: 8px 0;
  }

  .logoSecondary {
    max-width: 224px;
    overflow: hidden;
  }
`;

export const Submit = styled(Button)`
  display: grid;
  width: 100%;

  background: var(--cor-primaria) !important;
  transition: 0.35s;
  border: none;
  color: #fff;
  font-weight: 700;
  font-size: 1rem;
  padding: 10px;
  margin: 24px 0 16px;

  &:hover {
    opacity: 0.75;
    transition: 0.35s;
  }
`;

export const AlertDanger = styled.p`
  color: #dc3545 !important;
  font-size: 0.875rem;
`;

export const NavigatePassword = styled(NavLink)`
  color: #6c757d !important;
  transition: 0.35s;

  &:hover {
    opacity: 0.75;
    transition: 0.35s;
  }
`;
