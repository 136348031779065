import React from 'react';

import Login from '../pages/Login/';

const loginRoutePage = {
  component: <Login />,
  public: true,
  route: '/login',
};

export { loginRoutePage };
