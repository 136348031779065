import styled from 'styled-components';

export const Container = styled.main`
  height: 100%;
`;

export const Title = styled.h1`
  margin: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  line-height: 39px;
  color: #343a40;
  @media (max-width: 768px) {
    font-size: 1.25rem;
  }
`;
