/* eslint-disable @typescript-eslint/no-unused-vars */
import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';

//styles
import { InfoCircle, Printer } from 'react-bootstrap-icons';
import Button from '../../components/Button';
import { ExtractTable } from '../../components/ExtractTable';
import { PrintingVersion } from '../../components/PrintingVersion';
import { useUserContext } from '../../context/UserContext';
import { BoxCard, InfoTitle, PageTitleContainer, Title } from './styles';
import {
  ConsolidatedPositionProp,
  getConsolidatedPosition,
  naoContempladasProp,
} from '../../services/consolidatedPosition';
import { calcTotalByCollum, formatMoney } from '../../constants/functions';
import { useNavigate } from 'react-router-dom';

const ConsolidatedPosition = () => {
  const currentYear = new Date().getFullYear();

  const ref = useRef<HTMLDivElement>(null);
  const [isPrinting, setIsPrinting] = useState<boolean>(false);
  const { group, quota, userData, loginResponseData, setQuota, setGroup } = useUserContext();
  const [consolidatedPosition, setConsolidatedPosition] = useState<ConsolidatedPositionProp>();
  const [differentDueDates, setDifferentDueDates] = useState<naoContempladasProp[][]>([]);
  const [filter, setFilter] = useState('N');
  const navigate = useNavigate();
  const handlePrint = useReactToPrint({
    content: () => ref?.current,
    onAfterPrint: () => setIsPrinting(false),
  });

  useEffect(() => {
    if (!loginResponseData) return;

    const func = async () => {
      try {
        const info = await getConsolidatedPosition();
        const groupBy = (arr: any[], property: string | number) => {
          return arr.reduce((acc, cur) => {
            acc[cur[property]] = [...(acc[cur[property]] || []), cur];
            return acc;
          }, {});
        };
        setDifferentDueDates(groupBy(info!.naoContempladas, 'data'));
        // setDifferentDueDates()
        setConsolidatedPosition(info as ConsolidatedPositionProp);
      } catch (e) {
        console.log(e);
      }
    };
    func();
  }, [loginResponseData]);

  const pageContent = () => {
    return (
      <>
        <PageTitleContainer>
          <div>
            <Title>Posição Consolidada </Title>
            <InfoTitle>
              <InfoCircle size={22} />
              <span>Clique em uma cota para emitir o boleto</span>
            </InfoTitle>
          </div>

          <Button
            onClick={() => {
              handlePrint();
            }}
            hideOnPrint={true}
          >
            <Printer /> Imprimir
          </Button>
        </PageTitleContainer>
        {Object.keys(differentDueDates)?.map((dueDate: any) => (
          <>
            <BoxCard>
              <ExtractTable
                title={'Cota(s) não contemplada(s) com vencimento em ' + differentDueDates[dueDate][0].data}
                rowsGroup={[
                  'Grupo/Cota',
                  'Valor crédito',
                  'Valor parcela',
                  'Saldo devedor',
                  '% Devedor',
                  'Total pago',
                  '% Pago',
                ]}
                data={[
                  ...differentDueDates[dueDate].map((naoContemplada) => {
                    const {
                      cota,
                      credito,
                      data,
                      grupo,
                      percentualDevedor,
                      percentualPago,
                      saldoDevedor,
                      saldoPago,
                      valorParcela,
                    } = naoContemplada;

                    return [
                      grupo + '/' + cota,
                      formatMoney(credito),
                      formatMoney(valorParcela),
                      formatMoney(saldoDevedor),
                      percentualDevedor,
                      formatMoney(saldoPago),
                      percentualPago,
                    ];
                  }),
                ]}
                totalValues={
                  differentDueDates[dueDate].length > 1
                    ? [
                        'Total: ' + differentDueDates[dueDate].length,
                        formatMoney(calcTotalByCollum(differentDueDates[dueDate], 'credito')),
                        formatMoney(calcTotalByCollum(differentDueDates[dueDate], 'valorParcela')),
                        formatMoney(calcTotalByCollum(differentDueDates[dueDate], 'saldoDevedor')),
                        '',
                        formatMoney(calcTotalByCollum(differentDueDates[dueDate], 'saldoPago')),
                        '',
                      ]
                    : []
                }
                withLink={(e) => {
                  setQuota(differentDueDates[dueDate][e].cota);
                  setGroup(differentDueDates[dueDate][e].grupo);
                  setTimeout(() => navigate('/emissao-de-boleto'), 100);
                }}
              />
            </BoxCard>
          </>
        ))}

        <BoxCard>
          <ExtractTable
            title="Cotas"
            rowsGroup={['Cotas', 'Valor crédito', 'Valor parcela', 'Saldo devedor', 'Total pago']}
            totalValues={[
              'Geral: ' + consolidatedPosition?.total.totalCotas,
              formatMoney(consolidatedPosition?.total.credito),
              formatMoney(consolidatedPosition?.total.valorParcela),
              formatMoney(consolidatedPosition?.total.saldoDevedor),
              formatMoney(consolidatedPosition?.total.saldoPago),
            ]}
          />
        </BoxCard>
      </>
    );
  };

  return (
    <>
      {consolidatedPosition && (
        <>
          <div ref={ref}>
            <PrintingVersion>{pageContent()}</PrintingVersion>
          </div>

          <div>{pageContent()}</div>
        </>
      )}
    </>
  );
};

export default ConsolidatedPosition;
