import { Alert } from 'react-bootstrap';

import { useUserContext } from '../../context/UserContext';
import { useAxios } from '../../services/swr';
import { Container, Title } from './styles';

interface ContractData {
  urlContrato: string;
}

export function Contract() {
  const { group, quota, digit } = useUserContext();

  const { data, error } = useAxios<ContractData>(['consorciado/contrato', { grupo: group, quota, digito: digit }]);

  return (
    <Container>
      <Title>Impressão de Contrato</Title>
      {error && (
        <div className="mt-3">
          <Alert key={'primary'} variant={'primary'}>
            {error.response?.data || 'Não foi possível gerar o contrato.'}
          </Alert>
        </div>
      )}

      {!!data && <embed src={data.urlContrato} className="mt-4" width="100%" height="93%" />}
    </Container>
  );
}
