import React, { createContext, Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from 'react';
import { getUserInfo, handleLogin, requestPassword } from '../services/login';
import { getFromSessionStorage, setInSessionStorage } from '../constants/functions';

export interface User {
  statusRetorno: string;
  consorciadoGruposCotasSegmentos: string;
  consorciadoCotaDigito: string;
  usuarioNome: string;
  consorciadoUltimoAcesso: string;
  consorciadoTextoBoasVindasNetAtendLinha1: string;
  consorciadoTextoBoasVindasNetAtendLinha2: string;
  consorciadoTelefoneAtendimento: string;
  consorciadoTemEmailInformado: string;
  consorciadoCpfCnpj: string;
  consorciadoAcesso: string;
  consorciadoSegmento: string;
  consorciadoEmail: string;
  consorciadoPoderesMaster: string;
  consorciadoGruposCotasNaoContempladas: string;
  consorciadoGruposCotasContempladas: string;
  consorciadoLkEmpresa: string;
  consorciadoValorRenda: string;
  usuarioLogGravaEndereco: string;
  consorciadoAssembleias: string;
  usuarioCodigo: string;
  admPrimeiraEmpresaCod: string;
  usuarioApelido: string;
  consorciados: (
    | { contemplada: string; cota: string; grupo: string; nome: string; cpfCnpj: string; digito: string; email: string }
    | { contemplada: string; cota: string; grupo: string; nome: string; cpfCnpj: string; digito: string; email: string }
    | { contemplada: string; cota: string; grupo: string; nome: string; cpfCnpj: string; digito: string; email: string }
    | { contemplada: string; cota: string; grupo: string; nome: string; cpfCnpj: string; digito: string; email: string }
    | { contemplada: string; cota: string; grupo: string; nome: string; cpfCnpj: string; digito: string; email: string }
    | { contemplada: string; cota: string; grupo: string; nome: string; cpfCnpj: string; digito: string; email: string }
  )[];
  token: string;
  txt_pessoa: string;
  permite_alterar: string;
  sexo: string;
  sequenciaRes: string;
  sequenciaCom: string;
  sequenciaCob: string;
  txt_rg: string;
  txt_dt_emissao_rg: string;
  txt_dt_nasc_res: string;
  txt_e_mail_1: string;
  txt_seq_res: string;
  txt_codcep_res: string;
  txt_nomrua_res: string;
  txt_numero_res: string;
  txt_comple_res: string;
  txt_bairro_res: string;
  txt_cidade_res: string;
  txt_estado_res: string;
  txt_codddd_res: string;
  txt_telefo_res: string;
  txt_ramal_res: string;
  txt_nrfone_pager: string;
  txt_codigo_pager: string;
  txt_dddtel_celular: string;
  txt_nrfone_celular: string;
  txt_nrfone_fax_res: string;
  txt_seq_com: string;
  txt_codcep_com: string;
  txt_nomrua_com: string;
  txt_numero_com: string;
  txt_comple_com: string;
  txt_bairro_com: string;
  txt_cidade_com: string;
  txt_estado_com: string;
  txt_codddd_com: string;
  txt_telefo_com: string;
  txt_ramal_com: string;
  txt_nrfone_fax_com: string;
  txt_cargo: string;
  txt_vlrenda: string;
  txt_dtadmi_com: string;
  txt_seq_cob: string;
  txt_codcep_cob: string;
  txt_nomrua_cob: string;
  txt_numero_cob: string;
  txt_comple_cob: string;
  txt_bairro_cob: string;
  txt_cidade_cob: string;
  txt_estado_cob: string;
  txt_estado_civil: string;
  txt_banco: string;
  txt_agencia: string;
  txt_agencia_digito: string;
  txt_conta: string;
  txt_digito: string;
  txt_informa_paises: string;
  txt_pais_endereco: string;
  txt_pais_resid_fiscal: string;
  txt_pais_nacionalidade: string;
  txt_tpdeclarado: string;
  txt_pais_1: string;
  txt_NIF_1: string;
  txt_pais_2: string;
  txt_NIF_2: string;
  txt_pais_3: string;
  txt_NIF_3: string;
  txt_pessoa1: string;
  txt_cpf_cnpj_socio1: string;
  txt_nome_socio1: string;
  txt_perc_part1: string;
  txt_informa_paises1: string;
  txt_pais_endereco1: string;
  txt_pais_resid_fiscal1: string;
  txt_pais_nacionalidade1: string;
  txt_tpdeclarado1: string;
  txt_pais1_1: string;
  txt_NIF1_1: string;
  txt_pais1_2: string;
  txt_NIF1_2: string;
  txt_pais1_3: string;
  txt_NIF1_3: string;
  txt_pessoa2: string;
  txt_cpf_cnpj_socio2: string;
  txt_nome_socio2: string;
  txt_perc_part2: string;
  txt_informa_paises2: string;
  txt_pais_endereco2: string;
  txt_pais_resid_fiscal2: string;
  txt_pais_nacionalidade2: string;
  txt_tpdeclarado2: string;
  txt_pais2_1: string;
  txt_NIF2_1: string;
  txt_pais2_2: string;
  txt_NIF2_2: string;
  txt_pais2_3: string;
  txt_NIF2_3: string;
  txt_naturalidade: string;
  txt_naturalidade_uf: string;
  txt_vlr_patrimonio: string;
  txt_capital_social: string;
  txt_cod_cbo_cnae: string;
  txt_apl_cbo_cnae: string;
  txt_cod_natureza_juridica: string;
  txt_apl_natureza_juridica: string;
  txt_rg_ie_uf: string;
  txt_nacionalidade: string;
  txt_filiacao_nome_mae: string;
  txt_emprego: string;
  txt_identifica_PEP: string;
  txt_orgaoEmissor: string;
  txt_camposPessoa: (
    | { dataFundacao: { cpoObrig: string; cpoAlert: string; cpoCodigo: string; cpoDescr: string; cpoVlrMin: string } }
    | { atividade: { cpoObrig: string; cpoAlert: string; cpoCodigo: string; cpoDescr: string; cpoVlrMin: string } }
    | { endereco: { cpoObrig: string; cpoAlert: string; cpoCodigo: string; cpoDescr: string; cpoVlrMin: string } }
    | { telefone: { cpoObrig: string; cpoAlert: string; cpoCodigo: string; cpoDescr: string; cpoVlrMin: string } }
    | { email: { cpoObrig: string; cpoAlert: string; cpoCodigo: string; cpoDescr: string; cpoVlrMin: string } }
    | { faturamento: { cpoObrig: string; cpoAlert: string; cpoCodigo: string; cpoDescr: string; cpoVlrMin: string } }
    | { patrimonio: { cpoObrig: string; cpoAlert: string; cpoCodigo: string; cpoDescr: string; cpoVlrMin: string } }
    | {
        inscricaoEstadual: {
          cpoObrig: string;
          cpoAlert: string;
          cpoCodigo: string;
          cpoDescr: string;
          cpoVlrMin: string;
        };
      }
    | { complementoIE: { cpoObrig: string; cpoAlert: string; cpoCodigo: string; cpoDescr: string; cpoVlrMin: string } }
    | { ufIE: { cpoObrig: string; cpoAlert: string; cpoCodigo: string; cpoDescr: string; cpoVlrMin: string } }
    | { emissaoIE: { cpoObrig: string; cpoAlert: string; cpoCodigo: string; cpoDescr: string; cpoVlrMin: string } }
    | {
        naturezaJuridica: {
          cpoObrig: string;
          cpoAlert: string;
          cpoCodigo: string;
          cpoDescr: string;
          cpoVlrMin: string;
        };
      }
    | { capitalSocial: { cpoObrig: string; cpoAlert: string; cpoCodigo: string; cpoDescr: string; cpoVlrMin: string } }
    | { socio: { cpoObrig: string; cpoAlert: string; cpoCodigo: string; cpoDescr: string; cpoVlrMin: string } }
    | {
        reciboParcelaInicial: {
          cpoObrig: string;
          cpoAlert: string;
          cpoCodigo: string;
          cpoDescr: string;
          cpoVlrMin: string;
        };
      }
  )[];
  nome: string;
  phones: {
    '11': string;
    '12': string;
    segundaParte: string;
    '13': string;
    tipoTelefone: string;
    '14': string;
    '15': string;
    numero: string;
    primeiraParte: string;
    ddd: string;
    telefoneCompleto: string;
    registro: string;
    '0': string;
    principal: string;
    '3': string;
    '4': string;
    '10': string;
  }[];
  addresses: {
    zipCode: string;
    '15': string;
    city: string;
    '17': string;
    '18': string;
    '19': string;
    active: string;
    firstZipCode: string;
    main: string;
    '0': string;
    '1': string;
    number: string;
    '2': string;
    '3': string;
    Observação: string;
    street: string;
    checked: boolean;
    secondZipCode: string;
    neighborhood: string;
    state: string;
    stateDescription: string;
    id: string;
    complement: string;
  }[];
  emails: {
    '0': string;
    '11': string;
    principal: string;
    '1': string;
    tipo: string;
    observacao: string;
    '4': string;
    endereco: string;
    '6': string;
    '9': string;
    '10': string;
    status: string;
  }[];
}

export interface ILoginResponseData {
  '19': string;
  '21': string;
  '22': string;
  '23': string;
  '24': string;
  statusRetorno: string;
  consorciadoGruposCotasSegmentos: string;
  consorciadoCotaDigito: string;
  usuarioNome: string;
  consorciadoUltimoAcesso: string;
  consorciadoTextoBoasVindasNetAtendLinha1: string;
  consorciadoTextoBoasVindasNetAtendLinha2: string;
  consorciadoTelefoneAtendimento: string;
  consorciadoTemEmailInformado: string;
  consorciadoCpfCnpj: string;
  consorciadoAcesso: string;
  consorciadoSegmento: string;
  consorciadoEmail: string;
  consorciadoPoderesMaster: string;
  consorciadoGruposCotasNaoContempladas: string;
  consorciadoGruposCotasContempladas: string;
  consorciadoLkEmpresa: string;
  consorciadoValorRenda: string;
  usuarioLogGravaEndereco: string;
  consorciadoAssembleias: string;
  usuarioCodigo: string;
  admPrimeiraEmpresaCod: string;
  usuarioApelido: string;
  consorciados: {
    contemplada: string;
    cota: string;
    grupo: string;
    nome: string;
    cpfCnpj: string;
    digito: string;
    email: string;
  }[];
  token: string;
}

export interface ILoginFormData {
  group?: string;
  password?: string;
  quota?: string;
  digit?: string;
  document?: string;
  email?: string;
}

interface UserContextData {
  userData: User | undefined;
  setUserData: Dispatch<SetStateAction<User | undefined>> | any;
  updateAppState: (s: string) => void;
  appStateUpdatedCtx: string;
  loginResponseData: ILoginResponseData | undefined;
  setConsorciadoData: any;
  group: string;
  setGroup: any;
  makeLogin: any;
  makeLogout: any;
  setQuota: any;
  quota: any;
  token: any;
  digit: string;
  getUserData: any;
  setDigit: any;
  forgotPassword: any;
}

const initialContext: UserContextData = {
  userData: undefined,
  token: '',
  setUserData: () => null,
  updateAppState: () => null,
  digit: '',
  setDigit: () => null,
  appStateUpdatedCtx: '',
  loginResponseData: undefined,
  setConsorciadoData: () => null,
  group: '',
  setGroup: () => null,
  makeLogin: () => null,
  makeLogout: () => null,
  forgotPassword: () => null,
  getUserData: () => null,
  setQuota: () => null,
  quota: '',
};

export const UserContext = createContext<UserContextData>(initialContext);

export const UserProvider = ({ children }: any) => {
  const [userData, setUserData] = useState<User>();
  const [loginResponseData, setLoginResponseData] = useState<ILoginResponseData>();
  const [group, setGroup] = useState<string>('');
  const [quota, setQuota] = useState<string>('');
  const [digit, setDigit] = useState<string>('');

  const [token, setToken] = useState<string>();
  const [appStateUpdatedCtx, setAppStateUpdatedCtx] = useState('');

  const makeLogout = () => {
    sessionStorage.clear();
    return window.location.reload();
  };

  const forgotPassword = async (userData: ILoginFormData) => {
    try {
      const res = await requestPassword(userData);
      if ((res as any)['response']) {
        return {
          message: (res as any)['response'].data['1'],
          error: true,
        };
      }
      return res;
    } catch (e) {
      console.log(e);
      return e;
    }
  };
  //FUNCTION TO TRIGGER AN UI UPDATE FOR OTHER
  //STATE THAT ARE NOT RELATED TO THE USER INFO
  //stateContextName Basically you just set anything to update the state
  const updateAppState = (stateContextName: string) => {
    setAppStateUpdatedCtx(`${stateContextName}+${Math.random() * 1000}`);
  };

  const getUserData = useCallback(async () => {
    try {
      const response = (await getUserInfo()) as any;
      if (response.statusCode === 401) {
        makeLogout();
        return;
      }

      setUserData(response);
    } catch (error: any) {
      makeLogout();
    }
  }, []);

  const handleGetUserInfo = useCallback(async () => {
    if (getFromSessionStorage('token') && getFromSessionStorage('cpfCnpj')) {
      setToken(getFromSessionStorage('token') + '');
      if (getFromSessionStorage('group') && getFromSessionStorage('group') !== group) {
        setGroup(getFromSessionStorage('group') as string);
      }
      if (getFromSessionStorage('digit') && getFromSessionStorage('digit') !== digit) {
        setDigit(getFromSessionStorage('digit') as string);
      }
      if (getFromSessionStorage('quota') && getFromSessionStorage('quota') !== quota) {
        setQuota(getFromSessionStorage('quota') as string);
      }
      if (!loginResponseData && getFromSessionStorage('loginData')) {
        setLoginResponseData(JSON.parse(getFromSessionStorage('loginData') + '') as ILoginResponseData);
      }

      getUserData();
    }
  }, [digit, group, loginResponseData, quota, getUserData]);

  useEffect(() => {
    if (!token) return;

    getUserData();
    if (!quota || !group) return;
    if (quota !== getFromSessionStorage('quota')) setInSessionStorage('quota', quota);
    if (group !== getFromSessionStorage('group')) setInSessionStorage('group', group);
  }, [group, quota, getUserData, token]);

  const makeLogin = async (userData: ILoginFormData) => {
    try {
      let res = (await handleLogin(userData)) as ILoginResponseData;
      if ((res as any)['response']) {
        return {
          message: (res as any)['response'].data.consorciadoGruposCotasSegmentos,
          error: true,
        };
      }
      setLoginResponseData(res);
      // setInSessionStorage('cpfCnpj', res.consorciadoCpfCnpj);
      setInSessionStorage('token', res.token);
      setInSessionStorage('loginData', JSON.stringify(res));
      // setInSessionStorage('quota', userData.quota + '');
      // console.log(userData);
      setToken(res.token);
      return res;
    } catch (e) {
      return e;
    }
  };

  useEffect(() => {
    handleGetUserInfo();
  }, [token, handleGetUserInfo]);
  return (
    <UserContext.Provider
      value={{
        userData,
        setUserData,
        appStateUpdatedCtx,
        updateAppState,
        loginResponseData: loginResponseData,
        setConsorciadoData: setLoginResponseData,
        group,
        quota,
        digit,
        setDigit,
        setQuota,
        setGroup,
        makeLogin,
        makeLogout,
        token,
        getUserData,
        forgotPassword,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  const context = useContext(UserContext);
  return context;
};
