import { HTMLAttributes } from 'react';
import Header from '../Header';
import Sidenav from '../Sidenav';
import { Page, PageLayoutChildrenContainer, PageLayoutContainer } from './styles';

type IPageContainerProps = HTMLAttributes<HTMLDivElement>;

const PageContainer = ({ children }: IPageContainerProps) => {
  // const [minimizeSidenav, setMinimizeSidenav] = useState<boolean>(true);
  return (
    <PageLayoutContainer>
      <Header />

      <PageLayoutChildrenContainer>
        <Sidenav />

        <Page>{children}</Page>
      </PageLayoutChildrenContainer>
    </PageLayoutContainer>
  );
};

export default PageContainer;
