import { useMemo, useState } from 'react';
import { Alert, Col, Form, Row, Spinner } from 'react-bootstrap';

import { Container, Title } from './styles';

import { useUserContext } from '../../context/UserContext';
import { useAxios } from '../../services/swr';
import Button from '../../components/Button';
import { useForm } from 'react-hook-form';
import { PageTitleContainer } from '../home/styles';
import { getFromSessionStorage, splitLastOccurrence } from '../../constants/functions';
import api from '../../services/api';
import { DocumentFile } from '../../components/DocumentFile';

interface DocumentFormData {
  destiny: 'quota' | 'cpfCnpj';
  type: string;
  files: FileList;
}

export interface DocumentInfo {
  nomeExternoDocumento: string;
  descricaoStatusDocumento: 'Pendente' | 'Reprovado' | 'Aprovado';
  processo: string;
  documento: string;
  duplicidade: string;
  cpfCnpjConsorciado: string;
  grupoConsorciado: string;
  cotaConsorciado: string;
  digitoConsorciado: string;
  processo296: string;
  documento296: string;
  sequencia296: string;
  codigoUsuarioAdicionou: string;
  nomeUsuarioAdicionou: string;
  dataDocumentoAdicionado: string;
  horaDocumentoAdicionado: string;
  codigoUsuarioAnalisou: string;
  nomeUsuarioAnalisou: string;
  dataDocumentoAnalisado: string;
  horaDocumentoAnalisado: string;
  codigoStatusDocumento: string;
}

export interface PendingDocuments {
  grupo: string;
  quota: string;
  digitoCota: string;
  statusCota: string;
  consorciadoNome: string;
  cpfCnpjParseado: string;
  podeAprovar: boolean;
  tipoPessoa: string;
  cpfCnpj: string;
  documentosBaseUrl: string;
  processos: Array<any>;
  listaDocumentos: Array<{
    codigo: string;
    nomeDocumentoRepositorio: string;
    pessoa: string;
    checkAprova: string;
    tipoDocumento: string;
    descricaoDocumento: string;
    descricaoDetalhadaDocumento: string;
  }>;
  listaDocumentosConsorciado: DocumentInfo[];
  files: {
    cpfCnpjFiles: Array<{
      filename: string;
      path: string;
      url: string;
      documentInfo?: DocumentInfo;
    }>;
    quotaFiles: Array<{
      filename: string;
      path: string;
      url: string;
      documentInfo?: DocumentInfo;
    }>;
  };
}

export function Documents() {
  const [isFormOpen, setFormOpen] = useState(false);

  const { group, quota, digit } = useUserContext();

  const { register, handleSubmit, reset, formState } = useForm<DocumentFormData>();

  const { data, error, mutate } = useAxios<PendingDocuments>(
    ['consorciado/documentosPendentes', { grupo: group, quota, digitoCota: digit }],
    { revalidateOnFocus: false }
  );

  const documentTypes = useMemo(() => {
    if (!data) return [];

    return data.listaDocumentos.map((listaDocumento) => [
      listaDocumento.nomeDocumentoRepositorio,
      listaDocumento.descricaoDocumento,
    ]);
  }, [data]);

  async function onSubmit(documentFormData: DocumentFormData) {
    if (!data) return;

    let files = [];

    const filenamePrefix = `${documentFormData.type.trim()}_`;

    for (let i = 0; i < documentFormData.files.length; i++) {
      const file = documentFormData.files[i];

      let [name, extension] = splitLastOccurrence(file.name, '.');

      let filename = filenamePrefix + name.replaceAll('.', '') + `.${extension}`;

      if (documentFormData.destiny === 'quota') {
        filename = splitLastOccurrence(filename, '.').join('_true.');
      }

      // Adiciona _true no final do nome do arquivo para validação no backend
      files.push(new File([file], filename.toUpperCase(), { type: file.type }));
    }

    const formData = new FormData();

    const segmento = getFromSessionStorage('segmentoLogin');

    formData.append('cpfCnpj', data.cpfCnpj);
    formData.append('grupo', data.grupo);
    formData.append('quota', data.quota);
    formData.append('nome', data.consorciadoNome);
    formData.append('digitoCota', data.digitoCota);
    formData.append('documentsPicked', JSON.stringify([documentFormData.type]));
    formData.append('segmentoLogin', segmento);

    for (const file of files) {
      formData.append('images[]', file);
    }

    try {
      await api.patch('venda/images', formData, { headers: { token: getFromSessionStorage('token') } });

      const codDocumento = data.listaDocumentos.find(
        (i) => i.nomeDocumentoRepositorio === documentFormData.type
      )?.codigo;

      for (const file of files) {
        const requestBody = {
          grupo: group,
          quota,
          cpfCnpj: getFromSessionStorage('cpfCnpj'),
          codDocumento,
          destiny: documentFormData.destiny === 'quota' ? '0002' : '0001',
          digito: digit,
          nomeArquivo: file.name,
        };

        await api.post('consorciado/registarVerificar', requestBody, {
          headers: { token: getFromSessionStorage('token') },
        });
      }
    } finally {
      mutate();

      setFormOpen(false);

      reset();
    }
  }

  return (
    <Container>
      <PageTitleContainer>
        <Title>Documentos</Title>
      </PageTitleContainer>
      {error && (
        <div className="mt-3">
          <Alert key={'primary'} variant={'primary'}>
            {error.response?.data || 'Não foi possível gerar o contrato.'}
          </Alert>
        </div>
      )}

      <div style={{ padding: '0 24px' }}>
        {!isFormOpen ? (
          <Button onClick={() => setFormOpen(true)}>Incluir</Button>
        ) : (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="gy-3">
              <Form.Group as={Col} md="4" lg="4" xl="3">
                <Form.Label>Destino do Documento</Form.Label>
                <div>
                  <Form.Check type="radio" inline value="cpfCnpj" label="CPF/CNPJ" checked {...register('destiny')} />
                  <Form.Check type="radio" inline value="quota" label="Cota" {...register('destiny')} />
                </div>
              </Form.Group>
              <Form.Group as={Col} md="4" lg="4" xl="3">
                <Form.Label>Tipo de Documento</Form.Label>
                <Form.Select required {...register('type')}>
                  <option value="">Selecione um tipo</option>
                  {documentTypes.map(([value, label]) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="4" lg="4" xl="6">
                <Form.Label>Arquivo</Form.Label>
                <Form.Control
                  required
                  type="file"
                  accept="image/jpeg,image/png,application/pdf"
                  multiple
                  {...register('files')}
                />
              </Form.Group>
              <Col style={{ gap: '8px' }} className="d-flex align-items-end justify-content-end">
                <Button disabled={formState.isSubmitting} onClick={() => setFormOpen(false)}>
                  Retornar
                </Button>
                <Button disabled={formState.isSubmitting} type="submit">
                  {formState.isSubmitting ? <Spinner size="sm" /> : 'Salvar'}
                </Button>
              </Col>
            </Row>
          </Form>
        )}

        <div style={{ marginTop: 32 }}>
          <Title style={{ color: '#6C757D', fontSize: '1.25rem' }}>Documentos do CPF/CNPJ</Title>
          <Row className="gy-3">
            {data?.files.cpfCnpjFiles.map((file, index) => (
              <DocumentFile onApproveReprove={mutate} file={file} canApprove={data.podeAprovar} order={index + 1} />
            ))}
          </Row>
        </div>

        <div style={{ marginTop: 32, marginBottom: 16 }}>
          <Title style={{ color: '#6C757D', fontSize: '1.25rem' }}>Documentos da Cota</Title>
          <Row className="gy-3">
            {data?.files.quotaFiles.map((file, index) => (
              <DocumentFile onApproveReprove={mutate} file={file} canApprove={data.podeAprovar} order={index + 1} />
            ))}
          </Row>
        </div>
      </div>
    </Container>
  );
}
