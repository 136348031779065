import { Alert, Form, InputGroup } from 'react-bootstrap';

//styles
import { ButtonGroup, FormGroup, PageTitleContainer, Title, BoxCard } from './styles';

import BButton from 'react-bootstrap/Button';

import 'bootstrap/dist/css/bootstrap.min.css';
import Button from '../../components/Button';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useUserContext } from '../../context/UserContext';
import api from '../../services/api';
import { useMemo, useState } from 'react';
import { AxiosError } from 'axios';
import { useAxios } from '../../services/swr';
import { Regras, FormSchemaData, changeSchemaBasedOnRules, initSchema } from './schema';
import { Eye, EyeSlash } from 'react-bootstrap-icons';
import { ExtractTable } from '../../components/ExtractTable';

type RegrasResponse = {
  regras: Regras;
  textos: {
    conter: Array<string>;
    naoConter: Array<string>;
  };
};

export default function ChangePassword() {
  const [errorMessage, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [schema, setSchema] = useState(initSchema);

  const [isPasswordHiddenObject, setPasswordHidden] = useState({ oldPassword: true, newPassword: true });

  const { loginResponseData } = useUserContext();

  const { data } = useAxios<RegrasResponse>('consorciado/regrasPoliticaAcesso', { revalidateOnFocus: false });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormSchemaData>({
    resolver: zodResolver(schema),
  });

  const textTable = useMemo(() => {
    if (!data) return;

    const { conter, naoConter } = data.textos;

    const maxLength = Math.max(conter.length, naoConter.length); // Encontre o comprimento máximo dos arrays

    const result = [];

    for (let i = 0; i < maxLength; i++) {
      const elementA = conter[i] || ''; // Use string vazia se não houver elemento em a
      const elementB = naoConter[i] || ''; // Use string vazia se não houver elemento em b
      result.push([elementA, elementB]);
    }

    return result;
  }, [data]);

  async function handleSalvar(form: FormSchemaData) {
    setError('');
    setSuccessMessage('');

    try {
      const [grupo, quota] = form.quota.split('_');

      const res = await api.post('/consorciado/changePassword', {
        grupo,
        quota,
        oldPassword: form.password,
        newPassword: form.newPassword,
        todas: form.alterAllQuotas ? 'S' : 'N',
      });

      setSuccessMessage((res as any)[1]);
    } catch (e) {
      const err = e as AxiosError<any>;

      setError(err.response?.data[1] || '');
    }
  }

  async function handleChangeSchema() {
    if (!data || !loginResponseData) return;

    const { regras } = data;

    const cpfCnpj = loginResponseData.consorciadoCpfCnpj.replace(/\D/g, '');
    const idVolvo = loginResponseData.usuarioNome.trim();
    const ownCode = loginResponseData.usuarioCodigo.replace(/\D/g, '');

    setSchema(
      changeSchemaBasedOnRules(regras, {
        cpfCnpj,
        idVolvo,
        ownCode,
      })
    );
  }

  return (
    <>
      <form onSubmit={handleSubmit(handleSalvar)}>
        <PageTitleContainer>
          <div>
            <Title>Trocar Senha</Title>
          </div>
        </PageTitleContainer>

        {(!!errorMessage || !!successMessage) && (
          <Alert variant={errorMessage ? 'danger' : 'success'}>{errorMessage || successMessage}</Alert>
        )}

        <div className="row">
          <div className="col-md-6">
            <FormGroup controlId="formBasicTYPE">
              <Form.Label>Grupo/Cota</Form.Label>
              <Form.Select {...register('quota')}>
                {loginResponseData?.consorciados.map((consorciado) => (
                  <option
                    key={consorciado.cota}
                    value={consorciado.grupo.padStart(5, '0') + '_' + consorciado.cota.padStart(4, '0')}
                  >
                    {consorciado.grupo} / {consorciado.cota}.{consorciado.digito}
                  </option>
                ))}
              </Form.Select>
            </FormGroup>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <FormGroup controlId="formBasicCEP">
              <Form.Label>Senha Atual</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type={isPasswordHiddenObject.oldPassword ? 'password' : 'text'}
                  placeholder="Senha Atual"
                  required
                  {...register('password')}
                  isInvalid={!!errors.password}
                />
                <BButton
                  variant="secondary"
                  onClick={() => setPasswordHidden((state) => ({ ...state, oldPassword: !state.oldPassword }))}
                >
                  {isPasswordHiddenObject.oldPassword ? <EyeSlash /> : <Eye />}
                </BButton>
                <Form.Control.Feedback type="invalid">{errors.password?.message}</Form.Control.Feedback>
              </InputGroup>
            </FormGroup>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6">
            <FormGroup controlId="streetAddress">
              <Form.Label>Nova Senha</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type={isPasswordHiddenObject.newPassword ? 'password' : 'text'}
                  placeholder="Nova Senha"
                  onFocus={handleChangeSchema}
                  required
                  {...register('newPassword')}
                  isInvalid={!!errors.newPassword}
                />
                <BButton
                  variant="secondary"
                  onClick={() => setPasswordHidden((state) => ({ ...state, newPassword: !state.newPassword }))}
                >
                  {isPasswordHiddenObject.newPassword ? <EyeSlash /> : <Eye />}
                </BButton>
                <Form.Control.Feedback type="invalid">{errors.newPassword?.message}</Form.Control.Feedback>
              </InputGroup>
            </FormGroup>
          </div>

          <div className="col-sm-6">
            <FormGroup controlId="numberAddress">
              <Form.Label>Redigite a Nova Senha</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type={isPasswordHiddenObject.newPassword ? 'password' : 'text'}
                  placeholder="Nova Senha"
                  onFocus={handleChangeSchema}
                  required
                  {...register('newPasswordConfirmation')}
                  isInvalid={!!errors.newPasswordConfirmation}
                />
                <BButton
                  variant="secondary"
                  onClick={() => setPasswordHidden((state) => ({ ...state, newPassword: !state.newPassword }))}
                >
                  {isPasswordHiddenObject.newPassword ? <EyeSlash /> : <Eye />}
                </BButton>
                <Form.Control.Feedback type="invalid">{errors.newPasswordConfirmation?.message}</Form.Control.Feedback>
              </InputGroup>
            </FormGroup>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6">
            <Form.Check type={'checkbox'} label={`Alterar em Todas as Cotas`} {...register('alterAllQuotas')} />
          </div>
        </div>

        <ButtonGroup>
          <Button className="reset" type="reset" onClick={() => window.location.reload()}>
            Restaurar
          </Button>
          <Button className="submit" type="submit">
            Salvar
          </Button>
        </ButtonGroup>
      </form>
      <BoxCard>
        <ExtractTable title="A SENHA DEVE" titleCenter rowsGroup={['CONTER', 'NÃO CONTER']} data={textTable} />
      </BoxCard>
    </>
  );
}
