import { Form } from 'react-bootstrap';
import styled from 'styled-components';

export const FormGroup = styled(Form.Group)`
  position: relative !important;
`;

export const Input = styled(Form.Control)`
  background: #fff;
  border: 1px solid #ced4da;
  border-radius: 8px;
  padding: 9px 16px;
  margin: 16px 0;
  color: #abb5be !important;
  box-shadow: none !important;
  outline: none !important;
  background: none !important;

  &::placeholder {
    color: #abb5be !important;
  }
`;

export const ViewPassword = styled.button`
  border: none;
  background: none;

  display: flex;
  align-items: center;
  justify-content: center;
  margin: 9px 16px;
  padding: 0;

  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
`;
