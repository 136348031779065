import styled from 'styled-components';

export const PritingVersion = styled.div`
  font-family: Arial;
  font-size: 12px !important;
  p {
    font-size: 12px !important;
  }
  div {
    font-size: 12px !important;
  }
  @media screen {
    display: none;
  }
`;
export const PritingHeader = styled.div<{ bgColor?: string }>`
  font-size: 1.5rem;
  display: flex;
  justify-content: space-between;
  padding: 16px 32px;
  background: ${({ bgColor }) => bgColor};
  color: white;
  border-radius: 8px;
  @media screen {
    display: none;
  }
`;
export const LogoHeader = styled.figure`
  max-width: 300px;
  overflow: hidden;
  margin: 30px auto;
`;
