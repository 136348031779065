import { assemblyResultsRoutePage } from './AssemblyResults';
import { assemblyResultsResumeRoutePage } from './AssemblyResultsResume';
import { automaticDebitRoutePage } from './AutomaticDebit';
import { bidOfferRoutePage } from './BidOffer';
import { billetCreationRoutePage } from './BilletCreation';
import { billetSendRoutePage } from './BilletSend';
import { changeAddressRoutePage } from './ChangeAddress';
import { changePasswordRoutePage } from './ChangePassword';
import { consolidatedPositionRoutePage } from './ConsolidatedPosition';
import { contractRoutePage } from './Contract';
import { currentAccountStatementRoutePage } from './CurrentAccountStatement';
import { documentsRoutePage } from './Documents';
import { forgotPasswordRoutePage } from './ForgotPassword';
import { groupsAvailabilityRoutePage } from './GroupsAvailability';
import { homeRoutePage } from './home';
import { loginRoutePage } from './Login';
import { membershipProposalRoutePage } from './MembershipProposal';
import { paymentOptionsRoutePage } from './PaymentOptions';
import { quotaInformationRoutePage } from './QuotaInformation';

const routes = [
  homeRoutePage,
  bidOfferRoutePage,
  changeAddressRoutePage,
  billetCreationRoutePage,
  consolidatedPositionRoutePage,
  groupsAvailabilityRoutePage,
  quotaInformationRoutePage,
  changePasswordRoutePage,
  automaticDebitRoutePage,
  currentAccountStatementRoutePage,
  documentsRoutePage,
  forgotPasswordRoutePage,
  loginRoutePage,
  assemblyResultsRoutePage,
  assemblyResultsResumeRoutePage,
  contractRoutePage,
  membershipProposalRoutePage,
  billetSendRoutePage,
  paymentOptionsRoutePage,
];

export default routes;
