import axios, { AxiosError } from 'axios';

import CUSTOM_ERRORS from '../constants/errosHTTP';
import { getFromSessionStorage } from '../constants/functions';
import { toast } from 'react-toastify';

type ErrorData = {
  1?: string;
  err?: { 1: string };
  message?: string;
};

const showLoading = (show: boolean) => {
  document.getElementById('loading')!.style.display = show ? 'block' : 'none';
};
const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_API ? process.env.REACT_APP_BASE_URL_API.toString() : '', // IP do servidor
  // Temporário até
  validateStatus: (status: number) => {
    return status >= 200 && status < 300; // default
  },
  headers: {
    segmento: process.env.REACT_APP_SEGMENTO,
    autorizado: process.env.REACT_APP_AUTORIZADO,
    token: getFromSessionStorage('token'),
    'X-Content-Type-Options': 'nosniff',
    'X-Frame-Options': 'SAMEORIGIN',
    'X-XSS-Protection': '1',
    'Content-Security-Policy-Report-Only': 'policy',
    'Strict-Transport-Security': 'max-age=31536000',
    'Cache-Control': 'no-store',
  },
  withCredentials: false,
});
let pending = 0;
api.interceptors.request.use(
  (config) => {
    showLoading(true);
    pending++;
    config.headers['access'] = getFromSessionStorage('access');
    return config;
  },
  (error) => {
    showLoading(false);
    return Promise.reject(error);
  }
);
// Alter defaults after instance has been created
api.interceptors.response.use(
  (response: any) => {
    if (--pending <= 0) {
      showLoading(false);
    }
    // Capturo o Erro para ser trabalhado
    if (response.data.error) {
      const err = CUSTOM_ERRORS[response.data.error](response);
      return err || Promise.reject(JSON.stringify(response.data));
    }
    return response.data;
  },
  (error: AxiosError<ErrorData>) => {
    if (--pending <= 0) {
      showLoading(false);
    }

    if ((error.code === 'ERR_NETWORK' || error.response?.status === 401) && window.location.pathname !== '/login') {
      sessionStorage.clear();
      window.location.reload();
    }

    if (!['/recuperar-senha', '/login'].includes(window.location.pathname)) {
      const errorMessage =
        error.response?.data?.[1] ||
        error.response?.data?.err?.[1] ||
        error.response?.data?.message ||
        error.message ||
        'Ocorreu um erro desconhecido na API';

      toast.error(errorMessage);
    }

    return Promise.reject(error);
  }
);

export default api;
