import { useMemo, useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { Input } from '../../components/Form/Input/Input';

//styles
import { useNavigate } from 'react-router-dom';
import ModalInfo from '../../components/ModalInfo';
import { useUserContext } from '../../context/UserContext';
import { useStylization } from '../../hooks/useStylization';
import * as S from './styles';
import { maskCpfCnpj } from '../../utils/masks';

const Login = () => {
  const { error, stylization } = useStylization();

  const [group, setGroup] = useState<string>('');
  const [quota, setQuota] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [cpfCnpj, setCpfCnpj] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [digit, setDigit] = useState<string>('');

  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [validated, setValidated] = useState<boolean>(false);

  const { makeLogin } = useUserContext();

  const willUseGroupQuota = useMemo(() => {
    if (group || quota) return true;

    return false;
  }, [quota, group]);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      try {
        const res = await makeLogin({
          group,
          quota,
          password,
          digit,
          document: cpfCnpj.replace(/\D/g, ''),
        });
        if (res.error) {
          setErrorMessage(res.message);
          return handleShow();
        }
        navigate('/');
      } catch (e: any) {
        console.log(e);
      }
    }

    setValidated(true);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <S.Content loginbackground={stylization?.imagemFundo + ''}>
      <div className="container">
        <div className="row">
          {error && (
            <div className="px-4 mt-3">
              <Alert key={'primary'} variant={'primary'}>
                {error}
              </Alert>
            </div>
          )}
          <div className="col-lg-6 ms-auto d-flex align-items-center justify-content-center">
            <S.Box>
              <div>
                <figure className="text-center mx-auto logoSecondary">
                  <img src={stylization?.logoPrincipal} alt="" className="w-100" />
                </figure>

                <ModalInfo
                  isHtml
                  message={errorMessage}
                  show={show}
                  handleClose={handleClose}
                  header={'Acesso Negado'}
                ></ModalInfo>

                <S.Head>
                  <h1>{stylization?.tituloPrincipal}</h1>
                  <p>{stylization?.subTitulo}</p>
                </S.Head>

                {group === '' || quota === '' || password === '' ? (
                  <S.AlertDanger>Preencha o grupo e quota e senha ou CPF/CNPJ e senha!</S.AlertDanger>
                ) : null}

                <Form noValidate validated={validated} onSubmit={handleSubmit} autoComplete="off">
                  <Input
                    type="text"
                    id="group"
                    placeholder="Grupo"
                    required={willUseGroupQuota}
                    onChange={(e: any) => setGroup(e.target.value)}
                    value={group}
                    autoFocus
                  />

                  <div className="row" style={{ marginTop: '-8px', marginBottom: '-8px' }}>
                    <div className={'col-md-6'}>
                      <Input
                        type="text"
                        id="quota"
                        required={willUseGroupQuota}
                        placeholder="Cota"
                        onChange={(e: any) => setQuota(e.target.value)}
                        value={quota}
                      />
                    </div>
                    <div className={'col-md-6'}>
                      <Input
                        type="text"
                        id="digito"
                        placeholder="Digito"
                        onChange={(e: any) => setDigit(e.target.value)}
                        value={digit}
                      />
                    </div>
                  </div>

                  <Input
                    type="text"
                    id="cpfCnpj"
                    required={!willUseGroupQuota}
                    disabled={willUseGroupQuota}
                    placeholder="CPF/CNPJ"
                    value={cpfCnpj}
                    onChange={(e: any) => setCpfCnpj(maskCpfCnpj(e.target.value))}
                  />

                  <Input
                    type="password"
                    id="password"
                    required={true}
                    placeholder="Senha"
                    value={password}
                    onChange={(e: any) => setPassword(e.target.value)}
                  />
                  <S.Submit type="submit">Fazer login</S.Submit>
                </Form>

                <div className="text-center">
                  <S.NavigatePassword to="/recuperar-senha" title="Recuperar Senha">
                    Esqueci minha senha
                  </S.NavigatePassword>
                </div>
              </div>

              <div className="infoFooter">
                <p
                  dangerouslySetInnerHTML={{ __html: stylization?.textoRodape || '' }}
                  className="text-center mb-0"
                ></p>

                <div className="text-center d-flex justify-content-center align-items-center gap-2 flex-wrap">
                  <strong>{stylization?.textoRodapeNegrito}</strong>
                </div>
              </div>

              <figure className="text-center mt-2 mb-0 mx-auto logoSecondary">
                <img src={stylization?.logoSecundario} alt="" className="w-100" />
              </figure>
            </S.Box>
          </div>
        </div>
      </div>
    </S.Content>
  );
};

export default Login;
