import React from 'react';
import {
  AccordionCustomHeader,
  ColoredSubTitleRow,
  MainHeader,
  PseudoCollumnLabel,
  PseudoCollumnValue,
  PseudoRow,
  PseudoRowContainer,
  PseudoRowTitle,
  Row,
  RowGroup,
  SubgroupContainer,
} from './styles';
import { useGetWindowSize } from '../../hooks/useGetWindowSize';
import { Accordion } from 'react-bootstrap';

interface TableProps {
  titleRow: { data: any[]; color: string };
  rows: any[];
  mobilePseudoCollumnLabels: string[];
}

const Table = ({ titleRow, rows, mobilePseudoCollumnLabels }: TableProps) => {
  const { isMobile } = useGetWindowSize();

  return (
    <SubgroupContainer color={titleRow.color}>
      {isMobile ? (
        <>
          <Accordion defaultActiveKey={['0']} alwaysOpen>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <AccordionCustomHeader>
                  <MainHeader>{titleRow.data[0]}</MainHeader>
                  <PseudoRow>
                    <PseudoCollumnLabel>{mobilePseudoCollumnLabels[1]}</PseudoCollumnLabel>
                    <PseudoCollumnValue>{titleRow.data[1]}</PseudoCollumnValue>
                  </PseudoRow>
                </AccordionCustomHeader>
              </Accordion.Header>
              <Accordion.Body>
                {rows.map((row, index) => (
                  <PseudoRowContainer key={'r' + index}>
                    <PseudoRowTitle>{row[0]}</PseudoRowTitle>
                    {row.map((data: any, index: number) => {
                      if (index === 0 || !data || data === '') return <></>;
                      return (
                        <PseudoRow>
                          <PseudoCollumnLabel>{mobilePseudoCollumnLabels[index]}</PseudoCollumnLabel>
                          <PseudoCollumnValue>{row[index]}</PseudoCollumnValue>
                        </PseudoRow>
                      );
                    })}
                  </PseudoRowContainer>
                ))}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </>
      ) : (
        <>
          <ColoredSubTitleRow color={titleRow.color}>
            {titleRow.data.map((row, index) => (
              <p key={'h' + index} className={`p${index}`}>
                {row}
              </p>
            ))}
          </ColoredSubTitleRow>
          <RowGroup>
            {rows.map((row, index) => (
              <Row key={'r' + index} hoverable={true}>
                {row.map((data: any, index: number) => (
                  <p key={'p' + index} className={`p${index}`}>
                    {data}
                  </p>
                ))}
              </Row>
            ))}
          </RowGroup>
        </>
      )}
    </SubgroupContainer>
  );
};

export default Table;
