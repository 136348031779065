import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useRef, useState } from 'react';

//styles
import { InfoCircle, Printer } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import Button from '../../components/Button';
import { ExtractTable } from '../../components/ExtractTable';
import { useUserContext } from '../../context/UserContext';
import { BoxCard } from '../BilletCreation/styles';
import { PageTitleContainer, Title } from '../ChangeAddress/styles';
import { ButtonsContainer } from '../GroupsAvailability/styles';
import { SubTitle } from './styles';
import { getConsolidatedPosition } from '../../services/consolidatedPosition';
import { formatMoney } from '../../constants/functions';
import { PrintingVersion } from '../../components/PrintingVersion';

const QuotaInformation = () => {
  const navigate = useNavigate();
  const [consolidate, setConsolidate] = useState<any>();

  const ref = useRef<HTMLDivElement>(null);
  const { group, quota, digit, loginResponseData } = useUserContext();

  useEffect(() => {
    if (!group || !quota || !digit || !loginResponseData) return;

    const func = async () => {
      try {
        const groupQuota = loginResponseData.consorciados
          .map((consorciado) => consorciado.grupo + '/' + consorciado.cota)
          .join(',');
        const res2 = await getConsolidatedPosition(groupQuota);
        setConsolidate(res2);
      } catch (e) {
        console.log(e);
      }
    };
    func();
  }, [group, quota, digit, loginResponseData]);
  const handlePrint = useReactToPrint({
    content: () => ref?.current,
  });

  const pageContent = () => {
    return (
      <>
        <PageTitleContainer>
          <div>
            <Title>Informação de Todas as Cotas</Title>
            <SubTitle>
              <InfoCircle size={24}></InfoCircle>Clique em uma cota para emitir o boleto
            </SubTitle>
          </div>

          <ButtonsContainer>
            <Button
              onClick={() => {
                handlePrint();
              }}
              hideOnPrint={true}
            >
              <Printer /> Imprimir
            </Button>
          </ButtonsContainer>
        </PageTitleContainer>

        <div>
          <div className="col-lg-12">
            <BoxCard>
              <ExtractTable
                title="Cota(s) não contemplada(s)"
                rowsGroup={['Grupo/Cota', 'Valor crédito', 'Bem']}
                data={[
                  ...consolidate.naoContempladas.map((naoContemplada: any) => {
                    const { cota, credito, grupo, bem } = naoContemplada;

                    return [grupo + '/' + cota, formatMoney(credito), bem];
                  }),
                ]}
                totalValues={['Total: ' + consolidate.total.totalCotas, formatMoney(consolidate.total.credito), '']}
                withLink={() => navigate('/emissao-de-boleto')}
              />
            </BoxCard>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {consolidate && (
        <>
          <div ref={ref}>
            <PrintingVersion>{pageContent()}</PrintingVersion>
          </div>

          <div>{pageContent()}</div>
        </>
      )}
    </>
  );
};

export default QuotaInformation;
