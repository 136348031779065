import styled from 'styled-components';

export const Content = styled.h1`
  font-size: 1.5em;
  text-align: center;
  color: palevioletred;
`;
export const Title = styled.h1`
  margin: 0;
  font-style: normal;
  font-weight: 600;
  color: #343a40;
  margin-bottom: 16px;
  font-size: 2rem;
  @media (max-width: 768px) {
    font-size: 1.25rem;
  }
`;
export const Cell = styled.div<{ color: string }>`
  border: 1px solid ${({ color }) => (color ? 'transparent' : '#e2e2e2')};
  padding: 12px;
  min-width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ color }) => (color ? color : '#e2e2e2')};
  color: ${({ color }) => (color ? 'white' : 'black')};
  cursor: ${({ color }) => (color ? 'pointer' : '')};
`;
export const HistogramContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(20, 50px);
  width: fit-content;
  grid-gap: 12px 0;

  margin-top: 24px;
  @media (max-width: 768px) {
    grid-template-columns: repeat(5, 50px);
  }
`;
export const PageTitleContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 24px;
  justify-content: space-between;
  align-items: end;
`;
export const TotalLine = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 8px;
  font-weight: 600;
  gap: 24px;
`;
export const TotalLineLabel = styled.div`
  padding: 10px;
  line-height: 19px;
  background: var(--cor-terciaria);
  border-radius: 8px;
`;
export const InputRow = styled.div`
  display: flex;
  gap: 16px;
  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
`;
export const BoxCard = styled.div`
  background: white;
  padding: 24px;
  border-radius: 8px;
  height: 100%;
`;
