import { z } from 'zod';

export type Regras = {
  politica: string;
  politica_descricao: string;
  tamanho_minimo: string;
  tamanho_maximo: string;
  tempo_historico: string;
  bloq_por_tentativa: string;
  bloq_por_tentativa_qtde: string;
  bloq_por_tentativa_tempo: string;
  trocar_senha_periodi: string;
  nro_dias_troca: string;
  obrigatorio_deveconter: string;
  obrigatorio_minusculas: string;
  obrigatorio_maiusculas: string;
  obrigatorio_numeros: string;
  obrigatorio_carac_espe: string;
  obrigatorio_num_ou_esp: string;
  bloqueio_idvolvo: string;
  bloqueio_espaco: string;
  bloqueio_espacos: string;
  bloqueio_zeros: string;
  bloqueio_proprio_codigo: string;
  bloqueio_sequencias: string;
  bloqueio_cpf_cnpj: string;
  bloqueio_dtnasc: string;
  caracteresEspeciais: string;
  liberar_redef_senha: string;
  email_redef_senha: string;
};

interface ValuesToNotInclude {
  cpfCnpj: string;
  idVolvo: string;
  ownCode: string;
}

export function makeSchema(newPasswordSchema: z.ZodString | z.ZodEffects<any, string, string> = z.string()) {
  return z
    .object({
      password: z.string(),
      newPassword: newPasswordSchema,
      newPasswordConfirmation: z.string(),
      quota: z.string(),
      alterAllQuotas: z.boolean(),
    })
    .refine((data) => data.newPassword === data.newPasswordConfirmation, {
      message: 'Nova senha não confere',
      path: ['newPasswordConfirmation'],
    });
}

export function changeSchemaBasedOnRules(regras: Regras, valuesToNotInclude: ValuesToNotInclude) {
  const regexString = `.*[${regras.caracteresEspeciais.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')}].*`;
  const caracteresEspeciaisRegex = new RegExp(regexString);

  const minSize = Number(regras.tamanho_minimo);
  const maxSize = Number(regras.tamanho_maximo);

  const deveConterRegex = regras.obrigatorio_deveconter === 'N' ? /.*\d.*/ : /.*[a-zA-Z].*/;
  const deveConterText = regras.obrigatorio_deveconter === 'N' ? 'Deve conter numeros' : 'Deve conter letras';

  let baseSchema = z
    .string()
    .min(minSize, `Deve conter pelo menos ${minSize} caracteres`)
    .max(maxSize, `Deve conter no máximo ${maxSize} caracteres`)
    .refine((val) => deveConterRegex.test(val), deveConterText) as z.ZodEffects<any, string, string>;

  if (regras.obrigatorio_maiusculas === 'S') {
    baseSchema = baseSchema.refine((val) => /.*[A-Z].*/.test(val), 'Deve conter letra maiúscula');
  }

  if (regras.obrigatorio_minusculas === 'S') {
    baseSchema = baseSchema.refine((val) => /.*[a-z].*/.test(val), 'Deve conter letra minuscula');
  }

  if (regras.obrigatorio_numeros === 'S') {
    baseSchema = baseSchema.refine((val) => /.*\d.*/.test(val), 'Deve conter números');
  }

  if (regras.obrigatorio_carac_espe === 'S') {
    baseSchema = baseSchema.refine(
      (val) => caracteresEspeciaisRegex.test(val),
      `Deve conter caracteres especiais (${regras.caracteresEspeciais})`
    );
  }

  if (regras.obrigatorio_num_ou_esp === 'S') {
    baseSchema = baseSchema.refine(
      (val) => caracteresEspeciaisRegex.test(val) || /.*\d.*/.test(val),
      `Deve conter números ou caracteres especiais (${regras.caracteresEspeciais})`
    );
  }

  if (regras.bloqueio_espaco === 'S') {
    baseSchema = baseSchema.refine((val) => !val.includes(' '), `Não deve conter espaços`);
  }

  if (regras.bloqueio_zeros === 'S') {
    baseSchema = baseSchema.refine((val) => !/^0+$/.test(val), `Não deve conter só zeros`);
  }

  if (regras.bloqueio_cpf_cnpj === 'S') {
    baseSchema = baseSchema.refine((val) => {
      return !val.includes(valuesToNotInclude.cpfCnpj);
    }, `Não deve conter o CPF/CNPJ`);
  }

  if (regras.bloqueio_idvolvo === 'S') {
    baseSchema = baseSchema.refine((val) => !val.includes(valuesToNotInclude.idVolvo), `Não deve conter o ID Volvo`);
  }

  if (regras.bloqueio_proprio_codigo === 'S') {
    baseSchema = baseSchema.refine(
      (val) => !val.includes(valuesToNotInclude.ownCode),
      `Não deve conter o próprio código`
    );
  }

  if (regras.bloqueio_sequencias === 'S') {
    const firstRegex =
      /(?:abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|mno|nop|opq|pqr|qrs|rst|stu|tuv|uvw|vwx|wxy|xyz|123|234|345|456|567|678|789|890)/;
    const secondRegex = /(\w)\1{2}/;
    const thirdRegex = /(\d)\1{2}/;

    baseSchema = baseSchema.refine(
      (val) => !(firstRegex.test(val) || secondRegex.test(val) || thirdRegex.test(val)),
      `Não deve conter sequencias (aaa, abc, 123, 111)`
    );
  }

  return makeSchema(baseSchema);
}

export const initSchema = makeSchema();

export type FormSchemaData = z.infer<typeof initSchema>;
