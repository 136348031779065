import styled from 'styled-components';
export const SubTitle = styled.div`
  font-size: 1rem;
  gap: 12px;
  display: flex;
  align-items: center;
  margin-top: 8px;
  @media print {
    display: none;
  }
`;
